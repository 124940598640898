import React from "react";

import { useHistory,useRouteMatch } from "react-router-dom";

import classNames from "classnames";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({
  children,
  tooltip,
  props,
  position = "bottom",
  to,
  icon,
  exact=false,
  onUpdate,
  active = false,
}) => {
  let history = useHistory();
  let match = useRouteMatch(to);

  function changeNavigation() {
    history.push(to);
    if(onUpdate) {
      onUpdate();
    }
  }
  return tooltip ? (
    <OverlayTrigger
      key={to}
      placement={"bottom"}
      overlay={<Tooltip id={`tooltip-${position}-${to}`}>{tooltip}</Tooltip>}
    >
      <div
      key={to}
        onClick={() => changeNavigation()}
        className={classNames({
          "ngh-AppBar--NavItem": true,
          "ngh-AppBar--NavItem__active": active,
        })}
      >
        {icon ? <FontAwesomeIcon icon={icon} className="mr-2 icon" /> : null}
        {children}
      </div>
    </OverlayTrigger>
  ) : (
    <div
    key={to}
      onClick={() => changeNavigation()}
      className={classNames({
        "ngh-AppBar--NavItem": true,
        "ngh-AppBar--NavItem__active": match  ? exact ? match.isExact : true : false,
      })}
    >
      {icon ? <FontAwesomeIcon icon={icon} className="mr-2 icon" /> : null}
      {children}
    </div>
  );
};
