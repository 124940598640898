import React from "react";
import Item from "./Item";
import Items from "./Items";
import Spacer from "ngh/components/AppBar/Spacer";

import { faBars, faStream } from "@fortawesome/pro-light-svg-icons";

const Toolbar = ({
  menu = [],
  actions = [],
  onToggle,
  toggled,
  test,
  toolbar = false,
  menuItems,
  actionItems,
  loading = false,
  ...props
}) => {
  return (
    <section className="ngh-console__toolbar">
      {loading ? (
        <div className="ngh-console__toolbar__loading">
          <div className="ngh-console__toolbar__loading__indicator"></div>
        </div>
      ) : null}
      {onToggle ? (
        <Item
          toolbar
          icon={toggled ? faStream : faBars}
          onClick={() => onToggle()}
        ></Item>
      ) : null}
      {menuItems ? menuItems : <Items items={menu} />}
      <Spacer />
      {actionItems ? actionItems : <Items items={actions} />}
    </section>
  );
};

Toolbar.Items = Items;
Toolbar.Item = Item;

export default Toolbar;
