import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import firebase from "firebase";
import { useHistory } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "components/Spinner";

import Wizard from "./Wizard";
import { createNewBusiness } from "redux/actions/console";
import JoyRide from "assets/undraw_joyride_hnno.svg";

export default () => {
  const { t } = useTranslation();
  const [individual, setIndividual] = useState(false);
  const dispatch = useDispatch();
  const [stripeLink, setStripeLink ] = useState();
  const [stripeLoading, setStripeLoading ] = useState(false);

  const loading = useSelector(state => state.createBusiness.loading);
  const msg = useSelector(state => state.createBusiness.msg);
  const error = useSelector(state => state.createBusiness.error);
  const key = useSelector(state => state.createBusiness.key);

  async function getOnboardingLinks() {
    setStripeLoading(true);
    let func = firebase.functions().httpsCallable('stripe-getOnboardingLinks')
    let data = { id: key, baseUrl: "http://localhost:3000/console/"};
    let result = await func(data)
    if(result && result.data) {
      window.location.href = result.data.url;
    }
  }


  yup.setLocale({
    string: {
      min: t("form-validation-min"),
      required: t("form-validation-required"),
      email: t("form-validation-email")
    }
  });

  let schemaPageOne = yup.object({
    companyName: yup
      .string()
      .required()
      .min(6),
    city: yup
      .string()
      .required()
      .min(4),
    address1: yup
      .string()
      .required()
      .min(6),
    business_type: yup.string().required(),
    country: yup.string().required(),
    zip: yup
      .string()
      .required()
      .min(4)
  });

  let schemaPageTwo = yup.object({
    firstName: yup
      .string()
      .required()
      .min(3),
    lastName: yup
      .string()
      .required()
      .min(3),
    phone: yup
      .string()
      .required()
      .min(5),
    representativeRelation: yup.string().required()
  });

  let schemaPageFour = yup.object({
    owncheck: yup
      .boolean()
      .required()
      .oneOf([true], t("must-validate-that-he-is-theowner")),
    stripeCheck: yup
      .boolean()
      .required()
      .oneOf([true], t("stripe-check-required"))
  });
 const history = useHistory();

  return (
    <Wizard
      validationSchema={schemaPageOne}
      initialValues={{}}
      created={key}
      onChange={values => {
        setIndividual(
          values.business_type && values.business_type === "individual"
        );
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        dispatch(createNewBusiness(values));
        actions.setSubmitting(false);
      }}
    >
      <Wizard.Page validation={schemaPageOne}>
        <div className="container-fluid p-0 m-0">
          <div className="row">
            <div className="col-md-9">
              <Wizard.FormGroup
                name="companyName"
                label={t("Legal Business Name")}
                placeholder={t("Placeholder Your Company Name")}
              />
            </div>
            <div className="col">
              <Wizard.FormGroup
                name="business_type"
                label={t("label-business_type")}
                options={[
                  { value: "", label: t("business_type_choose") },
                  { value: "individual", label: t("business_type_individual") },
                  { value: "company", label: t("business_type_company") },
                  { value: "non_profit", label: t("business_type_non_profit") }
                ]}
              />
            </div>
          </div>
        </div>
        {individual ? (
          <>
            <h6>{t("create-business-individual-explanation")}</h6>
            <Wizard.FormGroup
              name="gender"
              label={t("label-gender")}
              options={[
                { value: "", label: t("gender-choose") },
                { value: "female", label: t("label-gender-female") },
                { value: "male", label: t("label-gender-male") }
              ]}
            />
          </>
        ) : null}
        <Wizard.FormGroup
          name="address1"
          label={t("Address")}
          placeholder={t("Adress Line 1")}
        />

        <Wizard.FormGroup
          name="address2"
          label={t("Address2")}
          placeholder={t("Address Line 2")}
        />

        <div className="container-fluid p-0 m-0">
          <div className="row">
            <div className="col">
              <Wizard.FormGroup name="zip" label={t("Zip")} type="number" />
            </div>
            <div className="col">
              <Wizard.FormGroup name="city" label={t("City")} />
            </div>
            <div className="col">
              <Wizard.FormGroup
                name="country"
                label={t("Country")}
                options={[
                  { value: "", label: t("country_choose") },
                  { value: "ch", label: t("country-ch-label") }
                ]}
              />
            </div>
          </div>
        </div>
      </Wizard.Page>
      <Wizard.Page validation={schemaPageTwo}>
        <h4 className="mb-3">{t("Company Representative")}</h4>
        <div className="container-fluid p-0 m-0">
          <div className="row">
            <div className="col">
              <Wizard.FormGroup name="firstName" label={t("label-firstName")} />
            </div>
            <div className="col">
              <Wizard.FormGroup name="lastName" label={t("label-lastName")} />
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 m-0">
          <div className="row">
            <div className="col">
              <Wizard.FormGroup
                name="representativeTitle"
                label={t("label-representativeTitle")}
                placeholder={t("placeholder-representativeTitle")}
              />
            </div>
            <div className="col">
              <Wizard.FormGroup
                name="representativeRelation"
                label={t("label-representativeRelation")}
                options={[
                  { value: "", label: t("representativeRelation_choose") },
                  {
                    value: "director",
                    label: t("label-representativeRelation_director")
                  },
                  {
                    value: "executive",
                    label: t("label-representativeRelation_executive")
                  },
                  {
                    value: "owner",
                    label: t("label-representativeRelation_owner")
                  },
                  {
                    value: "representative",
                    label: t("label-representativeRelation_representative")
                  }
                ]}
              />
            </div>
          </div>
        </div>
        <Wizard.FormGroup
          name="phone"
          label={t("label-phone")}
          placeholder={t("placeholder-phone")}
        />
        <Wizard.FormGroup
          name="taxNumber"
          label={t("label-taxNumber")}
          placeholder={t("placeholder-taxNumber")}
        />

        <Wizard.FormGroup
          name="vatNumber"
          label={t("label-vatNumber")}
          placeholder={t("placeholder-vatNumber")}
        />
      </Wizard.Page>
      <Wizard.Page validation={schemaPageFour}>
        <Wizard.FormGroup
          name="owncheck"
          label={t("label-owncheck")}
          type="checkbox"
          id="owncheck-checkbox"
        />
        <Wizard.FormGroup
          name="stripeCheck"
          label={t("label-stripeCheck")}
          type="checkbox"
          id="stripeCheck-checkbox"
        />
      </Wizard.Page>
      <Wizard.Page
        val="three"
        disableWizard={key}
        loading={loading}
        error={error}
        submitButton={
          key ? <Button className="ml-auto" variant="success" onClick={() => history.push(`/console/${key}`)}>Close</Button> : null
        }
      >
        <div className="container-fluid m-0 p-0">
          {key ? (
            <div>            
            <h1 className="text-center">{t("Thank You!")}</h1>
            <p>Your Business is registered and you are ready to start adding your offers</p>
          <p>Company Key: <span className="code">{key}</span></p>
            <p>To be able to pay you out, our external Payment Partner <em>Stripe</em> needs to verify your idendity.</p>
            <p> You can choose to do it now, following the link or finish it later through your Company Dashboard</p>
          <Button variant="info" onClick={()=>getOnboardingLinks()}>{stripeLoading ? <Spinner/> : null}Redirect to Banking onboarding</Button>
          </div>
          ) : (
            <div className="text-center">
              {error ? <Alert variant="danger">{error.message}</Alert> : null}              
              <img
                src={JoyRide}
                className="card-img-top"
                alt=""
                style={{ height: "200px" }}
              />
              <h1 className="text-center">{t("We are almost done")}</h1>
              <p>{t("create-business-wizard-before-submit")} </p>{" "}
            </div>
          )}
        </div>
      </Wizard.Page>
    </Wizard>
  );
};
