import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import Dialog from "components/Dialog";
import { useSelectedComponent } from "hooks/editor";
import ContentTypes, { DEFAULT_LAYOUT } from "editor/ContentTypes";
import { EditorState, convertFromHTML, ContentState } from "draft-js";

import { useDispatch } from "react-redux";
import { setCurrentPage } from "redux/actions/editor";

import ComponentDialog from "editor/ComponentDialog";
import { uuid } from "helper";
import useEditor from "hooks/editor";

const EditComponentDialog = ({ business }) => {
  const [selectedComponent] = useSelectedComponent();
  const editor = useSelector((state) => state.editor);

  return selectedComponent ? (
    <ComponentDialog
      component={selectedComponent}
      editor={editor}
      business={business}
      onSubmit={async () => {
        throw Error("shit happens");
      }}
    ></ComponentDialog>
  ) : null;
};

const EditPageDialog = ({ business }) => {
  const [selectedComponent] = useSelectedComponent();
  const [currentPage] = useEditor();
  const editor = useSelector((state) => state.editor);
  console.log("currentPage")
console.log(currentPage)
  return currentPage ? (
    <ComponentDialog
      component={{
        ...currentPage.meta,
        type: ContentTypes.PAGE
      }}
      editor={editor}
      business={business}
      onSubmit={async () => {
        throw Error("shit happens");
      }}
    ></ComponentDialog>
  ) : null;
};

const AddComponentDialog = ({ type, business }) => {
  const editor = useSelector((state) => state.editor);

  return (
    <ComponentDialog
      editor={editor}
      business={business}
      component={{ type }}
      add={true}
      onSubmit={async () => {
        throw Error("shit happens");
      }}
    ></ComponentDialog>
  );
};

const AddPageDialog = ({ business }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(true);
  const targetPath = `/business/${business.id}/pages`;
  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);
    try {
      let name = fields.name.replace(/ /g, "_");
      let layout = DEFAULT_LAYOUT({title: fields.title, name});
      let content = {
        ...layout,
        id: name,
        name,
        currentId: layout.versions[0].id,
      };
      console.log("shit shit");
      console.log(content);
      dispatch(setCurrentPage(name, content));
      setShowDialog(false);
      helper.resetForm();
      window.setTimeout(
        () => history.push(`/console/${business.id}/profile/${name}`),
        400
      );
    } catch (e) {
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog
      initialValues={{}}
      title={`Add Page`}
      show={showDialog}
      business={business}
      onSubmit={submitHandler}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
    >
      <Dialog.FormGroup name="title" label="Page Title" />
      <Dialog.FormGroup name="name" label="Page Name" />
    </Dialog>
  );
};

export default ({ business }) => {
  let match = useRouteMatch();
  return (
    <Route
      path={[
        `${match.path}*/*/*/*`,
        `${match.path}*/*`,
        `${match.path}*`,
        `${match.path}`,
      ]}
      children={({ match, ...rest }) => {
        return (
          <Switch>
            <Route path={`${match.path}/add-cmp-hero`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.HERO}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-video`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.VIDEO}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-card`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.CARD}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-columns`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.COLUMNS}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-headline`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.HEADLINE}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-text`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.TEXT}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-text-image`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.TEXT_IMAGE}
              />
            </Route>            
            <Route path={`${match.path}/add-cmp-section`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.SECTION}
              />
            </Route>
            <Route path={`${match.path}/settings`}>
              <EditPageDialog
                business={business}
                type={ContentTypes.PAGE}
              />
            </Route>
            <Route path={`${match.path}/addpage`}>
              <AddPageDialog business={business} />
            </Route>
            <Route path={`${match.path}/editcomponent`}>
              <EditComponentDialog business={business} />
            </Route>
          </Switch>
        );
      }}
    />
  );
};
