export const types = {
  SAVE_CURRENT_PAGE: "EDITOR/SAVE_CURRENT_PAGE",
  PUBLISH_CURRENT_PAGE: "EDITOR/PUBLISH_CURRENT_PAGE",
  SET_CURRENT_PAGE: "EDITOR/SET_CURRENT_PAGE",
  CLEAR_CURRENT_PAGE: "EDITOR/CLEAR_CURRENT_PAGE",
  ADD_SELECTED_COMPONENT: "EDITOR/ADD_SELECTED_COMPONENT",
  REMOVE_SELECTED_COMPONENT: "EDITOR/REMOVE_SELECTED_COMPONENT",
  CHANGE_CURRENT_LANGUAGE: "EDITOR/CHANGE_CURRENT_LANGUAGE",
  ADD_COMPONENT: "EDITOR/ADD_COMPONENT",
  UPDATE_COMPONENT: "EDITOR/UPDATE_COMPONENT",
  MOVE_COMPONENT: "EDITOR/MOVE_COMPONENT",
  REMOVE_COMPONENT: "EDITOR/REMOVE_COMPONENT",
};

export const moveComponent = (target, source, down, currentPage, currentLanguage) => ({
  type: types.MOVE_COMPONENT,
  target,
  source,
  down,
  currentPage,
  currentLanguage
})

export const addComponent = (component, currentPage, currentLanguage, selectedComponents) => ({
  type: types.ADD_COMPONENT,
  component,
  currentPage,
  currentLanguage,
  selectedComponents
})

export const updateComponent = (component, currentPage, currentLanguage) => ({
  type: types.UPDATE_COMPONENT,
  component,
  currentPage,
  currentLanguage
})

export const removeComponent = (source, currentPage, currentLanguage) => ({
  type: types.REMOVE_COMPONENT,
  source,
  currentPage,
  currentLanguage
})

export const addPage = (path, page, notification) => ({
  type: types.SAVE_CURRENT_PAGE,
  page,
  path,
  notification
});

export const publishPage = (path, page, notification) => ({
  type: types.PUBLISH_CURRENT_PAGE,
  page,
  path,
  notification
});

export const setCurrentPage = (id, content) => ({
  type: types.SET_CURRENT_PAGE,
  id,
  content,
});

export const clearCurrentPage = (id, content) => ({
  type: types.CLEAR_CURRENT_PAGE,
})

export const addSelectedComponent = (component) => ({
  type: types.ADD_SELECTED_COMPONENT,
  component,
});

export const removeSelectedComponent = (component) => ({
  type: types.REMOVE_SELECTED_COMPONENT,
  component,
});

export const changeCurrentLanguage = (language) => ({
  type: types.CHANGE_CURRENT_LANGUAGE,
  language,
});
