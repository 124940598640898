import { combineReducers } from "redux";
import undoable, { excludeAction } from "redux-undo";
import { types } from "redux/actions/editor";

import { uuid } from "helper";

import ContentTypes from "editor/ContentTypes";
const initialuuid = uuid();

const initialEditor = {
  dirty: false,
  id: "index",
  currentPage: {
    name: "index",
    id: "index",
    status: "new",
    defaultLanguage: "de",
    currentVersion: initialuuid,
    versions: [
      {
        id: initialuuid,
        defaultLanguage: "de",
        type: "ngh/page/business/profile",
        languages: {
          de: {
            meta: {
              title: "page deutsch",
              subtitle: "page sub title",
            },
            parsys: [
              {
                id: "herasdlfasfd",
                fixposition: true,
                type: ContentTypes.HERO,
                slides: [
                  {
                    title: "slide 1",
                    subtitle: "suntitle slide 1",
                    text: "subtitle text?",
                    image:
                      "https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg",
                  },
                ],
                title: "asdfasdf",
              },
              {
                id: "asfasdfk",
                containerType: "regular",
                fullwidth: true,
                fixposition: true,
                type: ContentTypes.SECTION,
                parsys: [],
              },
              {
                id: "footer",
                containerType: "fluid",
                fixposition: true,
                type: ContentTypes.SECTION,
                parsys: [],
              },
            ],
          },
          en: {
            meta: {
              title: "page englisch",
              subtitle: "page sub title",
            },
            parsys: [
              {
                type: ContentTypes.HEADLINE,
                text: "hallo wlet",
                size: 1,
                id: "jklasdf09jkl",
              },
            ],
          },
        },
      },
    ],
  },
  currentSelection: null,
};

function findComponentById(id, items, component) {
  for (let key in items) {
    if (items[key].id === id) {
      items[key] = component;
      return items[key];
    } else if (items[key].parsys) {
      let result = findComponentById(id, items[key].parsys, component);
      if (result) {
        return {
          ...result,
        };
      }
    }
  }
  return null;
}

function getComponentById(id, items, component, remove = false) {
  for (let key in items) {
    if (items[key].id === id) {
      const c = {
        ...items[key],
      };
      if (remove) items.splice(key, 1);
      return c;
    } else if (items[key].parsys) {
      let result = getComponentById(id, items[key].parsys, component, remove);
      if (result) {
        return {
          ...result,
        };
      }
    }
  }
  return null;
}

function addComponent(id, items, component, down) {
  for (let key in items) {
    if (items[key].id === id) {
      let itm = items[key];
      if (
        (itm.type === ContentTypes.SECTION && itm.parsys.length === 0) ||
        itm.type === ContentTypes.COLUMNS ||
        itm.type === ContentTypes.COLUMN
      ) {
        itm.parsys.push(component);
      } else {
        items.splice(down ? key + 1 : key, 0, component);
      }
      return itm;
    } else if (items[key].parsys) {
      let result = addComponent(id, items[key].parsys, component, down);
      if (result) {
        return {
          ...result,
        };
      }
    }
  }
  return null;
}

function addNewComponent(component, page, language, selectedComponents) {
  let p = JSON.parse(JSON.stringify(page));
  let version = p.versions.find((v) => v.id === p.currentVersion);
  let v = version.languages[language];
  if (selectedComponents.length === 0) {
    if (v.parsys.length > 0 && v.parsys[v.parsys.length - 1].fixposition) {
      v.parsys.splice(v.parsys.length - 1, 0, component);
    } else {
      v.parsys.push(component);
    }
  } else {
    let target = getComponentById(selectedComponents[0].id, v.parsys, false);
    if (target.parsys) {
      target.parsys.push(component);
    } else {
      addComponent(target.id, v.parsys, component, true);
    }
  }
  return p;
}

function updateComponent(component, page, language) {
  let p = JSON.parse(JSON.stringify(page));
  let version = p.versions.find((v) => v.id === p.currentVersion);
  let v = version.languages[language];
  if (component.type === ContentTypes.PAGE) {
    v.meta = component;
  } else {
    findComponentById(component.id, v.parsys, component);
  }
  return p;
}

function moveComponent(source, target, page, language, down) {
  let p = JSON.parse(JSON.stringify(page));
  let version = p.versions.find((v) => v.id === p.currentVersion);
  let v = version.languages[language];
  let component = getComponentById(source, v.parsys, true);
  getComponentById(source, v.parsys, component, true);
  addComponent(target, v.parsys, component, down);
  return p;
}

function removeComponent(source, page, language) {
  let p = JSON.parse(JSON.stringify(page));
  let version = p.versions.find((v) => v.id === p.currentVersion);
  let v = version.languages[language];
  getComponentById(source.id, v.parsys, null, true);
  return p;
}

const editorReducer = undoable(
  function createReducer(state = initialEditor, action = {}) {
    switch (action.type) {
      case types.CLEAR_CURRENT_PAGE:
        return null;
      case types.PUBLISH_CURRENT_PAGE:
        return {
          ...state,
          currentPage: {
            ...state.currentPage,
            loading: true,
          },
        };
      case types.SAVE_CURRENT_PAGE:
        return {
          ...state,
          dirty: false,
          currentPage: {
            ...state.currentPage,
            loading: true,
          },
        };
      case types.ADD_COMPONENT:
        return {
          ...state,
          dirty: true,
          currentPage: addNewComponent(
            action.component,
            action.currentPage, // todo
            action.currentLanguage, // todo
            action.selectedComponents // todo
          ),
        };
      case types.UPDATE_COMPONENT:
        return {
          ...state,
          dirty: true,
          currentPage: {
            ...updateComponent(
              action.component,
              action.currentPage, // todo
              action.currentLanguage // todo
            ),
            status: "edited",
          },
        };
      case types.MOVE_COMPONENT:
        return {
          ...state,
          dirty: true,
          currentPage: {
            ...moveComponent(
              action.source,
              action.target,
              action.currentPage,
              action.currentLanguage, // todo
              action.down
            ),
            status: "edited",
          },
        };
      case types.REMOVE_COMPONENT:
        return {
          ...state,
          dirty: true,
          currentPage: {
            ...removeComponent(
              action.source,
              action.currentPage,
              action.currentLanguage
            ),
            status: "edited",
          },
        };
      case types.SET_CURRENT_PAGE:
        return {
          ...state,
          currentId: action.id,
          currentPage: {
            ...action.content,
          },
        };
      default:
        return state;
    }
  },
  {
    filter: excludeAction([types.SET_CURRENT_PAGE, types.PUBLISH_CURRENT_PAGE]),
    syncFilter: true,
  }
);

const initialConfig = {
  selectedComponents: [],
  currentLanguage: "de",
  currentId: "index",
};

const configReducer = function createReducer(
  state = initialConfig,
  action = {}
) {
  switch (action.type) {
    case types.CHANGE_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.language,
      };
      case types.CLEAR_CURRENT_PAGE:
        return {
          ...state,
          selectedComponents: []
        }
    case types.REMOVE_SELECTED_COMPONENT:
      return {
        ...state,
        selectedComponents: state.selectedComponents.filter(
          (item) => item.id !== action.component
        ),
      };
    case types.ADD_SELECTED_COMPONENT:
      return {
        ...state,
        selectedComponents: [action.component],
      };
    default:
      return state;
  }
};

export const editor = combineReducers({
  document: editorReducer,
  config: configReducer,
});
