import React, { useState } from "react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import Moment from "react-moment";
import "moment-timezone";
import AccountPicture from "assets/undraw_address_udes.svg";
import ProductsPicture from "assets/undraw_empty_cart_co35.svg";
import CompanyProfile from "assets/undraw_account_490v.svg";
import Subscription from "./../subscriptions.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { useQuery } from "react-query";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { useSelector, useDispatch } from "react-redux";
import Status from "components/Status";
import Login from "components/account/login";
import Spinner from "react-bootstrap/Spinner";

import { sendEmailVerification, logout } from "redux/actions/login";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default () => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.login.user);

  const userData = useSelector((state) => {
    return {
      ...state.userData,
      subscriptions: state.userData.subscriptions
        ? state.userData.subscriptions.map((sub) => {
            return {
              ...sub,
              product: state.userData.products
                ? state.userData.products.find(
                    (itm) => itm.id === sub.product.id
                  )
                : sub.product.id,
            };
          })
        : [],
    };
  });

  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();

  console.log(userData);

  const stripe = useStripe();
  const elements = useElements();

  const createPortalLink = async () => {
    setLoading(true);
    const updateRef = firebase
      .app()
      .functions("europe-west6")
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");

    const { data } = await updateRef({ returnUrl: window.location.href });
    window.location.assign(data.url);
  };

  const claimAccess = async () => {
    const userRef = await firebase
      .firestore()
      .collection("users")
      .doc(user.uid);

    await userRef.set({ claimDeveloper: true }, { merge: true });
    alert("done");
  };
  const getSubscriptions = async (key, id) => {
    if (!user) {
      return [];
    }
    const sitesRef = firebase
      .firestore()
      .collection("customers")
      .doc(user.uid)
      .collection("sites");

    if (!sitesRef) return [];
    const querySnapshot = await sitesRef.get();
    const result = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        if (!doc.data().product) return { ...doc.data(), id: doc.id };
        /*const prod = await doc.data().product.get();
        console.log(doc.data());
        return { ...doc.data(), id: doc.id, product: { ...prod.data() } };*/
      })
    );

    return result;
  };

  const { status, data, error, isFetching } = useQuery(
    ["sites", user.uid],
    getSubscriptions
  );

  return (
    <div className="regular-layout">
      {user ? (
        <div className="container">
          {error ? (
            <div className="row mt-5">
              <div className="col">
                <Alert variant="danger">{error.message}</Alert>
              </div>
            </div>
          ) : null}
          <div className="row mt-5">
            <div className="col d-flex justify-content-between align-items-end">
              {/*loading ? (
                <div
                  className="font-weight-light text-center"
                  style={{ fontSize: "15px", minWidth: "200px" }}
                >
                  <Spinner animation="border" size="md" />
                  <br />
                  redirecting to stripe
                </div>
              ) : (
                <div
                  className="btn btn-primary"
                  onClick={() => createPortalLink()}
                >
                  Manage Subscription
                </div>
              )*/}
            </div>
          </div>
          {!isFetching && (!data || data.length === 0) ? (
            <div className="row">
              <div className="col-md-3">
                <div className="card-group admin">
                  <div className="card">
                    <img src={CompanyProfile} className="card-img-top" alt="" />
                    <div className="card-body">
                      <h5 className="card-title">{t("Get Started")}</h5>
                      <address>Create a new site</address>
                      <p className="card-text">
                        Creating hosted sites is currently only limited
                        supported in developer preview. You can demo the
                        software on your local machine for free. Currently only
                        Starter Packages are available to register. Unfortunetly
                        we need to get a small monthly fee of <em>7.30 $</em>{" "}
                        (excl. tax and payment fees) during the Technologie
                        preview phase for hosted instance to cover a minimum of
                        our costs.
                      </p>
                      <Link to="/sites/create" className="btn btn-primary">
                        Register Site
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col offset-1">
                <div className="card-group admin">
                  <div className="card">
                    <img src={AccountPicture} className="card-img-top" alt="" />
                    <div className="card-body">
                      <h5 className="card-title">
                        {t("Account Type")}{" "}
                        {user?.profile?.developer ? "Developer" : " User"}{" "}
                      </h5>

                      <p className="card-text">
                        {!user?.profile?.developer ? (
                          <div>
                            You can claim access to the developer preview.
                            During this phase you will be able to install the
                            LowCode CMS on your Developer machine with regular
                            product updates. <br />
                            {user?.profile?.claimDeveloper === true ? (
                              <div className="bg-info text-white my-5 p-5 font-weight-bold">
                                Your request is under review. Once your request
                                will be approved you will find installation
                                instruction on this page.
                              </div>
                            ) : (
                              <div
                                className="btn btn-success  my-5"
                                onClick={() => claimAccess()}
                              >
                                Claim Access to Developer Preview
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>install clie</div>
                        )}
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row mt-4">
            <div className="col">
              <div className="card">
              
                {isFetching && !data ? (
                  <Spinner animation="border" size="md" />
                ) : null}

                <ListGroup variant="flush">
                  {data
                    ? data.map((item, index) => (
                        <ListGroup.Item eventKey={index}>
                          <div class="d-flex w-100 justify-content-between">
                            <div>
                              <h5>{item.product.name} </h5>
                              <p>{item.metadata.name || "no description"}</p>
                            </div>{" "}
                            <div>seats: {item.quantity}</div>
                            <div className="text-center">
                              {item.cancel_at_period_end ? (
                                <span>
                                  canceled <br />
                                  active until
                                  <br />
                                  <Moment format={t("YYYY/MM/DD")}>
                                    {item.current_period_end.toDate()}
                                  </Moment>
                                </span>
                              ) : (
                                item.status
                              )}
                            </div>
                            <div
                              className={classNames({
                                "text-success": "active" === item.status,
                                "text-danger": "canceled" === item.status,
                                "text-warning": item.cancel_at_period_end,
                              })}
                            >
                              <FontAwesomeIcon
                                icon={
                                  "active" === item.status
                                    ? faCheckCircle
                                    : faCircle
                                }
                              />
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))
                    : ""}
                </ListGroup>

                
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              <div className="card">
                <Subscription />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h1>{t("Subscriptions")}</h1>
        </div>
      )}
    </div>
  );
};
