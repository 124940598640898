import firebase from "firebase";
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import rsf from "../firebase";

import {
  types,
  createNewBusinessSuccess,
  createNewBusinessFailure
} from "../actions/console";

function* addNewBusiness(action) {
  try {
    /*const result = yield call(
      rsf.functions.call,
      "createBusiness",
      action.fields,
      {
        method: "POST"
      }
    );*/      

    let createBusiness = yield firebase.functions().httpsCallable('createBusiness');
    let result = yield call(createBusiness, action.fields);

/*
    var addMessage = firebase.functions().httpsCallable('addMessage');
    addMessage({text: messageText}).then(function(result) {
      // Read result of the Cloud Function.
      var sanitizedMessage = result.data.text;
      // ...
    });


    */
    yield put(createNewBusinessSuccess(result.data.key));
  } catch (error) {
    yield put(createNewBusinessFailure(error));
  }
}

export default function* loginRootSaga() {
  yield all([takeEvery(types.CREATE.ADD_NEW_BUSINESS, addNewBusiness)]);
}
