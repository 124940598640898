import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

//import CreateWizard from "../components/business/CreateWizard";
import Admin from "components/business/admin";
import Wizard from "components/business/wizard";
export default () => {
  let match = useRouteMatch();

  return (
    <div className="regular-layout">
      <Switch>
        <Route path={`${match.url}/create`}>
          <Wizard />
        </Route>
        <Route path={`${match.url}/:id`}>
          <Admin />
        </Route>                
      </Switch>
    </div>
  );
};
