import firebase from "firebase";
import {
  all,
  call,
  fork,
  put,
  take,
  takeEvery,
  cancelled,
  cancel,
} from "redux-saga/effects";

import {
  types,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
  emailVerificationSendSuccess,
  emailVerificationFailure,
  sendPasswordResetEmailSuccess,
  sendPasswordResetEmailFailure,
  updateAccount,
  updateProfile,
  startSyncAccount,
  stopSyncAccount,
} from "../actions/login";

import { updateSubscriptions, syncProducts } from "redux/actions/console";
import { setMessagingToken } from "redux/actions/login";

import rsf from "../firebase";

const authProvider = new firebase.auth.FacebookAuthProvider();

const officeProvider = new firebase.auth.OAuthProvider("microsoft.com");
const googleProvider = new firebase.auth.GoogleAuthProvider();

function* updatePassword(action) {
  try {
    const user = yield call(rsf.auth.updatePassword, action.password);
    yield put(updatePasswordSuccess());
  } catch (error) {
    console.error(error);
    yield put(updatePasswordFailure(error));
  }
}

function* loginGoogle() {
  try {
    yield call(rsf.auth.signInWithPopup, googleProvider);
    // successful login will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* loginOffice() {
  try {
    yield call(rsf.auth.signInWithPopup, officeProvider);
    // successful login will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* sendEmailVerification() {
  try {
    yield call(rsf.auth.sendEmailVerification);
    yield put(emailVerificationSendSuccess());
  } catch (error) {
    yield put(emailVerificationFailure(error));
  }
}

function* sendPasswordResetEmail(action) {
  try {
    yield call(rsf.auth.sendPasswordResetEmail, action.email);
    yield put(sendPasswordResetEmailSuccess());
  } catch (error) {
    yield put(sendPasswordResetEmailFailure(error));
  }
}

function* loginSagaEmailAndPassword(action) {
  try {
    const data = yield call(
      rsf.auth.signInWithEmailAndPassword,
      action.email,
      action.password
    );
    yield put(loginSuccess(data));
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* createUserSaga(action) {
  try {
    const user = yield call(
      rsf.auth.createUserWithEmailAndPassword,
      action.email,
      action.password
    );
    yield put(loginSuccess(user));
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

function* loginSaga() {
  try {
    yield call(rsf.auth.signInWithPopup, authProvider);
    // successful login will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    yield put(loginFailure(error));
  }
}

function* logoutSaga() {
  try {
    yield call(rsf.auth.signOut);
    // successful logout will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    alert("error logout")
    yield put(logoutFailure(error));
  }
}

function resolveAdminClaim() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        console.log("custom claims");
        console.log(idTokenResult.claims);
        resolve({
          isSuperAdmin: !!idTokenResult.claims.superadmin,
          isDeveloper: !!idTokenResult.claims.developer,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*

db.collection("cities").doc("SF")
    .onSnapshot(function(doc) {
        console.log("Current data: ", doc.data());
    });*/

function* syncUserStarlet() {
  while (yield take(types.ACCOUNT.START_SYNC_ACCOUNT)) {
    const path = "users/" + firebase.auth().currentUser.uid + "/pages/starlet";
    //const channel = yield call(rsf.firestore.syncCollection, "business");
    /*while (true) {
    const account = yield take(channel);
    yield put(updateAccount(account));
  }
  */
    const bgSyncTask = yield fork(rsf.firestore.syncDocument, path, {
      successActionCreator: updateAccount,
    });
    yield take(types.ACCOUNT.STOP_SYNC_ACCOUNT);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask);
  }
}

function* syncUserData() {
  while (yield take(types.ACCOUNT.START_SYNC_ACCOUNT)) {
    // starts the task in the background
    console.log("sync started");
    const userid = firebase.auth().currentUser.uid;
    const path = "customers/" + userid;

    /*const bgSyncTaskProfile = yield fork(rsf.firestore.syncDocument, path, {
      successActionCreator: updateProfile,
    });
*/

    //yield take(types.ACCOUNT.STOP_SYNC_ACCOUNT);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
console.log("listening and watching for shit")
console.log(`/users/${firebase.auth().currentUser.uid}`)
    const bgSyncUserProfileTask = yield fork(
      rsf.firestore.syncDocument,
      `users/${firebase.auth().currentUser.uid}`,
      {
        successActionCreator: updateProfile,        
        failureActionCreator: (snap) => {
          console.log("error bgSyncUserProfileTask syn cs")
          console.log(snap);
        },
      }
    );
    

    const bgSyncProductsTask = yield fork(
      rsf.firestore.syncCollection,
      firebase.firestore().collection("products"),
      {
        successActionCreator: syncProducts,
        transform: (dada) => {
          return dada.docs.map((itm) => ({ ...itm.data(), id: itm.id }));
        },
        failureActionCreator: (snap) => {
          console.log(snap);
        },
      }
    );
    const bgSyncTask = yield fork(
      rsf.firestore.syncCollection,
      firebase
        .firestore()
        .collection("customers")
        .doc(userid)
        .collection("subscriptions"),
      {
        successActionCreator: updateSubscriptions,
        transform: (dada) => {
          const subs = [];
          const docs = dada.docs;
          for (let i = 0; i < docs.length; i++) {
            const itm = docs[i];
            const itd = {
              ...itm.data(),
              id: itm.id,
            };
            subs.push(itd);
          }
          return subs;
        },
        failureActionCreator: (snap) => {
          console.log(snap);
        },
      }
    );

    //TODO: refractor to make more sense - now this is only used
    // to change custom claim, but we should use it to have user role models changes on client as well
    let pathtosync = `metadata/${userid}/refreshTime`;
    let task = yield fork(rsf.database.sync, pathtosync, {
      successActionCreator: () => {
        console.log("refresh user token");        
        firebase.auth().currentUser.getIdToken(true);        

        return {
          type: "refresh-totken",
        };
      },
      failureActionCreator: (snap) => {
        alert("cannot sync user data refresh time token shit");
        console.log(snap);
      },
    });
    // wait for the user stop action
    yield take(types.ACCOUNT.STOP_SYNC_ACCOUNT);
    // user clicked stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(bgSyncTask);
    //yield cancel(bgSyncTaskProfile);
    yield cancel(task);
    yield cancel(bgSyncProductsTask);
    yield cancel(bgSyncUserProfileTask)
  }
}

let callback = null;
let metadataRef = null;

function* loginStatusWatcher() {
  const channel = yield call(rsf.auth.channel);
  while (true) {
    const { user } = yield take(channel);
    
    if (callback) {
      metadataRef.off("value", callback);
    }
    if (user) {
      const claims = yield call(resolveAdminClaim);
      console.log("claims")
      console.log(claims);
      const snapshot = yield call(
        rsf.firestore.getDocument,
        `users/${user.uid}`
      );
      const account = snapshot.data();

      yield put(loginSuccess({ ...user, ...claims, account }));
      yield put(startSyncAccount());

      /*metadataRef = firebase.database().ref('metadata/' + user.uid + '/refreshTime');
      callback = async (snapshot) => {
        // Force refresh to pick up the latest custom claims changes.
        // Note this is always triggered on first call. Further optimization could be
        // added to avoid the initial trigger when the token is issued and already contains
        // the latest claims.
        console.log("frefreshing user data on server request")
        alert("refreshing user because server asked to do that so")
        const isSuperAdmin = await resolveAdminClaim();
        const snapshotUser = await rsf.firestore.getDocument(`users/${user.uid}`);
        const account = await snapshotUser.data();
        put(loginSuccess({ ...user, isSuperAdmin, account }));        
        user.getIdToken(true);
      };
      // Subscribe new listener to changes on that node.
      console.log("setting refresh callback shit")
      metadataRef.on('value', callback);*/
    } else {
      yield put(stopSyncAccount());
      yield put(logoutSuccess());
    }
  }
}

export default function* loginRootSaga() {
  yield fork(loginStatusWatcher);
  yield fork(syncUserData);
  //yield fork(syncUserStarlet);
  yield all([
    takeEvery(types.ACCOUNT.UPDATE_PASSWORD, updatePassword),
    takeEvery(types.LOGIN.REQUEST_OFFICE, loginOffice),
    takeEvery(types.LOGIN.REQUEST_GOOGLE, loginGoogle),
    takeEvery(types.LOGIN.SEND_EMAIL_VERIFICATION, sendEmailVerification),
    takeEvery(types.LOGIN.REQUEST, loginSaga),
    takeEvery(types.LOGIN.SEND_PASSWORD_RESET_EMAIL, sendPasswordResetEmail),
    takeEvery(types.LOGIN.REQUEST_EMAIL_PASSWORD, loginSagaEmailAndPassword),
    takeEvery(types.LOGIN.REGISTER_EMAIL_PASSWORD, createUserSaga),
    takeEvery(types.LOGOUT.REQUEST, logoutSaga),
  ]);
}
