import React, { useContext } from "react";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";

import Placeholder from "components/placeholder";
import Dialog from "components/Dialog";
import Component from "editor/Component";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import EditorContext from "editor/EditorContext";

const Headline = ({ text, size, id, type, title, ...props }) => {
  const context = useContext(EditorContext);

  function renderContent() {
    switch (size) {
      case "2":
        return <h2>{text}</h2>;
      case "3":
        return <h3>{text}</h3>;
      case "4":
        return <h4>{text}</h4>;
      default:
        return <h1>{text}</h1>;
    }
  }
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      <Card className="mb-4">
        <Accordion.Toggle as={Card.Header} eventKey={id}>
          {title
            ? title
            : context.currentPage &&
              context.currentPage.meta &&
              context.currentPage.meta.title
            ? context.currentPage.meta.title
            : null}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={id}>
          <Card.Body>Hello! I'm the body</Card.Body>
        </Accordion.Collapse>
      </Card>
      {/*text ? (
        renderContent()
      ) : (
        <Placeholder
          title="empty headline"
          text="edit component and add headline text"
        />
      )*/}
    </Component>
  ) : (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={id}>
        {title
          ? title
          : context.currentPage &&
            context.currentPage.meta &&
            context.currentPage.meta.title
          ? context.currentPage.meta.title
          : null}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={id}>
        <Card.Body>Hello! I'm the body</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.COURSE_SCHEDULE,
});

Headline.createInitialValue = (component, add = false) =>
  add ? defaultStructure() : component;
Headline.title = "Schedule Component";
Headline.accept = [ContentTypes.COURSE_SCHEDULE, ContentTypes.COURSE_LIST];
Headline.displayFormFields = (component, currentPage) => {
  console.log("currentPage");
  console.log(currentPage);
  return (
    <>
      <Dialog.Tabs>
        <Dialog.Tab title="mtea info" eventKey="meta">
          <Dialog.FormGroup
            name="title"
            label="Course Title"
            placeholder={
              currentPage && currentPage.meta ? currentPage.meta.title : null
            }
          />
          <Dialog.FormRow>
            <Dialog.FormCol col="4">
              <Dialog.FormGroup
                name="number"
                label="Course Number"
                prepend={
                  currentPage && currentPage.meta
                    ? currentPage.meta.number
                    : null
                }
              />
            </Dialog.FormCol>
            <Dialog.FormCol col="4">
              <Dialog.FormGroup
                name="defaultSeats"
                type="number"
                label="Default Seats"
              />
            </Dialog.FormCol>
            <Dialog.FormCol col="4">
              <Dialog.FormGroup
                name="defaultNunberOfDays"
                type="number"
                label="Default Days"
              />
            </Dialog.FormCol>
          </Dialog.FormRow>
          <Dialog.FormRow>
            <Dialog.FormCol>
              <Dialog.FormGroup
                name="startTime"
                type="time"
                label="Default Start Time"
              />
            </Dialog.FormCol>
            <Dialog.FormCol>
              <Dialog.FormGroup
                name="endTime"
                type="time"
                label="Default End Time"
              />
            </Dialog.FormCol>
          </Dialog.FormRow>

          <Dialog.FormGroup
            name="defaultPrice"
            type="number"
            label="Default Price"
            placeholder={
              currentPage && currentPage.meta
                ? currentPage.meta.defaultPrice
                : null
            }
            prepend="CHF"
          />
        </Dialog.Tab>
        <Dialog.Tab title="Scheudle" eventKey="schedule">
          <h1>heuldesc</h1>
        </Dialog.Tab>
      </Dialog.Tabs>
    </>
  );
};
export default Headline;
