import React, { useEffect, useState, useRef } from "react";
import { ActionCreators } from "redux-undo";
import Console from "components/console";
import Toolbar, { ActionToolbar } from "./toolbar";
import useEditor from "hooks/editor";
import firebase from "firebase";
import Editor from "editor";

import { syncPages } from "redux/actions/console";

import { default as MainToolbar } from "components/console/toolbar";

import Dialogs from "./Dialogs";

import { useDispatch, useSelector } from "react-redux";

import { Switch, Route, useRouteMatch, Prompt } from "react-router-dom";
import {
  faPlus,
  faTshirt,
  faBoxFull,
  faMoneyCheckEdit,
  faHomeAlt,
  faGlobeEurope,
  faFileWord,
  faEdit,
  faFileAlt,
  faGiftCard,
  faCogs,
  faUserCrown,
} from "@fortawesome/pro-light-svg-icons";
import { setCurrentPage } from "redux/actions/editor";

export default ({ business }) => {
  const [currentPage, editor] = useEditor();
  const document = useSelector((state) => state.editor.document.present);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const bid = business.id;

  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("business")
      .doc(bid)
      .collection("pages")
      .onSnapshot(function (querySnapshot) {
        var pages = [];
        querySnapshot.forEach(function (doc) {
          pages.push({ ...doc.data(), id: doc.id });
        });
        dispatch(syncPages(bid, pages));
      });

    return function cleanup() {
      return new Promise((resolve, reject) => {
        try {
          unsubscribe();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [dispatch, bid]);

  return (
    <Console
      menuItems={<Toolbar business={business} />}
      actionItems={<ActionToolbar business={business} />}
      loading={!document || document.currentPage.loading}
      navigation={
        <div className="mt-0 h-100">
          <div style={{ overflow: "hidden" }} className="h-100">
            <h6 className="pl-3 mt-3">Profile</h6>
            <MainToolbar.Item
              to={`/console/${business.id}/profile`}
              exact
              icon={faHomeAlt}
              sidebar
            >
              {!business.pages || business.pages.length === 0 ? (
                <strong>Landing Page*</strong>
              ) : (
                "Landing Page"
              )}
            </MainToolbar.Item>
            <h6 className="pl-3 mt-3">Public Pages</h6>
            {business.pages
              ? business.pages.map((item) =>
                  item.id !== "index" ? (
                    <MainToolbar.Item
                      to={`/console/${business.id}/profile/pages/${item.id}`}
                      exact
                      icon={faFileAlt}
                      sidebar
                    >
                      {item.id}
                    </MainToolbar.Item>
                  ) : null
                )
              : null}
          </div>
        </div>
      }
    >
      <Switch>
        <Route path={`/console/${business.id}/profile/pages/:pagename`}>
          <LoadEditor business={business} />
        </Route>
        <Route path="/">
          <LoadEditor business={business} />
        </Route>
      </Switch>

      <Dialogs business={business} />
    </Console>
  );
};

const LoadEditor = ({ business, shit }) => {
  const match = useRouteMatch();
  const [page, setPage] = useState(null);
  const [currentPage, editor] = useEditor();
  const dispatch = useDispatch();
  const iframe = useRef();

  useEffect(()=>{
    if(iframe.current) {
    iframe.current.onload = ()=>{
      try {
      iframe.current.contentWindow.postMessage(JSON.stringify(currentPage), "*")
    }catch(e) {
      alert(e)
    }
      
    }
  } 
  }, [currentPage, iframe])
  useEffect(() => {
    if (business.pages && business.pages.length > 0) {
      const p = business.pages.find(
        (page) =>
          page.id === (match.params.pagename ? match.params.pagename : "index")
      );
      dispatch(setCurrentPage(p.id, p));      
    }
  }, [business, dispatch, match.params.pagename]);
  console.log("shit editor shit");
  console.log(editor);
  console.log(business.pages);
  
  return (
    <>
      {business.pages && currentPage ? (
        /*<Editor content={currentPage.parsys} currentPage={currentPage} />*/
        <iframe src="http://localhost:8000/" ref={iframe}
        style={{width: "100%", height: "100%", border:0}}
        title="editorframe"></iframe>
      ) : (
        <h1>nothing there, create your profile page</h1>
      )}
    </>
  );
};
