import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "components/Spinner";
import Card from "react-bootstrap/Card";
import Wizard from "./Wizard";
import { createNewBusiness } from "redux/actions/console";
import JoyRide from "assets/undraw_joyride_hnno.svg";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default () => {
  const { t } = useTranslation();
  const [individual, setIndividual] = useState(false);
  const dispatch = useDispatch();
  const [stripeLink, setStripeLink] = useState();
  const [selectedPackage, setSelectedPackage] = useState()
  const stripe = useStripe();

  const [serverRegions, setServerRegions] = useState([]);

  const [stripeLoading, setStripeLoading] = useState(false);
  const user = useSelector((state) => state.login.user);
  const loading = useSelector((state) => state.createBusiness.loading);
  const msg = useSelector((state) => state.createBusiness.msg);
  const error = useSelector((state) => state.createBusiness.error);
  const key = useSelector((state) => state.createBusiness.key);

  const getPackages = async (key, id) => {
    const querySnapshot = await firebase
      .firestore()
      .collection("packages")
      .where("active", "==", true)
      .get();
    const result = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const data = doc.data();

        return {
          ...data,
          pricesCH: data.pricesCH.map((price) => ({
            key: price.price,
            label: price.desc,
          })),
          id: doc.id,
        };
      })
    );

    return result;
  };

  const { status, data: packgaes, error: packygeError, isFetching } = useQuery(
    ["packages", user.uid],
    getPackages
  );

  async function getOnboardingLinks() {
    setStripeLoading(true);
    let func = firebase.functions().httpsCallable("stripe-getOnboardingLinks");
    let data = { id: key, baseUrl: "http://localhost:3000/console/" };
    let result = await func(data);
    if (result && result.data) {
      window.location.href = result.data.url;
    }
  }

  async function getGeoInfo() {
    setStripeLoading(true);
    let func = firebase.functions().httpsCallable("getGeoInfo");
    try {
      let result = await func();
      if (result && result.data) {
        console.log(result.data);
        console.log(result);
      }
    } catch (e) {
      console.log(e);
    }
    setStripeLoading(false);
  }

  useEffect(() => {
    async function getServerRegions() {
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      }

      try {
        setStripeLoading(true);
        const func = firebase.functions().httpsCallable("dingo-getServerRegions");
        const result = await func();
        setStripeLoading(false);
        setServerRegions(
          result?.data?.regions.filter((itm) => itm.available).sort(compare) ||
            []
        );
        console.log(result?.data?.regions);
      } catch (e) {
        alert("erro");
        console.log(e);
      }
    }

    getServerRegions();
    getGeoInfo();
  }, []);

  function transformRegions() {
    //{ value: "individual", label: t("business_type_individual") },

    if (serverRegions) {
      return serverRegions.map((itm) => ({
        key: itm.slug,
        label: itm.name,
      }));
    }
    return [];
  }

  yup.setLocale({
    string: {
      min: t("form-validation-min"),
      required: t("form-validation-required"),
      email: t("form-validation-email"),
    },
  });

  let schemaPageOne = yup.object({
    companyName: yup.string().required().min(6),
    city: yup.string().required().min(4),
    address1: yup.string().required().min(6),
    business_type: yup.string().required(),
    country: yup.string().required(),
    zip: yup.string().required().min(4),
  });

  let schemaPageTwo = yup.object({
    firstName: yup.string().required().min(3),
    lastName: yup.string().required().min(3),
    phone: yup.string().required().min(5),
    representativeRelation: yup.string().required(),
  });

  let schemaPageFour = yup.object({
    //  serverRegion: yup.string().required(),
    siteName: yup
      .string()
      .lowercase()
      .required()
      .min(4)
      .matches(/^((?!ns|www|my|solutas|lowcode|soluneo|demo|\s).)*$/),
    owncheck: yup
      .boolean()
      .required()
      .oneOf([true], t("must-validate-that-he-is-theowner")),
    stripeCheck: yup
      .boolean()
      .required()
      .oneOf([true], t("stripe-check-required")),
  });

  let schemaCheckout = yup.object({
    price: yup.string().required(),
  });

  const history = useHistory();

  return (
    <Wizard
      validationSchema={schemaPageOne}
      initialValues={{}}
      created={key}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        console.log(values);
        const selectedPrice = {
          price: values.price,
          quantity: 1,
          tax_rates: ["txr_1IPuK6JEKoWWdcytKYRwD8ql"],
          //dynamic_tax_rates: ["txr_1IPu7aJEKoWWdcytEAQq4d5s", "txr_1IPuK6JEKoWWdcytKYRwD8ql"]
        };
        // For prices with metered billing we need to omit the quantity parameter.
        // For all other prices we set quantity to 1.
        selectedPrice.quantity = 1;
        setStripeLoading(true);
        const docRef = await firebase
          .firestore()
          .collection("customers")
          .doc(user.uid)
          .collection("checkout_sessions")
          .add({
            allow_promotion_codes: true,
            billing_address_collection: "required",
            mode: "subscription",
            line_items: [selectedPrice],
            success_url:
              window.location.origin + "/account-settings/subscriptions",
            cancel_url: window.location.origin,
            payment_intent_data: {
              description: "blablabla",
              statement_descriptor: values.note + " - \n " + values.siteName,
            },
            metadata: {
              ...values,
            },
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            // Show an error to your customer and then inspect your function logs.
            alert(`An error occured: ${error.message}`);
            document
              .querySelectorAll("button")
              .forEach((b) => (b.disabled = false));
          }
          if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            stripe.redirectToCheckout({ sessionId });
          }
        });

        actions.setSubmitting(false);
      }}
    >
      <Wizard.Page validation={schemaPageFour}>
        <h1>packages</h1>
        {packgaes?.map((pack) => (
          <li>{pack.title}</li>
        ))}
        {/*   <Wizard.FormGroup
          name="serverRegion"
          label={"Cloud Region"}
          options={[
            { value: "", label: "Please choose your prefered region" },
            ...transformRegions(),
          ]}
        />
*/}
        <Wizard.FormGroup
          name="siteName"
          label={"Site Name"}
          text="This will also be the default name to access your instance"
          append=".lowcodecms.site"
        />
        <Wizard.FormGroup
          name="note"
          label={"Note"}
          text="Internal Notes for yourself"
        />

        <Wizard.FormGroup
          name="owncheck"
          label={t("label-owncheck")}
          type="checkbox"
          id="owncheck-checkbox"
        />
        <Wizard.FormGroup
          name="stripeCheck"
          label={t("label-stripeCheck")}
          type="checkbox"
          id="stripeCheck-checkbox"
        />
      </Wizard.Page>
      <Wizard.Page validation={schemaCheckout} loading={stripeLoading}>
        <Card>
          <Card.Body>
            <Card.Title>Technical Developer Preview</Card.Title>
            <Card.Text>
              The Developer Preview Package is designed for develpers to
              evaluate and test LowCode CMS. You can use it for personal sites
              but you are encouraged not to use it for customer production
              sites. Please contact <a href="mailto:info@solutas.ch">Sales</a>{" "}
              to get access to Production Ready hosting packages.
              <p>
                You will get a dedicated isolated instance with following
                configuration:
              </p>
              <ul className="font-weight-light">
                <li>Free Let's Encrypt SSL Encryption</li>
                <li>1 Dedicated Ip-Address </li>
                <li>1 GB / 1 CPU</li>
                <li>25 GB SSD Disk</li>
                <li>1000 GB transfer</li>
              </ul>
            </Card.Text>

            <h1>packages</h1>
            {packgaes?.map((pack) => (
              <div>
  
  <Wizard.FormGroup
              name="selectedPackage"  value={pack.id} label={"Note"}
              text="Internal Notes for yourself"/> {pack.title}
  
                <Wizard.FormGroup
              name="price"
              label={"Select Subscription Model"}
              options={[
                { key: "", label: "Please choose your Subscription Model" },
                ...pack?.pricesCH
              ]}
            />                
              </div>
            ))}
{/*
            <Wizard.FormGroup
              name="price"
              label={"Select Subscription Model"}
              options={[
                { key: "", label: "Please choose your Subscription Model" },
                {
                  key: "price_1IPrdjJEKoWWdcytdpEHizVR",
                  label:
                    "Yearly Subscription CHF 7.50 / Month = CHF 90 / Year ",
                },
                {
                  key: "price_1IPrdjJEKoWWdcytsZAA7VcG",
                  label: "Monthly Subscription CHF 8 / Month = CHF 96 / Year ",
                },
              ]}
            />*/}
          </Card.Body>
        </Card>
      </Wizard.Page>
      {/*
      <Wizard.Page
        val="three"
        disableWizard={key}
        loading={loading}
        error={error}
        submitButton={
          key ? (
            <Button
              className="ml-auto"
              variant="success"
              onClick={() => history.push(`/console/${key}`)}
            >
              Close
            </Button>
          ) : null
        }
      >
        <div className="container-fluid m-0 p-0">
          {key ? (
            <div>
              <h1 className="text-center">{t("Thank You!")}</h1>
              <p>
                Your Business is registered and you are ready to start adding
                your offers
              </p>
              <p>
                Company Key: <span className="code">{key}</span>
              </p>
              <p>
                To be able to pay you out, our external Payment Partner{" "}
                <em>Stripe</em> needs to verify your idendity.
              </p>
              <p>
                {" "}
                You can choose to do it now, following the link or finish it
                later through your Company Dashboard
              </p>
              <Button variant="info" onClick={() => getOnboardingLinks()}>
                {stripeLoading ? <Spinner /> : null}Redirect to Banking
                onboarding
              </Button>
            </div>
          ) : (
            <div className="text-center">
              {error ? <Alert variant="danger">{error.message}</Alert> : null}
              <img
                src={JoyRide}
                className="card-img-top"
                alt=""
                style={{ height: "200px" }}
              />
              <h1 className="text-center">{t("We are almost done")}</h1>
              <p>{t("create-business-wizard-before-submit")} </p>{" "}
            </div>
          )}
        </div>
      </Wizard.Page>*/}
    </Wizard>
  );
};
