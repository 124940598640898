import React from "react";
import Status from "components/Status";
import DetailHeader from "components/DetailHeader";

import { Link } from "react-router-dom";

export default ({ business }) => (
  <div className="container">
    <DetailHeader header="Member Plans" />  
    <Status />
  </div>
);
