import React, { useState, useEffect } from "react";
import {
  useRouteMatch,
  Link,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Dialog from "components/Dialog";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { convertToHTML } from "draft-convert";
import firebase from "firebase";
import PricingPlan from "./PricingPlan";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { faBadge, faBadgeCheck } from "@fortawesome/pro-solid-svg-icons";

export default ({ business, productId }) => {
  let match = useRouteMatch();
  let history = useHistory();
  const [product, setProduct] = useState();
  const [sumDialog, setSumDialog] = useState(false);

  useEffect(() => {
    if (business && business.products) {
      setProduct(business.products.find((item) => item.id === productId));
    }
    return () => {
      setProduct(null);
    };
  }, [business, productId]);

  function delay(callback) {
    return new Promise(async (resolve, reject) => {
      window.setTimeout(async () => {
        await callback();
        resolve();
      }, 1000);
    });
  }

  async function handleProductUpdate(fields, { resetForm, setSubmitting }) {
    const changes = {
      ...fields,
      description: convertToHTML(fields.description.getCurrentContent()),
    };
    setSubmitting(true);
    delete changes.id;
    const db = firebase.firestore();
    await delay(async () => {
      let ref = db
        .collection(`business/${business.id}/products`)
        .doc(product.id);
      await ref.update(changes);
    });
    resetForm();
    setSubmitting(false);
    history.go(-1);
  }

  return (
    <>
      {product ? (
        <>
          
          <div className="w-100 pl-3 pr-3">
          <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/console/${business.id}/products/list`}>Products</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {product ? <span>{product.name}
            <FontAwesomeIcon icon={product.active ? faBadgeCheck : faBadge} className={classNames({
              'text-success' : product.active,
              'text-danger' : !product.active,
              'mr-3': true
            })}/> 
            </span> : null}
            
          </li>
        </ol>
      </nav>
          <h1 className="mt-0 p-0 mb-3">Pricing Plans</h1>
            <PricingPlan product={product} business={business} />

            <h1 className="mt-0 p-0 mb-3">One Time Price</h1>
            <PricingPlan product={product} business={business} />
          
          </div>

          <Route
            path={[`${match.path}*/*`, `${match.path}*`, `${match.path}`]}
            children={({ match, ...rest }) => {
              return (
                <Switch>
                  <Route path={`${match.path}/settings`}>
                    <Dialog
                      title="Product Settings"
                      show={true}
                      onSubmit={handleProductUpdate}
                      initialValues={{
                        ...product,
                        description: EditorState.createWithContent(
                          ContentState.createFromBlockArray(
                            convertFromHTML(product.description || "")
                          )
                        ),
                      }}
                      handleClose={() => history.go(-1)}
                    >
                      <Dialog.FormGroup
                        name="name"
                        label="Name"
                      ></Dialog.FormGroup>
                      <Dialog.FormGroup
                        name="active"
                        type="switch"
                        disabledLabel="Product disabled"
                        enabledLabel="Product activated"
                        id="frm-active-switch"
                      ></Dialog.FormGroup>
                      <Dialog.FormGroup
                        name="description"
                        label="Descritasdf"
                        type="richtext"
                      ></Dialog.FormGroup>
                    </Dialog>
                  </Route>
                </Switch>
              );
            }}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

/*
<div className="row mt-3">
        <div className="col-md-4 mt-4">
          <div className="card">
            <div className="card-header">
            <FontAwesomeIcon icon={product.active ? faBadgeCheck : faBadge} className={classNames({
              'text-success' : product.active,
              'text-danger' : !product.active,
              'mr-3': true
            })}/> 
            {product.active ? "This Product active" : "This Product Disabled"}
            </div>
            <div className="card-body">              
              <strong>Name:</strong> {product.name}
              <br /><br/>
              <strong>Description:</strong>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: `${product.description ? product.description : null}`,
                }}
              ></span>
            </div>
            <div className="card-footer">
              <Button
                variant="transparent"
                className="text-solblue"
                onClick={() => setSumDialog(true)}
              >
                Edit
              </Button>
              <Dialog
                show={sumDialog}
                onSubmit={handleProductUpdate}
                initialValues={{
                  ...product,
                  description: EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(product.description || "")
                    )
                  ),
                }}
                handleClose={() => setSumDialog(false)}
              >
                <Dialog.FormGroup name="name" label="Name"></Dialog.FormGroup>
                <Dialog.FormGroup
                  name="active"
                  type="switch"
                  disabledLabel="Product disabled"
                  enabledLabel="Product activated"
                  id="frm-active-switch"
                ></Dialog.FormGroup>
                <Dialog.FormGroup
                  name="description"
                  label="Descritasdf"
                  type="richtext"
                ></Dialog.FormGroup>
              </Dialog>
            </div>
          </div>
        </div>
        <div className="col-md-8 mt-4">
        <PricingPlan product={product} business={business} />
        </div>
      </div>
    */
