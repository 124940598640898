import React from "react";
import Status from "components/Status";
import DetailHeader from "components/DetailHeader";

import { Link } from "react-router-dom";
import { Switch, Route, useRouteMatch } from "react-router-dom";

export default ({ business, course, scheduleId }) => {
    let match = useRouteMatch();
  
    return (
  <div className="w-100 pl-3 pr-3">
    <DetailHeader        
        items={[
          {
            to: `/console/${business.id}/courses`,
            text: "Classes & Workshops",
          },
          {
            to: `/console/${business.id}/courses/view/${match.params.id}/schedule`,
            text: course ? course.versions[0].languages.de.meta.title: null,
          },
          {
              text:  "title of schedule or date or so"
          }
        ]}
      />
    
  </div>
);}
