import { Route, Switch, useRouteMatch } from "react-router-dom";

import DetailHeader from "components/DetailHeader";
import { Link } from "react-router-dom";
import Placeholder from "components/placeholder";
import React from "react";
import Status from "components/Status";

export default ({ business, course }) => {
  let match = useRouteMatch();

  return (
    <div className="w-100 pl-3 pr-3">
        <DetailHeader
        header="Course Schedules"
        items={[
          {
            to: `/console/${business.id}/courses`,
            text: "Classes & Workshops",
          },
          {
              text: course ? course.versions[0].languages.de.meta.title: null 
          }
        ]}
      />
      <div class="list-group">
        <Link
          className="list-group-item list-group-item-action flex-column align-items-start "
          style={{ cursor: "pointer" }}
          key={2398}
          to={`/console/${business.id}/courses/view/${match.params.id}/schedule/2349`}
        >
          <div class="d-flex w-100 justify-content-between">
  <div>schedule asafsd kj </div>
            <Status value={false} />
          </div>
        </Link>
        {/*business.packages
        ? business.packages.map((item) => (
            <Link
              className="list-group-item list-group-item-action flex-column align-items-start "
              style={{ cursor: "pointer" }}
              key={item.id}
              to={`/console/${business.id}/products/subscriptions/${item.id}`}
            >
              <div class="d-flex w-100 justify-content-between">
                <div>{item.name}</div>
                <Status value={item.active} />
              </div>
            </Link>
          ))
        : null*/}
      </div>
    </div>
  );
};
