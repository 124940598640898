import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

export default ({ value }) => (
  <div
    className={classNames({
      "text-success": value,
      "text-danger": !value,
    })}
  >
    <FontAwesomeIcon icon={value ? faCheckCircle : faCircle} />
  </div>
);
