import React from "react";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { convertToHTML } from "draft-convert";
import Placeholder from "components/placeholder";
import Dialog from "components/Dialog";
import Component from "editor/Component";


const Headline = ({ text, size, id, type, ...props }) => {
  function renderContent() {
    switch (size) {
      case "2":
        return <h2>{text}</h2>;
      case "3":
        return <h3>{text}</h3>;
      case "4":
        return <h4>{text}</h4>;
      default:
        return <h1>{text}</h1>;
    }
  }
  return (
    <Component type={type} id={id} {...props}>
      {text ? renderContent(): <Placeholder title="empty headline" text="edit component and add headline text"/>}
    </Component>
  );
};

export const defaultStructure = ()=>(
  {
      id: uuid(),        
      type: ContentTypes.PAGE
  }
)
Headline.beforeSave = (component) => ({
  ...component,
  teaser: convertToHTML(component.teaser.getCurrentContent()),
});

Headline.createInitialValue = (component, add=false) => add ? defaultStructure() : {
  ...component,
  teaser: EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(component.teaser || "")
    )
  ),
};
Headline.title = "Page Dialog";
Headline.accept = []
Headline.displayFormFields = () => (
  <>
    <Dialog.FormGroup name="title" label="Title" />
    <Dialog.FormGroup name="name" label="Name" />
    <Dialog.FormGroup name="teaser" type="richtext" label="Teaser Text" />
  </>
);
export default Headline;
