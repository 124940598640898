import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Dialog from "../../Dialog";
import { updateContactInformation } from "../../../redux/actions/business";

// male/female switch not working

export default ({ business }) => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  const [showForm, setUpdateForm] = useState(false);
  const [showCompanyNameDialog, setShowCompanyNameDialog] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);
  const updateContactInformationError = useSelector(
    (state) => state.updateContactInformation.updateContactInformationError
  );
  const updateContactInformationSuccess = useSelector(
    (state) => state.updateContactInformation.updateContactInformationSuccess
  );

  const show =
    loading || updateContactInformationSuccess || updateContactInformationError;
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/console/${match.params.id}`}>
                  {t("Account Admin")}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t("Contact Information")}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1>{t("Contact & Information")}</h1>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-2">
          <h1 className="h6">{t("Legal Business Name")}</h1>
        </div>
        <div className="col">
          {business.companyName}
          <br />
          <Dialog
            show={showCompanyNameDialog}
            initialValues={{ ...business }}
            title={`Change ${business.companyName}'s Company Name`}
            handleClose={() => setShowCompanyNameDialog(false)}
            onSubmit={(fields) => {
              //setUpdateForm(false);
              dispatch(
                updateContactInformation({
                  companyName: fields.companyName ? fields.companyName : null,
                  id: business.id,
                })
              );
            }}
          >
             <Dialog.FormGroup name="companyName" label="Company name" />
            
          </Dialog>          
          <Button
            size="sm"
            onClick={() => setShowCompanyNameDialog(true)}
            className="mt-3"
          >
            {t("Change Company Name")}
          </Button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-2">
          <h1 className="h6">{t("Address")}</h1>
        </div>
        <div className="col">
          {business.address1}
          <br />
          {business.address2 ? business.address2 + "<br/>" : null}
          {business.zip}, {t(business.country)}
          <br />
          <Dialog
            show={showForm}
            initialValues={{ ...business }}
            title={`Change ${business.companyName}'s Address`}
            handleClose={() => setUpdateForm(false)}
            onSubmit={(fields) => {
              //setUpdateForm(false);
              dispatch(
                updateContactInformation({
                  address1: fields.address1 ? fields.address1 : null,
                  address2: fields.address2 ? fields.address2 : null,
                  zip: fields.zip,
                  country: fields.country,
                  id: business.id,
                })
              );
            }}
          >
            {updateContactInformationError ? (
              <Alert variant="danger">
                {updateContactInformationError.code}
              </Alert>
            ) : null}
            <Dialog.FormGroup name="address1" label="Street" />
            <Dialog.FormGroup name="address2" label="Address Line 2" />
            <div className="row">
              <div className="col">
                <Dialog.FormGroup name="zip" label="Zip code" />
              </div>
              <div className="col">
                <Dialog.FormGroup name="city" label="City" />
              </div>
            </div>
            <Dialog.FormGroup name="country" label="Country" disabled />
          </Dialog>
          <Button
            size="sm"
            onClick={() => setUpdateForm(true)}
            className="mt-3"
          >
            {t("Change Address")}
          </Button>
        </div>
      </div>
    </div>
  );
};

/*


      {showForm && business ?
        <Formik 
        enableReinitialize={true} 
        onSubmit={fields => {
          //setUpdateForm(false);
          dispatch(updateContactInformation(fields))
        }}
        initialValues={{...business}}>
          {({
             handleSubmit,
             handleChange,
             touched,
             values,
             errors
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {updateContactInformationError? (
                <Alert variant="danger">errr
                  <small>{updateContactInformationError.code}</small>
                </Alert>
              ): null}
              <Form.Group className="row mt-5">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("Legal Business Name")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4" 
                name="companyName"
                feedback={errors.companyName}
                onChange={handleChange}
                value={values.companyName}
                isValid={touched.companyName && !errors.companyName}
                isInvalid={errors.companyName}
                />
              </Form.Group>
              <Form.Group className="row mt-2">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("label-firstName")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4" 
                feedback={errors.firstName}
                onChange={handleChange}
                isValid={touched.firstName && !errors.firstName}
                isInvalid={errors.firstName}
                value={values.firstName}
                name="firstName"
                />
              </Form.Group>
              <Form.Group className="row mt-2">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("label-lastName")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4"                 
                name="lastName"
                feedback={errors.lastName}
                onChange={handleChange}
                isValid={touched.lastName && !errors.lastName}
                isInvalid={errors.lastName}   
                value={values.lastName}
                />
              </Form.Group>
              <Form.Group className="row mt-5">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("Address Line 1")}</h1>
                </Form.Label>
                <Form.Control name="lastName" className="col-md-4"                 
                feedback={errors.address1}
                onChange={handleChange}
                isValid={touched.address1 && !errors.address1}
                isInvalid={errors.address1}
                name="address1"
                value={values.address1}
                />
              </Form.Group>
              <Form.Group className="row mt-2">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("Address Line 2")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4" 
                feedback={errors.address2}
                onChange={handleChange}
                isValid={touched.address2 && !errors.address2}
                isInvalid={errors.address2}
                value={values.address2}
                name="address2"
                />
              </Form.Group>
              <Form.Group className="row mt-2">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("Zip")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4" 
                feedback={errors.address2}
                onChange={handleChange}
                isValid={touched.zip && !errors.zip}
                isInvalid={errors.zip}                
                value={values.zip}
                name="zip"
                />
              </Form.Group>
              <Form.Group className="row mt-2">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("City")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4" 
                feedback={errors.city}
                onChange={handleChange}
                isValid={touched.city && !errors.city}
                isInvalid={errors.city}
                value={values.city}                        
                name="city"
                />
              </Form.Group>
              <Form.Group className="row mt-2">
                <Form.Label className="col-md-2">
                  <h1 className="h6">{t("Country")}</h1>
                </Form.Label>
                <Form.Control className="col-md-4" 
                onChange={handleChange}
                isValid={touched.country && !errors.country}
                isInvalid={errors.country}
                value={values.country}
                name="country"
                />
              </Form.Group>
              <Form.Group>
              <Button type="submit" size="sm">  
              {t("Update Contact Information")}        
              </Button>
              </Form.Group>
            </Form>

          )}
        </Formik>          
        : <div>
        <div className="row mt-5">
          <div className="col-md-2">
            <h1 className="h6">{t("Legal Business Name")}</h1>
          </div>
          <div className="col">
            {business.companyName}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-2">
            <h1 className="h6">{t("Contact")}</h1>
          </div>
          <div className="col">
            { business.gender === "male" ?                
              t("Mr. ") + business.firstName + " " + business.lastName:
              t("Ms. ") + business.firstName + " " + business.lastName
              }
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <h1 className="h6">{t("label-phone")}</h1>
          </div>
          <div className="col">
            {business.phone}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-2">
            <h1 className="h6">{t("Address")}</h1>
          </div>
          <div className="col">
            {business.address1}
          </div>
        </div>
        {business.address2 != null ? 
                    <div className="row mt-5">
                    <div className="col-md-2">
                      <h1 className="h6">{t("Address Line 2")}</h1>
                    </div>
                    <div className="col">
                      {business.address2}
                    </div>
                  </div>
                  : null }

        <div className="row mt-2">
          <div className="col-md-2">
            <h1 className="h6">{t("Zip")}</h1>
          </div>
          <div className="col-md-2">
            {business.zip}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
              <h1 className="h6">{t("City")}</h1>
            </div>
            <div className="col">
              {business.city}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <h1 className="h6">{t("Country")}</h1>
          </div>
          <div className="col">
            {business.country}
          </div>
        </div>
        <div className="row mt-5">
        <Button size="sm" onClick={() => setUpdateForm(true)}>
        {t("Update Contact Information")}
      </Button>
        </div>
      </div> }*/
