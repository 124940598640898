import React, { useState, useContext } from "react";
import EditorContext from "editor/EditorContext";
import classNames from "classnames";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";
import Component from "editor/Component";
import ContentMapper from "editor/ContentMapper";
import Dialog from "components/Dialog";
import Placeholder from "components/placeholder";

export const createInitialValue = (component) => component;
export const title = "Section Component";

const Section = ({
  parsys,
  id,
  type,
  fg,
  containerType,
  fullheight,
  fullwidth,
  bg,
  marginTop = "no",
  ...props
}) => {
  const [disableHover, setDisableHover] = useState(false);
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component
      type={type}
      id={id}
      {...props}
      disableHover={disableHover}
      key={id}
    >
      <div
        className={classNames({
          container: !containerType || containerType === "regular",
          "container-fluid": containerType || containerType === "fluid",
          "mt-2": marginTop === "small",
          "mt-3": marginTop === "medium",
          "mt-4": marginTop === "big",
          "h-100": fullheight,
        })}
        key={`${id}-wrapper`}
        style={{ backgroundColor: bg, color: fg }}
      >
        {parsys && parsys.length > 0 ? (
          <ContentMapper
            onHover={(value) => setDisableHover(value)}
            content={parsys}
            key={`${id}-section`}
          />
        ) : (
          <Placeholder
            title={`empty section rea`}
            text="add or drag a new component into it"
          />
        )}
      </div>
    </Component>
  ) : (
    <div
      className={classNames({
        container: !containerType || containerType === "regular",
        "container-fluid": containerType || containerType === "fluid",
        "mt-2": marginTop === "small",
        "mt-3": marginTop === "medium",
        "mt-4": marginTop === "big",
        "h-100": fullheight,
      })}
      key={`${id}-wrapper`}
      style={{ backgroundColor: bg, color: fg }}
    >
      {parsys && parsys.length > 0 ? (
        <ContentMapper
          onHover={(value) => setDisableHover(value)}
          content={parsys}
          key={`${id}-section`}
        />
      ) : (
        null
      )}
    </div>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.SECTION,
  parsys: [],
});

Section.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.COLUMNS,
  ContentTypes.COLUMN,
  ContentTypes.SECTION,
  ContentTypes.TEXT,
  ContentTypes.HERO,
  ContentTypes.TEXT_IMAGE,
];

Section.createInitialValue = (component, add) =>
  add ? defaultStructure() : component;
Section.title = "Section Component";

Section.displayFormFields = (component) => (
  <>
    <Dialog.FormGroup
      name="containerType"
      label="Container Type"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "fluid", label: "Fluid" },
        { key: "regular", label: "Regular" },
      ]}
    />

    <Dialog.FormGroup name="fg" label="foreground color" type="color" />
    <Dialog.FormGroup name="bg" label="bg" type="color" />
    <Dialog.FormGroup
      name="marginTop"
      label="marginTop"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "no", label: "no margin" },
        { key: "small", label: "small" },
        { key: "medium", label: "medium" },
        { key: "big", label: "big" },
      ]}
    />
  </>
);

export default Section;
