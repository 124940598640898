import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import Editor from "editor";
import {
  useRouteMatch,
  Link,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { setNavigationTitle } from "redux/actions/app";
import { useSelector, useDispatch } from "react-redux";
export default () => {
  const match = useRouteMatch();

  const { t } = useTranslation();
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData() {
      try {
        const path = match.url.replace("/show/", "");
        console.log("get path");
        console.log(path);

        const db = firebase.firestore();
        const doc = await db.doc(path).get();
        let d = doc.data();
        console.log("document");
        console.log(d);
        setDocument(d.languages.de);
        dispatch(setNavigationTitle(d.business.name, `/show/public/${d.business.id}/pages/index`));
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    fetchData();
    
  }, [dispatch, match]);
  console.log(document);
  return loading ? (
    "loadding"
  ) : document && document.parsys ? (
    <>
      <Helmet>
        <title>{document.meta.title}</title>
      </Helmet>
      <Editor content={document.parsys} currentPage={document} editing={false} />
    </>
  ) : (
    <h1>loading </h1>
  );
};
