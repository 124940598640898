import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

function findComponentById(id, items) {
  for (let key in items) {
    if (items[key].id === id) {
      return items[key];
    } else if (items[key].parsys) {
      let result = findComponentById(id, items[key].parsys);
      if (result) {
        return {
          ...result,
        };
      }
    }
  }
  return null;
}

const useEditor = () => {
  const editor = useSelector((state) =>
    state.editor ? state.editor.document.present : null
  );

  const currentPage = useSelector((state) => {    
    if(!state.editor.document.present || !state.editor.document.present.currentPage)
      return null;
    let version = state.editor.document.present.currentPage.versions.find(
      (v) => v.id === state.editor.document.present.currentPage.currentVersion
    );
    if (version) {
      return version.languages[state.editor.config.currentLanguage];
    }
    return null;
  });

  return [currentPage, editor];
};

export default useEditor;

export const useSelectedComponent = () => {
  const [component, setComponent] = useState(null);
  const [currentPage] = useEditor();

  const selectedComponents = useSelector((state) => {
    return state.editor.config.selectedComponents;
  });

  useEffect(() => {
    if (selectedComponents.length === 1 && currentPage) {
      let c = JSON.parse(JSON.stringify(currentPage.parsys));

      let results = findComponentById(selectedComponents[0].id, c);

      if (results) {
        setComponent(results);
      }
    }
  }, [selectedComponents, currentPage]);

  return [component, currentPage];
};
