import { types } from "../actions/login";

const initialState = {
  loading: false,
  loggedIn: false,
  user: null,
  init: false,
  messagingToken: null,
};

export default function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.MESSAGING.SET_TOKEN:
      return {
        ...state,
        messagingToken: action.token,
      };
    case types.LOGIN.REQUEST:
    case types.LOGIN.REQUEST_EMAIL_PASSWORD:
    case types.LOGOUT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN.SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        sendEmailVerificationSuccess: true,
      };
    case types.LOGIN.SEND_EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        sendEmailVerificationSuccess: false,
        sendEmailVerificationError: action.error,
      };
    case types.LOGIN.SEND_PASSWORD_RESET_EMAIL_FAILURE:
      return {
        ...state,
        sendPasswordResetEmailFailure: action.error,
      };
    case types.LOGIN.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        sendPasswordResetEmailSuccess: true,
      };
    case types.LOGIN.SEND_PASSWORD_RESET_EMAIL_CLEAR:
      return {
        ...state,
        loading: false,
        sendPasswordResetEmailSuccess: false,
        sendPasswordResetEmailFailure: null,
      };
    case types.ACCOUNT.UPDATE_PROFILE:
      console.log("UPDATE_PROFILE")      
      const profile = action.profile.data();
      if(state?.user?.profile && !state?.user?.profile?.developer && profile?.developer) {
        alert("Congratulations! You got promoted to Developer Status")
      }
      return {
        ...state,
        user: {
          ...state.user,
          profile,
        },
      };
    case types.ACCOUNT.UPDATE_ACCOUNT:
      console.log(action.account.data());
      return {
        ...state,
        user: {
          ...state.user,
          account: action.account.data(),
        },
      };
    case types.ACCOUNT.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePasswordError: action.error,
      };
    case types.ACCOUNT.UPDATE_PASSWORD_SUCESS:
      return {
        ...state,
        updatePasswordSuccess: true,
      };
    case types.ACCOUNT.UPDATE_PASSWORD_CLEAR:
      return {
        ...state,
        loading: false,
        updatePasswordSuccess: false,
        updatePasswordError: null,
      };

    case types.ACCOUNT.UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN.SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        init: true,
        user: action.user,
      };
    case types.LOGIN.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.LOGOUT.SUCCESS:
      return { ...initialState, init: true };
    case types.LOGOUT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.INIT.START:
      return {
        ...state,
        init: false,
      };
    case types.INIT.FINISHED:
      return {
        ...state,
        init: true,
      };
    default:
      return state;
  }
}
