import React, { useState } from "react";
import { useQuery } from "react-query";
import Card from "react-bootstrap/Card";
import firebase from "firebase";
import Console from "components/console";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Table from "react-bootstrap/Table";
import Editor from "editor";
import ContentTypes from "editor/ContentTypes";
import { default as MainToolbar } from "components/console/toolbar";
import SettingsDialog from "components/admin/SettingsDialog";
import NewPackageDialog from "components/admin/NewPackageDialog";
import ProductSettingsDialog from "components/admin/ProductSettingsDialog";
import UsersList from "components/admin/UsersList";
import Status from "components/Status";
import UsersPage from "./UsersPage";
import { useDispatch, useSelector } from "react-redux";
import {
  faCogs,
  faUsersCog,
  faEdit,
  faCircle,
  faCheckCircle,
  faServer,
  faPlus,
  faBoxes,
} from "@fortawesome/pro-light-svg-icons";
import { faUserSecret, faKey } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "react-query";
import classNames from "classnames";

import {
  faGithub,
  faDocker,
  faDigitalOcean,
} from "@fortawesome/free-brands-svg-icons";

const EditPackage = ({ packages }) => {
  const { params } = useRouteMatch();
  console.log("EditPackage");
  console.log(params.id);

  return packages ? (
    <NewPackageDialog data={packages?.find((p) => p.id === params.id)} />
  ) : null;
};

// {!business.pages || business.pages.length === 0 ? <strong>Landing Page*</strong> : "Landing Page"}
export default () => {
  const queryClient = useQueryClient();
  const { params } = useRouteMatch();

  const getDevSettings = async () => {
    const querySnapshot = await firebase
      .firestore()
      .collection("settings")
      .doc("dev")
      .get();
    return querySnapshot.data();
  };

  const { status, data, error, isFetching } = useQuery(
    "devsettings",
    getDevSettings
  );

  const getCloudSettings = async () => {
    const querySnapshot = await firebase
      .firestore()
      .collection("settings")
      .doc("prod")
      .get();
    return querySnapshot.data();
  };

  const { data: prodData } = useQuery("prodsettings", getCloudSettings);

  const getPackages = async () => {
    const querySnapshot = await firebase
      .firestore()
      .collection("packages")
      .get();
    console.log("packages blabla");
    console.log(querySnapshot);

    const result = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const d = await doc.data();
        return { ...d, id: doc.id, pp: "packageblabla" };
      })
    );
    return result;
  };

  const { data: packagesData } = useQuery("packages", getPackages);

  const [selectedPackage, setSelectedPackage] = useState();

  let user = useSelector((state) => state.login.user);

  return (
    <Switch>
      <Route path="/admin/user">
        <UsersPage />
      </Route>

      <Route path="/">
        <div className="regular-layout">
          <Console
            menuItems={
              <Switch>
                <Route path="/admin/packages">
                  <Console.LinkItems
                    items={[
                      {
                        icon: faPlus,
                        text: "New",
                        items: [
                          {
                            text: "Package",
                            to: `/new`,
                            icon: faServer,
                            rewrite: true,
                          },
                        ],
                      },
                      {
                        text: "Edit",
                        icon: faEdit,
                        to: `/${selectedPackage}/edit`,
                        rewrite: true,
                        disabled: !selectedPackage,
                      },
                    ]}
                  />
                </Route>
              </Switch>
            }
            navigation={
              <div className="mt-0 h-100">
                <div style={{ overflow: "hidden" }} className="h-100">
                  <MainToolbar.Item
                    to={`/admin/packages`}
                    icon={faBoxes}
                    sidebar
                  >
                    Packages
                  </MainToolbar.Item>

                  <MainToolbar.Item
                    to={`/admin/settings`}
                    icon={faCogs}
                    sidebar
                  >
                    Settings
                  </MainToolbar.Item>

                  <MainToolbar.Item
                    to={`/admin/users`}
                    exact
                    icon={faUsersCog}
                    sidebar
                  >
                    Users
                  </MainToolbar.Item>
                </div>
              </div>
            }
          >
            <Route path="/admin/packages">
              <div className="container pt-3">
                <h1 className="mb-3">Packages</h1>
                <table className="table table-dark table-bordered table-hover">
                  <thead>
                    <tr className="rounded">
                      <th>id</th>
                      <th>name</th>
                      <th>title</th>
                      <th>Notes</th>
                      <th>State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packagesData?.map((pg) => {
                      console.log(pg);
                      return (
                        <tr
                          style={{ cursor: "pointer" }}
                          className={`${
                            selectedPackage === pg.id ? "table-active" : ""
                          }`}
                          onClick={() => {
                            setSelectedPackage(
                              selectedPackage === pg.id ? null : pg.id
                            );
                          }}
                        >
                          <td>{pg.id}</td>
                          <td>{pg.name}</td>
                          <td>{pg.title}</td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: pg?.notes?.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br>"
                              ),
                            }}
                          ></td>
                          <td className="text-center"><Status value={pg.active} /></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Switch>
                  <Route path="/admin/packages/:id/edit">
                    <EditPackage packages={packagesData} />
                  </Route>
                  <Route path="/admin/packages/new">
                    <NewPackageDialog data={{}} />
                  </Route>
                </Switch>
              </div>
            </Route>
            <Route path="/admin/settings">
              <div className="container pt-3">
                <h1 className="pb-3">Settings</h1>

                <div className="row">
                  <div className="col">
                    <Card style={{ width: "18rem" }}>
                      <Card.Body>
                        <Card.Title>
                          <FontAwesomeIcon icon={faGithub} />{" "}
                          <FontAwesomeIcon icon={faDocker} /> Development
                        </Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          Local Environment Settings
                        </Card.Subtitle>
                        <Card.Text>
                          <Table>
                            <tr>
                              <td>GitHub Token</td>
                              <td
                                className={classNames({
                                  "text-success":
                                    data?.GithubToken &&
                                    data?.GithubToken.length > 0,
                                  "text-danger":
                                    !data?.GithubToken ||
                                    data?.GithubToken.length === 0,
                                })}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    data?.GithubToken &&
                                    data?.GithubToken.length > 4
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>GitHub User</td>
                              <td
                                className={classNames({
                                  "text-success":
                                    data?.GithubUser &&
                                    data?.GithubUser.length > 0,
                                  "text-danger":
                                    !data?.GithubUser ||
                                    data?.GithubUser.length === 0,
                                })}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    data?.GithubUser &&
                                    data?.GithubUser.length > 4
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Docker Login Command</td>
                              <td
                                className={classNames({
                                  "text-success":
                                    data?.DockerLoginCommand &&
                                    data?.DockerLoginCommand.length > 0,
                                  "text-danger":
                                    !data?.DockerLoginCommand ||
                                    data?.DockerLoginCommand.length === 0,
                                })}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    data?.DockerLoginCommand &&
                                    data?.DockerLoginCommand.length > 4
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Docker Componse Template</td>
                              <td
                                className={classNames({
                                  "text-success":
                                    data?.GithubInstallTemplate &&
                                    data?.GithubInstallTemplate.length > 0,
                                  "text-danger":
                                    !data?.GithubInstallTemplate ||
                                    data?.GithubInstallTemplate.length === 0,
                                })}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    data?.GithubInstallTemplate &&
                                    data?.GithubInstallTemplate.length > 4
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                />
                              </td>
                            </tr>
                          </Table>
                        </Card.Text>
                        <LinkContainer to="/admin/settings/dev">
                          <Card.Link>
                            <FontAwesomeIcon icon={faEdit} /> Edit
                          </Card.Link>
                        </LinkContainer>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col">
                    <Card
                      style={{ width: "18rem" }}
                      bg="danger"
                      text="light"
                      className="shadow-lg"
                    >
                      <Card.Body>
                        <Card.Title className="font-weight-bold">
                          <span style={{ color: "white" }}>
                            <FontAwesomeIcon icon={faDigitalOcean} />
                          </span>{" "}
                          <span style={{ color: "white" }} className="p-2">
                            <FontAwesomeIcon icon={faUserSecret} />
                          </span>{" "}
                          Danger Area
                        </Card.Title>
                        <Card.Subtitle className="mb-2">
                          Production Cloud Configuration
                        </Card.Subtitle>
                        <Card.Text>
                          <Table className="bg-light py-2 my-5">
                            <tr>
                              <td className="font-weight-bold">
                                <span style={{ color: "orange" }}>
                                  <FontAwesomeIcon icon={faKey} />
                                </span>{" "}
                                Master Key
                              </td>
                              <td
                                className={classNames({
                                  "text-success":
                                    prodData?.masterKey &&
                                    prodData?.masterKey.length > 0,
                                  "text-danger":
                                    !prodData?.masterKey ||
                                    prodData?.masterKey.length === 0,
                                })}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    prodData?.masterKey &&
                                    prodData?.masterKey?.length > 4
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span style={{ color: "blue" }}>
                                  <FontAwesomeIcon icon={faDigitalOcean} />
                                </span>{" "}
                                DigitalOcean Token
                              </td>
                              <td
                                className={classNames({
                                  "text-success":
                                    prodData?.dcToken &&
                                    prodData?.dcToken.length > 0,
                                  "text-danger":
                                    !prodData?.dcToken ||
                                    prodData?.dcToken.length === 0,
                                })}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    prodData?.dcToken &&
                                    prodData?.dcToken?.length > 4
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                />
                              </td>
                            </tr>
                          </Table>
                        </Card.Text>
                        <LinkContainer to="/admin/settings/prod">
                          <Card.Link className="text-white">
                            <FontAwesomeIcon icon={faEdit} /> Edit
                          </Card.Link>
                        </LinkContainer>
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                <Route path="/admin/settings/dev">
                  <SettingsDialog data={data} />
                </Route>
                <Route path="/admin/settings/prod">
                  <ProductSettingsDialog data={prodData} />
                </Route>
              </div>
            </Route>
          </Console>
        </div>
      </Route>
    </Switch>
  );
};
