import { types } from "../actions/console";

const initialState = {
  success: false,
  loading: false,
  business: [],
  msg: "data store working",
};
export const updateContactInformation = function createReducer(
  state = initialState,
  action = {}
) {  
  switch (action.type) {
    case types.UPDATE.CONTACT_INFORMATION:
      console.log("action.type " + action.type )
      console.log("types.UPDATE.CONTACT_INFORMATION above");
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE.CONTACT_INFORMATION_SUCCESS:
      console.log("action.type " + action.type )
      console.log("types.UPDATE.CONTACT_INFORMATION above");
      return {
        ...state,
        success: true,
        loading: false,
      };
    case types.UPDATE.CONTACT_INFORMATION_FAILURE:
      console.log("action.type " + action.type )
      console.log("types.UPDATE.CONTACT_INFORMATION above");
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
