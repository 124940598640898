import React, { useEffect } from "react";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import "./styles/index.scss";
import TopNavigationBar from "./components/TopNavigationBar";
import LandingPage from "./pages/LandingPage";
import BusinessDetailPage from "./pages/BusinessDetailPage";
import BusinessAdminPage from "./pages/BusinessAdminPage";
import AccountSettingsPage from "./pages/AccountSettings";
import LoginPage from "./pages/LoginPage";
import MyStarletPage from "./pages/MyStarletPage";
import Sites from "./components/account/sites";
 
import AppBar from "./ngh/components/AppBar";
import { faKey } from "@fortawesome/pro-light-svg-icons";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase";

//TopNavigationBar />
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from "react-instantsearch-dom";
const APP_ID = "DKKUV6H3QY";

const API_KEY = "c81acb1345aae053a7182d645e252ddb";

const searchClient = algoliasearch(APP_ID, API_KEY);


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_DfbBnA1XQasNnqNLD5TZNVZK00CF7Yp9Z5');


/*

attributeForDistinct: 'section',
  distinct: true*/

function App() {
  const registrationToken = useSelector(
    (state) => state.messaging.registrationToken
  );
  const user = useSelector((state) => state.login.user);
  console.log(user)
  useEffect(() => {
    async function updateDevice() {
      if (
        user !== null &&
        (!user.devices ||
          user.devices.indexOf(registrationToken) === -1)
      ) {
       /* try {
          console.log("updating " + user.uid);
          const a = await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .update({
              devices: firebase.firestore.FieldValue.arrayUnion(
                registrationToken
              ),
            });
          console.log("ASDFASDFASDF");
          console.log(a);
        } catch (e) {
          console.log("app rerror")
          //alert(e);
        }*/
        //firebase.firestore
      }
    }

    updateDevice();
  }, [
    registrationToken,
    user    
  ]);
  return (
    <Elements stripe={stripePromise}>
    <InstantSearch
      searchClient={searchClient}
      indexName="dev_business"
      distinct
    >
      <Configure distinct="2" />
      <div className="App">
        <Router>
          <TopNavigationBar />
          <Switch>
            <Route path="/support">

              <iframe className="w-100 h100" src="https://solutas.atlassian.net/servicedesk/customer/portal/3">

              </iframe>
            </Route>
          <Route path="/sites">
            {!user ? <Redirect to="/login" ></Redirect> : <Sites /> }
            </Route>
            <Route path="/mystarlet">
            {!user ? <Redirect to="/login" ></Redirect> : <MyStarletPage /> }
            </Route>
            <Route path="/account-settings">
            {!user ? <Redirect to="/login" ></Redirect> : <AccountSettingsPage /> }
            </Route>
            <Route path="/console">
            {!user ? <Redirect to="/login" ></Redirect> : <BusinessAdminPage /> }
            </Route>
            <Route path="/show/*">
            {!user ? <Redirect to="/login" ></Redirect> :<BusinessDetailPage />}
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/admin">
            {!user || !user.isSuperAdmin ? <Redirect to="/login" ></Redirect> : 
              <MyStarletPage/> 
              }
            </Route>
            <Route path="/">
            {!user ? <Redirect to="/login" ></Redirect> : 
            
             user.isSuperAdmin ? <Redirect to="/admin" /> : <Redirect to="/sites" />
              
}
            </Route>
          </Switch>
        </Router>
      </div>
    </InstantSearch>
    </Elements>
  );
}

export default App;
