class WebCo extends HTMLElement {
  i = 0;
  shadow;
  constructor() {
    super(); // always call super() first in the constructor.
    this.shadow = this.attachShadow({ mode: "open" });

    /*this.addEventListener("click", (e) => {
      this.i = this.i + 1;
      this.render();
    });*/
    this.render();
    //this.i = this.i + 1;

    
  }

  attachEvents = () => {
    this.shadow.querySelector("#shit").addEventListener("click", (e) => {
        alert(this.i);
      });
  
      this.shadow.querySelector("#clickme").addEventListener("click", (e) => {
        this.i = this.i + 1;
        this.render();
      });
  }

  render = () => {
    this.shadow.innerHTML = `
        <button id="clickme">test i${this.i}</button>
        <h2 id="shit">h2 slot title: <slot name="title"></slot></h2>
      `;
      this.attachEvents();
  };
}

customElements.define("test-me", WebCo);
