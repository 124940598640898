import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./redux";
import "./i18n";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import firebase from "firebase";

import {
  SwitchTransition,
  CSSTransition,
  Transition,
} from "react-transition-group";

import * as serviceWorker from "./serviceWorker";
const queryClient = new QueryClient();

function AuthIsLoaded({ children }) {
  const init = useSelector((state) => state.login.init);
  const user = useSelector((state) => state.login.user);

  useEffect(() => {
    if (init && user) {
      const subscriptions = firebase
        .firestore()
        .collection("customers")
        .doc(user.uid)
        .collection("subscriptions")
        .onSnapshot(function (querySnapshot) {
          queryClient.invalidateQueries("subscriptions");
        });

        const sites = firebase
        .firestore()
        .collection("customers")
        .doc(user.uid)
        .collection("sites")
        .onSnapshot(function (querySnapshot) {
          queryClient.invalidateQueries("sites");
        });        
      const userRef = firebase
        .firestore()
        .collection("customers")
        .onSnapshot((qsnap) => queryClient.invalidateQueries("customers"));

      return function () {
        subscriptions();
        userRef();
        sites();
      };
    }
  }, [init, user]);
  return (
    <>
      {!init ? (
        <Container
          className=" mv-100 h-100 d-flex justify-content-center align-items-center"
          fluid
        >
          <Spinner
            style={{
              width: "150px",
              height: "150px",
              color: "#dd851b",
            }}
            animation="grow"
          />
        </Container>
      ) : (
        children
      )}
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthIsLoaded>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </AuthIsLoaded>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => {
    alert("updating installation");
    window.location.reload();
  },
  onSuccess: () => console.log("pwa installed successfully"),
});

serviceWorker.unregister();
