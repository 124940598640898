import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";

import Nav from "react-bootstrap/Nav";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faKey } from "@fortawesome/free-solid-svg-icons";

import {
  faCity,
  faUserCircle,
  faSignInAlt,
  faStarShooting,
  faSignOutAlt,
} from "@fortawesome/pro-light-svg-icons";

import { login, logout } from "../../redux/actions/login";

const LinkItem = ({ to, icon, children }) => (
  <LinkContainer to={to}>
    <Dropdown.Item>
      {icon ? <FontAwesomeIcon icon={icon} className="mr-2" /> : null}
      {children}
    </Dropdown.Item>
  </LinkContainer>
);

export default () => {
  const { t, i18n } = useTranslation();
  const [toggleOpen, setToggleOpen] = useState();
  const user = useSelector((state) => state.login.user);
  const business = useSelector((state) => state.userData.business);
  const dispatch = useDispatch();
  return (
    <>
      {user ? (
        <>
          <Dropdown
            alignRight
            onToggle={(isOpen) => setToggleOpen(isOpen)}
            as={ButtonGroup}
          >
            <Dropdown.Toggle
              id="user-dropdown"
              as={Nav.Link}
              className="socialtoken"
            >
              {user && user.photoURL ? (
                <img
                  src={user.photoURL}
                  alt=""
                  height="30"
                  className="rounded-circle"
                  title={
                    user && user.account && user.account.business
                      ? user.account.business.length
                      : 0
                  }
                />
              ) : (
                <FontAwesomeIcon
                  style={{ color: "white" }}
                  icon={
                    user
                      ? 
                         faUserCircle
                      : faSignInAlt
                  }
                  className="mr-2"
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <LinkItem to={`/profile/${user.uid}`} icon={faUserCircle}>
                {t("Profile")}
              </LinkItem>
              <LinkItem to="/account-settings" icon={faKey}>
                {t("Account")}
              </LinkItem>
              
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Header>
                  <FontAwesomeIcon icon={faCity} className="mr-2" />
                  {t("Account Type")}
                </Dropdown.Header>
                  <div className="py-3">{user.isDeveloper ? "Developer" : "User"}</div>
              

              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item onClick={() => dispatch(logout())}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />{" "}
                {t("Logout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/*          <DropdownButton id="dropdown-item-button" title={i18n.language}>
            <Dropdown.Item as="button" active={i18n.language === 'en'} onClick={()=>i18n.changeLanguage('en')}>
              en
            </Dropdown.Item>
            <Dropdown.Item as="button" active={i18n.language === 'de'} onClick={()=>i18n.changeLanguage('de')}>
              de
            </Dropdown.Item>
          </DropdownButton>*/}
        </>
      ) : (
        <>
          <LinkContainer to="/login">
            <Nav.Link>{t("Login")}</Nav.Link>
          </LinkContainer>
          <DropdownButton id="dropdown-item-button" title={i18n.language}>
            <Dropdown.Item
              as="button"
              active={i18n.language === "en"}
              onClick={() => i18n.changeLanguage("en")}
            >
              en
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              active={i18n.language === "de"}
              onClick={() => i18n.changeLanguage("de")}
            >
              de
            </Dropdown.Item>
          </DropdownButton>
        </>
      )}
    </>
  );
};
