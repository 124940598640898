import React, { useContext } from "react";
import EditorContext from "editor/EditorContext";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";
import Component from "editor/Component";
import Dialog from "components/Dialog";
import Placeholder from "components/placeholder";

const Text = ({ text, id, type, ...props }) => {
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      <div>
        {text ? (
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></span>
        ) : (
          <Placeholder
          title="empty text"
          text="edit component and add text"
        />
        )}
      </div>
    </Component>
  ) : (
    <div>
      {text ? (
        <span
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></span>
      ) : null}
    </div>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.TEXT,
  text: EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(""))
  ),
});

Text.beforeSave = (component) => ({
  ...component,
  text: convertToHTML(component.text.getCurrentContent()),
});

Text.createInitialValue = (component, add) =>
  add
    ? defaultStructure()
    : {
        ...component,
        text: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(component.text || "")
          )
        ),
      };

Text.title = "Text Component";

Text.displayFormFields = () => (
  <Dialog.FormGroup name="text" type="richtext" label="Text" />
);

Text.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.TEXT,
  ContentTypes.HERO,
  ContentTypes.TEXT_IMAGE,
];

export default Text;
