import React from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import AccountPicture from "assets/undraw_address_udes.svg";
import ProductsPicture from "assets/undraw_empty_cart_co35.svg";
import CompanyProfile from "assets/undraw_account_490v.svg";
import classnames from "classnames";
import "./webco";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Link,
    useHistory
  } from "react-router-dom";
export default ({ business }) => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  let history = useHistory();
  return business ? (
    <div className="container main-center">
      
      <div className="card-group admin">
        <div className="card" onClick={()=>history.push(`${match.url}/contact`)}>
          <img src={AccountPicture} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{t("Contact Information")}</h5>
            <address>
              <strong>{business.companyName}</strong>
              <br />
              {business.address1}
              <br />
              {business.address2}
              <br />
              {business.zip} {business.city}, {business.country}
              <br />
            </address>
            <p className="card-text"></p>
          </div>
        </div>
        <div className="card" onClick={()=>history.push(`${match.url}/products`)}>
          <img src={ProductsPicture} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">Products &amp; Plans</h5>
            <p className="card-text">Manage Products and Plans</p>
          </div>
          <div className="card-footer">
            <small className="text-muted">Last updated 3 mins ago</small>
          </div>
        </div>
        <div className="card" onClick={()=>history.push(`${match.url}/profile`)}>
          <img src={CompanyProfile} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{t("Public Profile")}</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
          </div>
          <div className="card-footer">
            <small className="text-muted">Last updated 3 mins ago</small>
          </div>
        </div>
        <div
          className={classnames({
            card: true,
            "bg-danger": !business.stripe || !business.stripe.payouts_enabled,
            "bg-success": business.stripe  && business.stripe.payouts_enabled,
            "text-white": business.stripe && !business.stripe.payouts_enabled
          })}
        >
          <div className="card-body">
            <h5 className="card-title">{t("Payment / Payouts")}</h5>
            <p className="card-text">
              {!business.stripe ? <h4 className="text-white">You need to verify your account information</h4> : null}
              {business.stripe && !business.stripe.payouts_enabled &&
              business.stripe.requirements.disabled_reason ? (
                <>
                  <strong>
                    Following Information are needed before you can accept
                    payments
                  </strong>
                  <ul>
                    {business.stripe[
                      business.stripe.requirements.disabled_reason.split(".")[0]
                    ][
                      business.stripe.requirements.disabled_reason.split(".")[1]
                    ].map(item => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </>
              ) : null}
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{t("Customers")}</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
          </div>
          <div className="card-footer">
            <small className="text-muted">Last updated 3 mins ago</small>
          </div>
        </div>
      </div>
      {/*business.stripe ? (
        <ReactJson src={business.stripe}></ReactJson>
      ) : (
        "no stripe connection"
      )*/}
    </div>
  ) : null;
};
