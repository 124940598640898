import React from "react";
import classNames from "classnames";

export default ({ children, open }) => {
  return (
    <section
      className={classNames({
        "ngh-console__container__navigation": true,
        "ngh-console__container__navigation--open": open,
      })}
    >
      <div>{children}</div>
    </section>
  );
};
