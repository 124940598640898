import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link
} from "react-router-dom";
import { useSelector } from "react-redux";

import Password from "./password";

export default () => {
    const { t } = useTranslation();
    let match = useRouteMatch();
    const user = useSelector(state => state.login.user);
  
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/account-settings">{t("Account")}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t("Login")}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1>{t("Login & Security")}</h1>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-2">
          <h1 className="h6">{t("E-Mail")}</h1>
        </div>
        <div className="col">
            {user.email}
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <h1 className="h6">{t("Password")}</h1>
        </div>
        <div className="col">
            <Password />
        </div>
      </div>      
    </div>
  );
};
