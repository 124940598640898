import React from "react";
import { Link } from "react-router-dom";
import ProfileIconToggle from "./ProfileIconToggle";
import { useDispatch, useSelector } from "react-redux";
import useScroll from "../../hooks/scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import {
  faCrosshairs,
  faFire,
  faArrowLeft,
  faUserCircle,
  faSignInAlt,
  faUserAstronaut,
  faUser,
  faGlobe
  
} from "@fortawesome/free-solid-svg-icons";
import AppBar from "ngh/components/AppBar";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";
/* <div
          className={classNames({
            topnavbar: true,
            business: true,
            "pr-3": true,
            "bg-solwhite": true,
            scroll: !scroll
          })}
        >
          <div className="system-logo mr-auto">
            <Link to="/">
              <FontAwesomeIcon icon={faSparkles} className="mr-2 text-solblue" />
              <>
                <span className="first">solu</span>
                <span className="second">neo</span>
              </>
            </Link>
          </div>
          <ProfileIconToggle />
        </div>*/

import { Switch, Route, useRouteMatch } from "react-router-dom";

import ConsoleBar from "./Console";

export default () => {
  const scroll = useScroll(30);
  let match = useRouteMatch();
  const business = useSelector(state =>
    state.userData.business.find(item => item.uid === match.params.id)
  );
  const user = useSelector(state => state.login.user);
  const userData = useSelector(state => state.userData);

  return (
    <Switch>
     
      <Route path={[`${match.url}`, `${match.url}/login`, `${match.url}/subscriptions`]} exact>
      <AppBar title="Soluneo" userData={user} right={<ProfileIconToggle/>}
      navItems={[
        {
          text: "Sites",
          icon: faGlobe,
          to: `/sites`,
        },        
      ]}
      />
       
      </Route>
      {user ? (
        <Route path={`${match.url}/:id`}>
          <ConsoleBar scroll={scroll} />          
        </Route>
      ) : null}
    </Switch>
  );
};
