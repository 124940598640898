import React from "react";
import { TouchBackend} from "react-dnd-touch-backend";
import  { HTML5Backend as Backend} from "react-dnd-html5-backend";

import { DndProvider } from "react-dnd";
import ContentMapper from "./ContentMapper";
import * as Content from "./content";
import ContentTypes from "./ContentTypes";
import EditorContext from "./EditorContext";
export { default as Component } from "./Component";

export const returnComponentByType = (type) => {
  //console.log(type)
  switch (type) {
    case ContentTypes.HEADLINE:
      return Content.Headline;
    case ContentTypes.TEXT:
      return Content.Text;
    case ContentTypes.TEXT_IMAGE:
      return Content.TextImage;
    case ContentTypes.SECTION:
      return Content.Section;
    case ContentTypes.COLUMNS:
      return Content.Columns;
    case ContentTypes.CARD:
      return Content.Card;
    case ContentTypes.HERO:
      return Content.Hero;
    case ContentTypes.VIDEO:
      return Content.Video;
    case ContentTypes.COLUMN:
      return Content.Column;
    case ContentTypes.PAGE:
      return Content.Page;
    case ContentTypes.COURSE_SCHEDULE:
      return Content.Schedule;
    case ContentTypes.COURSE_LIST:
      return Content.ScheduleList;
    default:
      return null;
  }
};

const isTouch =
  "ontouchstart" in window ||
  navigator.MaxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export default ({ content, editing = true, currentPage }) => {
  return currentPage ? (
    <EditorContext.Provider value={{ editing, currentPage }}>
      {editing ? (
        <div className="w-100 h-100">
          <DndProvider
            backend={isTouch ? TouchBackend : Backend}
            options={{
              enableMouseEvents: true,
              enableTouchEvents: true,
              delayTouchStart: 100,
            }}
          >
            <ContentMapper content={content} />
          </DndProvider>
        </div>
      ) : (
        <ContentMapper content={content} />
      )}
    </EditorContext.Provider>
  ) : null;
};
