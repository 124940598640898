import React from "react";
import AddNewDialog from "./AddNewDialog";

import { Switch, Route, useRouteMatch } from "react-router-dom";
import Overview from "./Overview";
import Detail from "./Detail";

const Subscription = ({ business }) => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <Detail business={business} />
      </Route>
      <Route path={`${match.path}`}>
        <Overview business={business} />
      </Route>
    </Switch>
  );
};
Subscription.Dialog = AddNewDialog;

export default Subscription;
