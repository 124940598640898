import React, { useEffect } from "react";
import { Formik, useField, useFormikContext } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "components/Spinner";

const FormGroup = ({ label, placeholder, options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group>
      {props.type && props.type === "checkbox" ? (
        <>
          {" "}
          <Form.Check
            {...field}
            {...props}
            onChange={field.onChange}
            type="checkbox"
            onBlur={field.onBlur}
            isValid={meta.touched && !meta.error}
            label={label}
            isInvalid={meta.error}
          />
          {meta.error}
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </>
      ) : (
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            {...field}
            {...props}
            as={options ? "select" : "input"}
            onChange={field.onChange}
            onBlur={field.onBlur}
            isValid={meta.touched && !meta.error}
            isInvalid={meta.error}
            placeholder={placeholder ? placeholder : ""}
          >
            {options
              ? options.map(item => (
                  <option value={item.value}>{item.label}</option>
                ))
              : null}
          </Form.Control>

          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        </>
      )}
    </Form.Group>
  );
};

const CaptureValues = ({ onChange }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    onChange(values);
    console.log("capture values");
    console.log(values);
  }, [onChange, values]);

  return null;
};

export default class Wizard extends React.Component {
  static Page = ({ children }) => children;
  static FormGroup = FormGroup;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues
    };
  }

  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, bag) => {
    const { children, onSubmit, submitButton } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    const { validation, disableWizard, loading, submitButton, error } = activePage.props;

    const onChange = this.props.onChange;

    return (
      <div className="container">
        <Formik
          validationSchema={validation}
          initialValues={values}
          enableReinitialize={false}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isSubmitting,
            isValid,
            dirty,
            errors
          }) => (
            <form onSubmit={handleSubmit}>
              <CaptureValues
                onChange={values => (onChange ? onChange(values) : null)}
              />
              <div className="card" style={{ minHeight: "550px" }}>
                <div className="card-header">
                  <h6 className="text-dark">Register Business</h6>
                  <ProgressBar
                    now={page}
                    max={children.length - 1}
                    variant="info"
                  />
                </div>
                <div className="card-body">{activePage}</div>
                <div className="card-footer text-muted wizard-steps-buttons">
                  {!disableWizard && page > 0 && (
                    <Button
                      type="button"
                      variant="info"
                      className="secondary"
                      onClick={this.previous}
                    >
                      « Previous
                    </Button>
                  )}

                  {!isLastPage && (
                    <Button
                      variant="info"
                      type="submit"
                      className="ml-auto"
                      disabled={!isValid}
                    >
                      Next »
                    </Button>
                  )}
                  {isLastPage && !submitButton && (
                    <Button
                      variant="brand"
                      type="submit"
                      disabled={loading}
                      className="ml-auto"
                    >
                      {loading ? <Spinner variant="white" /> : null}
                      Submit
                    </Button>
                  )}
                  {submitButton ? submitButton : null}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
