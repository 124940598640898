import React from "react";
import ContentTypes from "./ContentTypes";

import Headline from "./content/Headline";
import Text from "./content/Text";
import TextImage from "./content/TextImage";
import Section from "./content/Section";
import Columns from "./content/Columns";
import Card from "./content/Card";
import Hero from "./content/Hero";
import Video from "./content/Video";
import Schedule from "./content/courses/Schedule";
import ScheduleList from "./content/courses/ScheduleList";

export default ({ content, ...props }) => {
  function renderItem(item) {
    switch (item.type) {
      case ContentTypes.HEADLINE:
        return <Headline {...item} {...props} key={props.id} />;
      case ContentTypes.TEXT:
        return <Text {...item} {...props} key={props.id} />;
      case ContentTypes.TEXT_IMAGE:
        return <TextImage {...item} {...props} key={props.id} />;
      case ContentTypes.SECTION:
        return <Section {...item} {...props} key={props.id} />;
      case ContentTypes.CARD:
        return <Card {...item} {...props} key={props.id} />;
      case ContentTypes.COLUMNS:
        return <Columns {...item} {...props} key={props.id} />;
      case ContentTypes.HERO:
        return <Hero {...item} {...props} key={props.id} />;
      case ContentTypes.VIDEO:
        return <Video {...item} {...props} key={props.id} />;
      case ContentTypes.COURSE_LIST:
        return <ScheduleList {...item} {...props} key={props.id} />;
      case ContentTypes.COURSE_SCHEDULE:
        return <Schedule {...item} {...props} key={props.id} />;
      default:
        return <strong>Unkown component {item.type} </strong>;
    }
  }
  return content.map((item) => renderItem(item));
};
