/********************************************************************************
 * Copyright (C) 2019 SOLUTAS GmbH. All Rights Reserved.
 *
 * Paradieshofstrasse 117, 4054 Basel, Switzerland
 * http://www.solutas.ch | info@solutas.ch
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 ********************************************************************************/

// copiped from https://react.i18next.com/latest/using-with-hooks

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import LocaleEn from "./locales/en/translation.json";
import LocaleDe from "./locales/de/translation.json";

const resources = {
  en: { translation: LocaleEn },
  de: { translation: LocaleDe }
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      default: ['en']
    },
    
    debug: true,
    resources,    
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      wait: true
    }
  });

export default i18n;