import { all, fork } from 'redux-saga/effects'

//import analytics from './analytics'
//import functions from './functions'
import login from './login'
import console from "./console";
import business from "./business";
import editor from "./editor";
import messaging from "./messaging";
//import messaging from './messaging'
//import storage from './storage'
//import todos from './todos'

export default function* rootSaga() {
  yield all([
    fork(login),
    fork(console),
    fork(business),
    fork(editor),
    //fork(messaging),
  ])
}