import React from "react";
import Status from "components/Status";
import DetailHeader from "components/DetailHeader";

import { Link } from "react-router-dom";

export default ({ business }) => (
  <div className="w-100 pl-3 pr-3">
    <DetailHeader header="Packages" />
    <div class="list-group">
      {business.packages
        ? business.packages.map((item) => (
            <Link
              className="list-group-item list-group-item-action flex-column align-items-start "
              style={{ cursor: "pointer" }}
              key={item.id}
              to={`/console/${business.id}/products/subscriptions/${item.id}`}
            >
              <div class="d-flex w-100 justify-content-between">
                <div>{item.name}</div>
                <Status value={item.active} />
              </div>
            </Link>
          ))
        : null}
    </div>
  </div>
);
