import React, { useState } from "react";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useQuery, { useCurrentLocation } from "hooks/searchQuery";
import Dropdown from "react-bootstrap/Dropdown";
import {
  faUserAlien,
  faCaretDown,
  faBars,
  faTimes,
  faUserNinja
} from "@fortawesome/pro-light-svg-icons";

import {  
  faPegasus
} from "@fortawesome/pro-duotone-svg-icons";

import solIcon from "../../../assets/sol-icon-white.png";

import Spacer from "./Spacer";
import Nav from "./Nav";
import NavItem from "./NavItem";

import ProfileToggle from "../ProfileToggle";

import "./AppBar.scss";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    className="ngh-AppBar--title"
    style={{ cursor: "pointer" }}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon
      icon={faCaretDown}
      className="ml-2"
      style={{ opacity: 0.8 }}
    />
  </div>
));

export default ({ navItems, title, right, userData, business, profileToggle,hideBrand,className }) => {
  const [navOpen, setNavOpen] = useState(false);
  const query = useQuery();
  const location = useCurrentLocation();
  const history = useHistory();

  function toggleNav() {
    if (query.has("sidebar")) {
      query.delete("sidebar");
    } else {
      query.set("sidebar", "");
    }
    history.push(location.pathname + "?" + query.toString());
  }

  return (
    <div className={`ngh-AppBar ${className}`}>
      {!hideBrand ? <div className="ngh-AppBar--BrandIcon ngh-AppBar--NavItem" onClick={()=>history.push('/')}>
        {userData && userData.isSuperAdmin ? 
        
        <FontAwesomeIcon
          icon={faUserNinja}
          className="icon"
        />
        :
        <img src={solIcon} alt="brand icon" style={{ height: "30px" }} />}
      </div> : null}
      {title ? (
        <>
          {userData && userData.business && userData.business.length > 1 ? (
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {userData.business.map((item) => (
                  <Dropdown.Item
                    as={Link}
                    to={`/console/${item.id}`}
                    eventKey="3"
                    active={item.id === business.id}
                  >
                    {item.companyName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div className="ngh-AppBar--title">{title}</div>
          )}
        </>
      ) : null}
      <div className="ngh-Divider"></div>
      {navItems ? (
        <Nav open={query.has("sidebar")}>
          {navItems.map((item) => (
            <NavItem
              tooltip={item.tooltip}
              icon={item.icon}
              active={item.active}
              to={item.to}
              exact={item.exact}
              onUpdate={() => null}
            >
              {item.text}
            </NavItem>
          ))}
        </Nav>
      ) : null}
      <Spacer />
      {right ? (
        right
      ) : (
        <ProfileToggle icon={faUserAlien} navigation={navItems} />
      )}

      <div
        className={classNames({
          "ngh-AppBar--BrandIcon": true,
          "ngh-AppBar--NavItem": true,
          "ngh-AppBar--Right": true,
          "ngh-AppBar--Right__open": query.has("sidebar"),
        })}
        onClick={() => toggleNav()}
      >
        <FontAwesomeIcon
          icon={query.has("sidebar") ? faTimes : faBars}
          className="icon"
        />
      </div>
    </div>
  );
};
