import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default ({ items, header }) => {
  return (
    <>
      {items ? (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb m-0">
            {items.map((item) => (
              <li
                className={classNames({
                  "breadcrumb-item": true,
                  active: !item.to,
                })}
              >
                {item.to ? <Link to={item.to}>{item.text}</Link> : item.text}
              </li>
            ))}
          </ol>
        </nav>
      ) : null}
      {header ? (
        <h1
          className={classNames({
            "mt-0": items,
            "mt-3": !items,
            "p-0": true,
            "mb-3": true,
          })}
        >
          {header}
        </h1>
      ) : null}
    </>
  );
};
