import React, { useState, useEffect } from "react";
import Console from "components/console";
import {
  Link,
  useRouteMatch,
  useHistory,
  Route,
  Switch,
  Prompt,
} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Toolbar from "components/console/toolbar";
import { useDispatch, useSelector } from "react-redux";
import Subscriptions from "./subscriptions";
import Members from "./members";

import firebase from "firebase";
import { syncPackages, syncProducts } from "redux/actions/console";
import Dialog from "components/Dialog";
import * as yup from "yup";
//export { default } from "./old";
import {
  faPlus,
  faTshirt,
  faBoxFull,
  faMoneyCheckEdit,
  faHomeAlt,
  faEdit,
  faGiftCard,
  faCogs,
  faUserCrown
} from "@fortawesome/pro-light-svg-icons";

import NewProductDialog from "./NewProductDialog";

import ListProducts from "./ListProducts";

export default ({ business }) => {
  let match = useRouteMatch();
  let history = useHistory();
  let dispatch = useDispatch();
  const editAction = useSelector((state) => state.consoleActions.editAction);

  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("business")
      .doc(business.id)
      .collection("products")
      .onSnapshot(function (querySnapshot) {
        var products = [];
        querySnapshot.forEach(function (doc) {
          products.push({ ...doc.data(), id: doc.id });
        });
        dispatch(syncProducts(business.id, products));
      });

    return function cleanup() {
      return new Promise((resolve, reject) => {
        try {
          unsubscribe();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [dispatch, business.id]);


  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("business")
      .doc(business.id)
      .collection("packages")
      .onSnapshot(function (querySnapshot) {
        var packages = [];
        querySnapshot.forEach(function (doc) {
          packages.push({ ...doc.data(), id: doc.id });
        });
        dispatch(syncPackages(business.id, packages));
      });

    return function cleanup() {
      return new Promise((resolve, reject) => {
        try {
          unsubscribe();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [dispatch, business.id]);


  const [newProductDialog, setNewProductDialog] = useState(true);
  return (
    <Console
      menuItems={
        <Switch>
          <Route path={`/console/${business.id}/products/subscriptions`}>
            <Console.LinkItems
              items={[
                {
                  icon: faPlus,
                  text: "New",
                  disabled: false,
                  items: [
                    {
                      text: "Subscription Package",
                      to: `/addsub`,
                      rewrite: true,
                      icon: faBoxFull,
                    },
                    {
                      type: 'divider'
                    },
                    {
                      text: "Product",
                      to: `/new`,
                      icon: faTshirt,
                      rewrite: true,
                    },
                    {
                      text: "Plan",
                      to: `/addplan`,
                      icon: faMoneyCheckEdit,
                      rewrite: true,
                    },                    
                  ],
                },
                {
                  text: "two",
                },
              ]}
            />
          </Route>

          <Route path={`/console/${business.id}/products/list/show/*`}>
            <Console.LinkItems
              items={[
                {
                  icon: faPlus,
                  text: "New",
                  items: [
                    {
                      text: "Product",
                      to: `/new`,
                      icon: faTshirt,
                      rewrite: true,
                    },
                    {
                      text: "Pricing Plan",
                      to: `/addplantoproduct`,
                      icon: faMoneyCheckEdit,
                      rewrite: true,
                    },
                    {
                      type: 'divider'
                    },                    
                    {
                      text: "Subscription Package",
                      to: `/addsub`,
                      rewrite: true,
                      icon: faBoxFull,
                    },
                  ],
                },
                {
                  text: "Settings",
                  to: "/settings",
                  rewrite: true,
                  icon: faCogs,
                },

                {
                  text: "Edit",
                  to: editAction,
                  icon: faEdit,
                  disabled: !editAction,
                },
              ]}
            />
          </Route>

          <Route
            path={[
              `/console/${business.id}/products/list/*`,
              `/console/${business.id}/products/list`,
            ]}
          >
            <Console.LinkItems
              items={[
                {
                  icon: faPlus,
                  text: "New",
                  items: [
                    {
                      text: "Product",
                      to: `/new`,
                      icon: faTshirt,
                      rewrite: true,
                    },
                    {
                      type: 'divider'
                    },
                    {
                      text: "Pricing Plan",
                      to: `/addplan`,
                      icon: faMoneyCheckEdit,
                      rewrite: true,
                    },
                    {
                      text: "Subscription Package",
                      to: `/addsub`,
                      rewrite: true,
                      disabled: false,
                      icon: faBoxFull,
                    },
                  ],
                },
                {
                  text: "Settings",
                  icon: faCogs,
                  disabled: true,
                },

                {
                  text: "Edit",
                  icon: faEdit,
                  disabled: true,
                },
              ]}
            />
          </Route>

          <Route path={`/console/${business.id}/products`}>
            <Console.LinkItems
              items={[
                {
                  icon: faPlus,
                  text: "New",
                  items: [
                    {
                      text: "Product",
                      to: `/dialog/new`,
                      icon: faTshirt,
                      rewrite: true,
                    },
                    {
                      text: "Member Plan",
                      to: `/dialog/addmember`,
                      icon: faUserCrown,
                      rewrite: true,
                    },                    
                    {
                      text: "Pricing Plan",
                      to: `/dialog/addplan`,
                      icon: faMoneyCheckEdit,
                      rewrite: true,
                    },
                    {
                      text: "Subscription Package",
                      to: `/dialog/addsub`,
                      rewrite: true,
                      icon: faBoxFull,
                    },
                  ],
                },
                {
                  text: "twod",
                },
              ]}
            />
          </Route>
        </Switch>
      }
      navigation={
        <div className="mt-3">
          <h6 className="pl-3 mt-3">Products &amp; Services</h6>
          <Toolbar.Item
            to={`/console/${business.id}/products`}
            exact
            icon={faHomeAlt}
            sidebar            
          >
            Overview
          </Toolbar.Item>          
          
          <h6 className="pl-3 mt-3">E-Commerce Setup</h6>
          <Toolbar.Item
            to={`/console/${business.id}/products/members`}
            icon={faUserCrown}
            sidebar
          >
            Member Plans
          </Toolbar.Item>

          <Toolbar.Item
            to={`/console/${business.id}/products/list`}
            icon={faTshirt}
            sidebar
          >
            Products
          </Toolbar.Item>
          <Toolbar.Item
            to={`/console/${business.id}/products/subscriptions`}
            icon={faBoxFull}
            sidebar
          >
            Packages
          </Toolbar.Item>
          <Toolbar.Item
            to={`/console/${business.id}/products/coupons`}
            icon={faGiftCard}
            sidebar
          >
            Coupons
          </Toolbar.Item>
        </div>
      }
    >
      <Switch>
      <Route path={`/console/${business.id}/products/members`}>
          <Members business={business} />
        </Route>

        <Route path={`/console/${business.id}/products/subscriptions`}>
          <Subscriptions business={business} />
        </Route>
        <Route path={`/console/${business.id}/products/list`}>
          <ListProducts business={business} />
        </Route>
        <Route path={`/console/${business.id}/products`}>
          <h1>overview of products and stuff</h1>
        </Route>
      </Switch>

      <Route
        path={[`${match.path}*/*`, `${match.path}*`, `${match.path}`]}
        children={({ match, ...rest }) => {
          return (
            <Switch>
              <Route path={`${match.path}/addplan`}>
                <Dialog
                  show={true}
                  validation={{
                    nickname: yup.string().required(),
                    interval_count: yup.number().required(),
                    usage_type: yup.string().required(),
                    productId: yup.string().required(),
                  }}
                  onSubmit={async (fields, helper) => {
                    helper.setSubmitting(true);
                    try {
                      const redirectId = fields.productId;
                      const db = firebase.firestore();
                      const path = `business/${business.id}/products/${fields.productId}/plans`;
                      let ref = db.collection(path);
                      const data = {
                        ...fields,
                      };
                      delete data.productId;
                      await ref.add(data);

                      helper.resetForm();
                      history.push(
                        `/console/${business.id}/products/list/show/${redirectId}`
                      );
                    } catch (e) {
                      console.log(e);
                      helper.setErrors({ serverError: e.message });
                    }

                    helper.setSubmitting(false);
                  }}
                  initialValues={{
                    currency: "CHF",
                    trial_period_days: 0,
                    amount_decimal: "0.00",
                    usage_type: "licensed",
                  }}
                  title="Add new pricing plan"
                  handleClose={() => history.go(-1)}
                >
                  <Dialog.FormGroup
                    name="productId"
                    label="Product"
                    options={
                      business && business.products
                        ? [
                            {
                              key: "",
                              label: "Choose",
                            },
                            ...business.products.map((product) => {
                              return {
                                key: product.id,
                                label: product.name,
                              };
                            }),
                          ]
                        : []
                    }
                  />
                  <Dialog.FormGroup name="nickname" label="Plan nickname" />
                  <Dialog.FormGroup name="currency" label="Currency" disabled />
                  <div className="container-fluid p-0 m-0">
                    <div className="row">
                      <div className="col-md-6">
                        <Dialog.FormGroup
                          name="usage_type"
                          label="Usage type"
                          options={[
                            { key: "", label: "Choose" },
                            { key: "licensed", label: "Abo" },
                            { key: "metered", label: "METERED" },
                          ]}
                        />
                      </div>
                      <div className="col">
                        <Dialog.FormGroup
                          name="interval_count"
                          label="Billing interval"
                          options={[
                            { key: "", label: "Choose" },
                            { key: "1", label: "Monthly" },
                            { key: "3", label: "Every 3 Month" },
                            { key: "6", label: "Every 6 Month" },
                            { key: "12", label: "Yearly" },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 m-0">
                    <div className="row">
                      <div className="col-md-6">
                        <Dialog.FormGroup
                          name="amount_decimal"
                          label="Price"
                          prepend="CHF"
                        />
                      </div>
                      <div className="col">
                        <Dialog.FormGroup
                          name="trial_period_days"
                          type="number"
                          label="Trial period"
                          append="days"
                        />
                      </div>
                    </div>
                  </div>

                  <Alert variant="warning">
                    Once a pricing plan is created, only its nickname and trial
                    period can be updated.
                  </Alert>
                </Dialog>
              </Route>
              <Route path={`${match.path}/addsub`}>
                <Subscriptions.Dialog business={business}/>
              </Route>
              <Route path={`${match.path}/addmember`}>
                <Members.Dialog business={business}/>
              </Route>              
              <Route path={`${match.path}/new`}>
                <NewProductDialog
                  show={newProductDialog}
                  businessId={business.id}
                  handleClose={() => {
                    setNewProductDialog(false);
                    setTimeout(() => {
                      history.goBack(1);
                      setNewProductDialog(true);
                    }, 300);
                  }}
                />
              </Route>
            </Switch>
          );
        }}
      />
    </Console>
  );
};
