import { types } from "../actions/app";

const initialState = {
  title: null
};
export const app = function createReducer(
  state = initialState,
  action = {}
) {  
  switch (action.type) {
    case types.SET_NAVIGATION_TITLE:
      return {
        ...state,
        title: action.title,
        microlink: action.homelink
      };
    
    default:
      return state;
  }
};
