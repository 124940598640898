import React, { useEffect, useContext } from "react";
import { Formik, useField, useFormikContext, FieldArray } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import RichEditor from "components/RichEditor";
import { convertToHTML } from "draft-convert";
import Spinner from "components/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { SketchPicker } from "react-color";
import ImageFormGroup from "./ImageFormGroup";
import {
  faTimes,
  faArrowLeft,
  faSave,
  faPlus,
  faPlusSquare,
  faMinus,
  faArrowUp,
  faArrowDown,
  faEllipsisVAlt,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";

export const DialogContext = React.createContext({
  loading: false,
});

function arrayHoc(index, WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <WrappedComponent
          name={`${this.props.name}.${index}`}
          {...this.props}
        />
      );
    }
  };
}

export const useDialogContext = () => useContext(DialogContext);

const FormGroup = ({
  label,
  placeholder,
  options,
  disabled,
  append,
  prepend,
  multi,
  as,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const dialogContext = useContext(DialogContext);
  const { setValue } = helpers;
  const { isSubmitting, values } = useFormikContext();
 
  function renderFormField(itmName, index) {
    switch (props.type) {
      case "color":
        return (
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="color"
              style={{ backgroundColor: field.value }}
            >
              {label}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <SketchPicker
                color={field.value}
                disableAlpha={false}
                onChangeComplete={(value) => {
                  setValue(value.hex);
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        );
      case "image":
        return <ImageFormGroup {...props} field={field} setValue={setValue} />;
      case "richtext":
        return (
          <>
            <h6>{label}:</h6>
            <div className="container-fluid m-0 p-0">
              {disabled || isSubmitting || dialogContext.loading ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: convertToHTML(field.value.getCurrentContent()),
                  }}
                ></span>
              ) : (
                <RichEditor
                  editorState={field.value}
                  onChange={(value) => setValue(value)}
                />
              )}
            </div>
          </>
        );
      case "checkbox":
        return (
          <>
            <Form.Check
              {...field}
              {...props}
              onChange={field.onChange}
              type="checkbox"
              disabled={dialogContext.loading || disabled || isSubmitting}
              onBlur={field.onBlur}
              isValid={meta.touched && !meta.error}
              label={label}
              isInvalid={meta.error}
            />
            {meta.error}
            <Form.Control.Feedback type="invalid">
              {meta.error}
            </Form.Control.Feedback>
          </>
        );
      case "switch":
        return (
          <Form.Check
            {...field}
            {...props}
            type="switch"
            onChange={(value) => setValue(!field.value)}
            disabled={dialogContext.loading || disabled || isSubmitting}
            checked={field.value}
            onBlur={field.onBlur}
            isValid={meta.touched && !meta.error}
            label={field.value ? props.enabledLabel : props.disabledLabel}
            isInvalid={meta.error}
          />
        );
      default:
        return (
          <>
            <Form.Label>{label}</Form.Label>
            <InputGroup>
              {prepend ? (
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    {prepend}
                  </InputGroup.Text>
                </InputGroup.Prepend>
              ) : null}
              <Form.Control
                {...field}
                {...props}
                as={as ? as : options ? "select" : "input"}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={dialogContext.loading || disabled || isSubmitting}
                isValid={meta.touched && !meta.error}
                isInvalid={meta.error}
                placeholder={placeholder ? placeholder : ""}
              >
                {options
                  ? options.map((item) => (
                      <option value={item.key}>{item.label}</option>
                    ))
                  : null}
              </Form.Control>
              {props.type === "range" ? (
                <InputGroup.Append>
                  <InputGroup.Text id="inputGroupAppend">
                    {field.value}
                    {props.unit ? props.unit : null}
                  </InputGroup.Text>
                </InputGroup.Append>
              ) : null}

              {append ? (
                <InputGroup.Append>
                  <InputGroup.Text id="inputGroupAppend">
                    {append}
                  </InputGroup.Text>
                </InputGroup.Append>
              ) : null}

              <Form.Control.Feedback type="invalid">
                {meta.error}
              </Form.Control.Feedback>
            </InputGroup>
          </>
        );
    }
  }

  return props.children ? (
    <FieldArray name={props.name}>
      {({ insert, remove, push, move }) => (
        <div className="border rounded p-1 mb-4">
          <div>
            <Form.Label>{label}</Form.Label>
          </div>
          <div className="container pl-md-2">
            {values[props.name]?.map((itm, index) => (
              <div className="row rounded my-2 bg-white shadow-sm">
                <div className="col-10">{props.children(index)}</div>
                <div className="col-2 pt-3 text-center">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="warning"
                      id={`props.tilde.${index}`}
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={()=>{
                        insert(index, '')
                      }}>
                        <Icon icon={faPlus} /> Add
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=>{
                        remove(index)
                      }}>
                        <Icon icon={faMinus} /> Remove
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        disabled={index === 0}
                        onClick={(e) => {
                          move(index, index-1);
                        }}
                      >
                        <Icon icon={faArrowUp} /> Move up
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={index + 1 === values[props.name]?.length}
                        onClick={(e) => {
                          move(index, index+2);
                        }}
                      >
                        <Icon icon={faArrowDown} /> Move down
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ))}
          </div>

          <button
            type="button"
            className="btn btn-outline-solblue btn-sm ml-2"
            onClick={() => push({})}
          >
            <Icon icon={faPlus}/>
          </button>
        </div>
      )}
    </FieldArray>
  ) : (
    <Form.Group>{renderFormField()}</Form.Group>
  );
};

const Dialog = ({
  children,
  show = true,
  size,
  handleClose,
  title,
  onSubmit,
  initialValues = {},
  loading = false,
  validation = {},
  schemaLocale = {},
  submitText = "Submit",
  closeText = "Close",
  business,
}) => {
  const { t } = useTranslation();
  yup.setLocale({
    ...schemaLocale,
    string: {
      min: t("form-validation-min"),
      required: t("form-validation-required"),
      email: t("form-validation-email"),
    },
  });
  return (
    <DialogContext.Provider value={{ loading, business }}>
      <Formik
        validationSchema={yup.object(validation)}
        onSubmit={(fields, helpers) => {
          helpers.setErrors({});
          onSubmit(fields, helpers);
        }}
        initialValues={initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isSubmitting,
          setErrors,
          isValid,
          setFieldValue,
          resetForm,
          errors,
        }) => (
          <Modal
            backdrop
            show={show}
            size={size || null}
            keyboard={!loading || !isSubmitting}
            onHide={() => {
              handleClose();
              resetForm();
            }}
            centered
          >
            <Form noValidate onSubmit={handleSubmit}>
              {/*<Modal.Header closeButton className="bg-solwhite">
                <Modal.Title className="h6">{title || "Dialog"}</Modal.Title>
              </Modal.Header>
              */}
              <div
                className="modal-header bg-solblue text-white"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  className="text-white m-0 p-0 pl-3 d-block d-md-none"
                  variant="transparent"
                  style={{ fontSize: "1.5rem", height: "100%", width: "30px" }}
                  disabled={loading || isSubmitting}
                  onClick={() => {
                    handleClose();
                    resetForm();
                  }}
                >
                  <Icon icon={faArrowLeft} />
                </Button>
                <h5 className="modal-title" id="exampleModalLabel">
                  {title || "Dialog"}
                </h5>
                <Button
                  variant="transparent"
                  type="submit"
                  style={{ fontSize: "1.5rem" }}
                  className="text-white m-0 p-0 pr-3 d-block d-md-none"
                  disabled={loading || isSubmitting}
                >
                  {loading || isSubmitting ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    <Icon icon={faSave} />
                  )}
                </Button>
                <button
                  type="button"
                  className="close text-white d-none d-md-block"
                  data-dismiss="modal"
                  aria-label="Close"
                  disabled={loading || isSubmitting}
                  onClick={() => {
                    handleClose();
                    resetForm();
                  }}
                >
                  <Icon icon={faTimes} />
                </button>
              </div>

              <Modal.Body className="bg-solgray">
                {errors.serverError ? (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    {errors.serverError}
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setErrors({ ...errors, serverError: "" })}
                    >
                      <span aria-hidden="true">
                        <Icon icon={faTimesCircle} />
                      </span>
                    </button>
                  </div>
                ) : null}
                {children}
              </Modal.Body>
              <Modal.Footer className="bg-solgray d-none d-md-flex">
                {!loading && !isSubmitting ? (
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      handleClose();
                      resetForm();
                    }}
                  >
                    <span>{closeText}</span>
                  </Button>
                ) : null}
                <Button
                  variant="primary"
                  type="submit"
                  className="ml-auto"
                  disabled={loading || isSubmitting}
                >
                  {loading || isSubmitting ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    <span>{submitText}</span>
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </DialogContext.Provider>
  );
};
Dialog.FormGroup = FormGroup;
Dialog.Tabs = Tabs;
Dialog.Tab = (props) => (
  <Tab {...props}>
    <div className="container">{props.children}</div>
  </Tab>
);

Dialog.FormRow = ({ children }) => (
  <div className="container-fluid p-0 m-0">
    <div className="row">{children}</div>
  </div>
);

Dialog.FormCol = ({ children, col = 6 }) => (
  <div className={`col-md-${col}`}>{children}</div>
);

export default Dialog;

/*
example

<Dialog
    title="add new product dude"
    loading={loading}
    show={showAddProductDialog}
    initialValues={{name: ""}}
    onSubmit={()=>{
    setLoading(!loading)
    }}
    handleClose={() => {setShowAddProductDialog(false); setLoading(false)}}
>
    <Dialog.FormGroup name="name" label={t('Product Name')}></Dialog.FormGroup>
    <Dialog.FormGroup type="text" name="description" label={t('Description')}></Dialog.FormGroup>
    <Dialog.FormGroup
    name="gender"
    label={t("label-gender")}
    options={[
    { value: "", label: t("gender-choose") },
    { value: "female", label: t("label-gender-female") },
    { value: "male", label: t("label-gender-male") }
    ]}
    />
</Dialog>

            */
