import React, { useState, useContext } from "react";
import classNames from "classnames";
import EditorContext from "editor/EditorContext";
import Component from "editor/Component";
import { ContentTypes } from "editor/ContentTypes";
import { uuid } from "helper";
import Column from "editor/content/Column";
import Dialog from "components/Dialog";
import Placeholder from "components/placeholder";
import Section from "editor/content/Section";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { addComponent } from "redux/actions/editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

export const createInitialValue = (component) => component;
export const title = "Section Component";
function createGridCss(count) {
  let css = "";
  for (let i = 0; i < count; i++) {
    css += "1fr ";
  }
  return css;
}
export const accept = [
  /*  ContentTypes.COLUMN,
  ContentTypes.HEADLINE,
  ContentTypes.VIDEO,
  ContentTypes.CARD,*/
];

const Columns = ({
  parsys,
  columns,
  id,
  count,
  type,
  gap = 5,
  containerType,
  bg = "none",
  marginTop = "no",
  ...props
}) => {
  const [disableHover, setDisableHover] = useState(false);
  const context = useContext(EditorContext);
  const dispatch = useDispatch();
  const config = useSelector((state) => state.editor.config);
  const document = useSelector((state) => state.editor.document);

  /*

  display: "grid",
              gridTemplateColumns: createGridCss(count),
              gridGap: gap + "px",
              gridAutoRows: "1fr"*/
  return context.editing ? (
    <Component
      type={type}
      id={id}
      {...props}
      disableHover={disableHover}
      key={id}
    >
      <div
        className={classNames({
          container: !containerType || containerType === "regular",
          "ngh-columns": true,
        })}
        key={`${id}-wrapper`}
        style={{
          backgroundColor: bg,
          display: "grid",
          gridTemplateColumns: createGridCss(count),
          gridGap: gap + "px",
          gridAutoRows: "1fr",
        }}
      >
        parsys && parsys.length > 0 ? (
        <>
          {parsys.map((item, index) => {
            return (
              <div
                className="ngh-columns__item col p-5"
                key={`${id}-section-${index}-col`}
              >
                <div className="ngh-columns__item__add ngh-columns__item__add--right">
                  <Button
                    className="rounded-circle"
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(
                        "add column to" +
                          id +
                          "on column " +
                          index +
                          " after id: " +
                          item.id
                      );

                      dispatch(
                        addComponent(
                          {
                            id: uuid(),
                            fullwidth: true,
                            type: ContentTypes.COLUMN,
                            parsys: [
                              {
                                type: ContentTypes.SECTION,
                                id: uuid(),
                                fixposition: true,
                                parsys: [
                                  {
                                    type: ContentTypes.HEADLINE,
                                    text: "hallo wlet",
                                    size: 1,
                                    id: uuid(),
                                  },
                                ],
                              },
                            ],
                          },
                          document.present.currentPage,
                          config.currentLanguage,
                          [
                            {
                              id,
                              type,
                            },
                          ],
                          index
                        )
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
                <Column
                  onHover={(value) => setDisableHover(value)}
                  fullheight={true}
                  accept={accept}
                  {...item}
                />
              </div>
            );
          })}
          {/*<ContentMapper
              onHover={(value) => setDisableHover(value)}
              content={parsys}
              fullheight={true}
              accept={accept}
              key={`${id}-section`}
            />*/}
        </>
        ) : (
        <Component
          type="type"
          id={id}
          editiable={false}
          draggable={false}
          selectable={false}
        >
          <Placeholder
            title="empty sectasdfasdfion"
            text="add or drag a new component into it"
          />
        </Component>
        ) }
      </div>
    </Component>
  ) : (
    <div
      className={classNames({
        container: !containerType || containerType === "regular",
        "ngh-columns": true,
      })}
      key={`${id}-wrapper`}
      style={{
        backgroundColor: bg,
        display: "grid",
        gridTemplateColumns: createGridCss(count),
        gridGap: gap + "px",
        gridAutoRows: "1fr",
      }}
    >
      {parsys.map((item, index) => {
        return <Column fullheight={true} {...item} />;
      })}
    </div>
  );
};
export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.COLUMNS,
  count: 2,
  gap: 5,
  containerType: "regular",
  parsys: [
    {
      id: uuid(),
      fullwidth: true,
      type: ContentTypes.COLUMN,
      parsys: [
        {
          type: ContentTypes.SECTION,
          id: uuid(),
          fixposition: true,
          parsys: [
            {
              type: ContentTypes.HEADLINE,
              text: "hallo wlet",
              size: 1,
              id: uuid(),
            },
          ],
        },
      ],
    },
    {
      id: uuid(),
      fullwidth: true,
      type: ContentTypes.COLUMN,
      parsys: [
        {
          type: ContentTypes.SECTION,
          fixposition: true,
          id: uuid(),
          parsys: [],
        },
      ],
    },
  ],
});

Columns.accept = accept;

Columns.createInitialValue = (component, add = false) =>
  add
    ? defaultStructure()
    : {
        ...component,
        gap: component.gap ? component.gap : 5,
      };
Columns.title = "Columns Component";

Columns.displayFormFields = (component) => (
  <>
    <Dialog.FormGroup
      name="containerType"
      label="Container Type"
      options={[
        { key: "", label: "Choose" },
        { key: "fluid", label: "Fluid" },
        { key: "regular", label: "Regular" },
      ]}
    />
    <Dialog.FormGroup name="bg" label="background" type="color" />
    <Dialog.FormGroup name="count" label="number of columns" type="number" />
    <Dialog.FormGroup
      name="gap"
      label="space between"
      type="range"
      min="0"
      max="50"
      step="5"
      unit="px"
    />
    <Dialog.FormGroup
      name="marginTop"
      label="marginTop"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "no", label: "no margin" },
        { key: "small", label: "small" },
        { key: "medium", label: "medium" },
        { key: "big", label: "big" },
      ]}
    />
  </>
);

export default Columns;
