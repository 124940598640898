import React, { useState } from "react";
import { useSelector } from "react-redux";
import defaultLayout from "./defaultLayout";

import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import Dialog from "components/Dialog";
import { useSelectedComponent } from "hooks/editor";
import ContentTypes from "editor/ContentTypes";
import { EditorState, convertFromHTML, ContentState } from "draft-js";

import { useDispatch } from "react-redux";
import { setCurrentPage } from "redux/actions/editor";

import ComponentDialog from "editor/ComponentDialog";
import { uuid } from "helper";
import useEditor from "hooks/editor";

const EditComponentDialog = ({ business }) => {
  const [selectedComponent] = useSelectedComponent();
  const editor = useSelector((state) => state.editor);

  return selectedComponent ? (
    <ComponentDialog
      component={selectedComponent}
      editor={editor}
      business={business}
      onSubmit={async () => {
        throw Error("shit happens");
      }}
    ></ComponentDialog>
  ) : null;
};

const EditPageDialog = ({ business }) => {
  const [selectedComponent] = useSelectedComponent();
  const [currentPage] = useEditor();
  const editor = useSelector((state) => state.editor);
  console.log("currentPage");
  console.log(currentPage);
  return currentPage ? (
    <ComponentDialog
      component={{
        ...currentPage.meta,
        type: ContentTypes.PAGE,
      }}
      editor={editor}
      business={business}
      onSubmit={async () => {
        throw Error("shit happens");
      }}
    ></ComponentDialog>
  ) : null;
};

const AddComponentDialog = ({ type, business }) => {
  const editor = useSelector((state) => state.editor);

  return (
    <ComponentDialog
      editor={editor}
      business={business}
      component={{ type }}
      add={true}
      onSubmit={async () => {
        throw Error("shit happens");
      }}
    ></ComponentDialog>
  );
};

const AddScheduleDialog = ({ business }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(true);
  const targetPath = `/business/${business.id}/courses`;
  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);
    try {
      let name = fields.title.replace(/ /g, "_");
      let layout = defaultLayout({ ...fields, name });
      let content = {
        ...layout,
        id: name,
        name,
        currentId: layout.versions[0].id,
      };
      console.log(content);
      dispatch(setCurrentPage(name, content));
      setShowDialog(false);
      helper.resetForm();
      window.setTimeout(
        () => history.push(`/console/${business.id}/courses/view/${name}`),
        400
      );
    } catch (e) {
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  let currentPage = {};

  return (
    <Dialog
      initialValues={{}}
      submitText="Create"
      title={`Add New Schedule`}
      show={showDialog}
      business={business}
      onSubmit={submitHandler}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
    >
      <Dialog.Tabs>
        <Dialog.Tab title="mtea info" eventKey="meta">
          <Dialog.FormGroup
            name="title"
            label="Course Title"
            placeholder={
              currentPage && currentPage.meta ? currentPage.meta.title : null
            }
          />
          <Dialog.FormRow>
            <Dialog.FormCol col="4">
              <Dialog.FormGroup
                name="number"
                label="Course Number"
                prepend={
                  currentPage && currentPage.meta
                    ? currentPage.meta.number
                    : null
                }
              />
            </Dialog.FormCol>
            <Dialog.FormCol col="4">
              <Dialog.FormGroup
                name="defaultSeats"
                type="number"
                label="Default Seats"
              />
            </Dialog.FormCol>
            <Dialog.FormCol col="4">
              <Dialog.FormGroup
                name="defaultNunberOfDays"
                type="number"
                label="Default Days"
              />
            </Dialog.FormCol>
          </Dialog.FormRow>
          <Dialog.FormRow>
            <Dialog.FormCol>
              <Dialog.FormGroup
                name="startTime"
                type="time"
                label="Default Start Time"
              />
            </Dialog.FormCol>
            <Dialog.FormCol>
              <Dialog.FormGroup
                name="endTime"
                type="time"
                label="Default End Time"
              />
            </Dialog.FormCol>
          </Dialog.FormRow>

          <Dialog.FormGroup
            name="defaultPrice"
            type="number"
            label="Default Price"
            placeholder={
              currentPage && currentPage.meta
                ? currentPage.meta.defaultPrice
                : null
            }
            prepend="CHF"
          />
        </Dialog.Tab>
        <Dialog.Tab title="Scheudle" eventKey="schedule">
          <h1>heuldesc</h1>
        </Dialog.Tab>
      </Dialog.Tabs>
    </Dialog>
  );
};

const AddPageDialog = ({ business }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(true);
  const targetPath = `/business/${business.id}/courses`;
  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);
    try {
      let name = fields.title.replace(/ /g, "_");
      let layout = defaultLayout({ ...fields, name });
      let content = {
        ...layout,
        id: name,
        name,
        currentId: layout.versions[0].id,
      };
      console.log(content);
      dispatch(setCurrentPage(name, content));
      setShowDialog(false);
      helper.resetForm();
      window.setTimeout(
        () => history.push(`/console/${business.id}/courses/view/${name}`),
        400
      );
    } catch (e) {
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog
      initialValues={{}}
      submitText="Create"
      title={`Add New Class or Workshop`}
      show={showDialog}
      business={business}
      onSubmit={submitHandler}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
    >
      <Dialog.FormGroup name="title" label="Course Title" />

      <Dialog.FormRow>
        <Dialog.FormCol col="4">
          <Dialog.FormGroup name="number" label="Course Number" />
        </Dialog.FormCol>
        <Dialog.FormCol col="4">
          <Dialog.FormGroup
            name="defaultSeats"
            type="number"
            label="Default Seats"
          />
        </Dialog.FormCol>
        <Dialog.FormCol col="4">
          <Dialog.FormGroup
            name="defaultNunberOfDays"
            type="number"
            label="Default Days"
          />
        </Dialog.FormCol>
      </Dialog.FormRow>
      <Dialog.FormRow>
        <Dialog.FormCol>
          <Dialog.FormGroup
            name="startTime"
            type="time"
            label="Default Start Time"
          />
        </Dialog.FormCol>
        <Dialog.FormCol>
          <Dialog.FormGroup
            name="endTime"
            type="time"
            label="Default End Time"
          />
        </Dialog.FormCol>
      </Dialog.FormRow>

      <Dialog.FormGroup
        name="defaultPrice"
        type="number"
        label="Default Price"
        prepend="CHF"
      />
    </Dialog>
  );
};

export default ({ business }) => {
  let match = useRouteMatch();
  return (
    <Route
      path={[
        `${match.path}*/*/*/*`,
        `${match.path}*/*`,
        `${match.path}*`,
        `${match.path}`,
      ]}
      children={({ match, ...rest }) => {
        return (
          <Switch>
            <Route path={`${match.path}/add-cmp-schedule`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.COURSE_SCHEDULE}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-hero`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.HERO}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-video`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.VIDEO}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-card`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.CARD}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-columns`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.COLUMNS}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-headline`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.HEADLINE}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-text`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.TEXT}
              />
            </Route>
            <Route path={`${match.path}/add-cmp-section`}>
              <AddComponentDialog
                business={business}
                type={ContentTypes.SECTION}
              />
            </Route>
            <Route path={`${match.path}/settings`}>
              <EditPageDialog business={business} type={ContentTypes.PAGE} />
            </Route>
            <Route path={`${match.path}/course`}>
              <AddPageDialog business={business} />
            </Route>
            <Route path={`${match.path}/schedule`}>
              <AddScheduleDialog business={business} />
            </Route>
            <Route path={`${match.path}/editcomponent`}>
              <EditComponentDialog business={business} />
            </Route>
          </Switch>
        );
      }}
    />
  );
};
