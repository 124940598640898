import { types } from "../actions/console";




export const consoleActions =  function createReducer(state = {editAction: null}, action = {}) {
  switch (action.type) {       
    case types.SET_EDIT_ACTION:
      return {
        ...state,
        editAction: action.editAction
      }
    
    default:
      return state;
  }
}

const initialState = {
  success: false,
  loading: false,
  business: [],
  msg: "data store working"
};
export const createBusiness =  function createReducer(state = initialState, action = {}) {
  switch (action.type) {       
    case types.CREATE.ADD_NEW_BUSINESS:
        return {
            ...state,
            loading: true
        }
    case types.CREATE.ADD_NEW_BUSINESS_SUCCESS:
      return {
        ...state,
        key: action.key,
        success: true,
        loading: false
      };
      case types.CREATE.ADD_NEW_BUSINESS_FAILURE:
        return {
          ...state,
          error: action.error,
          loading: false
        };      
    default:
      return state;
  }
}

const initialStateMyBusiness = {
  business: [],
  subscriptions: [],
  userdata: true
};
export const userData =  function createReducer(state = initialStateMyBusiness, action = {}) {
  switch (action.type) {   
    case types.SYNC_PAGES:
      return {
        ...state,
        business: state.business.map((item)=>{
          if(item.id !== action.business) {
            return item;
          }        
          let data = {
            ...item,
            pages: action.pages
          }
          return data;
        })
      }
    case types.SYNC_PRODUCTS:
      return {
        ...state,
        products: action.products
      }
      case types.SYNC_PACKAGES:
        return {
          ...state,
          business: state.business.map((item)=>{
            if(item.id !== action.business) {
              return item;
            }        
            let data = {
              ...item,
              packages: action.packages
            }
            return data;
          })
        }
  
  
    case types.UPDATE.UPDATE_BUSINESSES:
      return {
        ...state,
        business: action.business
      }    
      case types.UPDATE.UPDATE_SUBSCRIPTIONS:
        return {
          ...state,
          subscriptions: action.subscriptions
        }          
    default:
      return state;
  }
}
