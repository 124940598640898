import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Toolbar from "./toolbar";
import Container from "./Container";
import Navigation from "./Navigation";
import Sidebar from "./Sidebar";
import Panel from "./Panel";

const isTouch =
  "ontouchstart" in window ||
  navigator.MaxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

const Console = ({
  toolbarItems = {},
  toolbar,
  children,
  navigation,
  menuItems,
  loading=false,
  actionItems,
  ...props
}) => {
  const [navigationOpen, setNavigationOpen] = useState(!isTouch);

  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <main className="ngh-console">
        <Toolbar
          loading={loading}
          onToggle={() => setNavigationOpen(!navigationOpen)}
          menu={toolbarItems.menu}
          menuItems={menuItems}
          toggled={navigationOpen}
          actions={toolbarItems.actions}
          actionItems={actionItems}
        />
        <Container>
          <Navigation open={navigationOpen}>{navigation}</Navigation>
          <Panel>{children}</Panel>
          <Sidebar />
        </Container>
      </main>
    </ToastProvider>
  );
};

Console.Toolbar = Toolbar;

const LinkItems = ({ items, ...props }) => {
  let match = useRouteMatch();
  return <Toolbar.Items items={items} {...props} match={match} />;
};

Console.LinkItems = LinkItems;

export default Console;
