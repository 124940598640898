import React from "react";

import { Editor, EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { faBold, faListUl, faH1, faItalic, faUnderline } from "@fortawesome/pro-light-svg-icons";

/**
 *
 * copied 1:1 from here:
 * https://github.com/facebook/draft-js/tree/master/examples/draft-0-10-0/rich
 */
export default class RichEditorExample extends React.Component {
  constructor(props) {
    super(props);    
    this.focus = () => this.refs.editor.focus();
    this.onChange = editorState => {
      this.props.onChange(editorState);
    };

    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.props.editorState,
        4 /* maxDepth */
      );
      if (newEditorState !== this.props.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
    );
  }

  render() {
    const { editorState } = this.props;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = "RichEditor-editor";
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }

    return (
      <div className="RichEditor-root">
        <div
          className="btn-toolbar"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <InlineStyleControls            
            className="mr-3"
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />

          <BlockStyleControls            
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />          
        </div>

        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            onChange={this.onChange}
            placeholder="Tell a story..."
            ref="editor"
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }

    return (
      <button
        type="button"
        className={classNames({
          btn: true,
          "btn-secondary": true,
          active: this.props.active
        })}
        onMouseDown={this.onToggle}
      >
        {this.props.icon ? (
          <FontAwesomeIcon icon={this.props.icon} />
        ) : (
          this.props.label
        )}
      </button>
      /*<span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>*/
    );
  }
}

const BLOCK_TYPES = [
  { label: "H1", style: "header-one", icon: faH1 },
  { label: "UL", style: "unordered-list-item", icon: faListUl }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div
      className="btn-group btn-group-sm ml-2"
      role="group"
      aria-label="Basic example"
    >
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: "Bold", style: "BOLD", icon: faBold },
  { label: "Italic", style: "ITALIC", icon: faItalic },
  { label: "Underline", style: "UNDERLINE", icon: faUnderline }
];

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div
      className="btn-group  btn-group-sm"
      role="group"
      aria-label="Basic example"
    >
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};
