export const types = {
  LOGIN: {
    REQUEST: "LOGIN.REQUEST",
    REQUEST_EMAIL_PASSWORD: "LOGIN.REQUEST.EMAIL_PASSWORD",
    REGISTER_EMAIL_PASSWORD: "LOGIN.REGISTER.EMAIL_PASSWORD",
    SUCCESS: "LOGIN.SUCCESS",
    FAILURE: "LOGIN.FAILURE",
    SEND_EMAIL_VERIFICATION: "LOGIN.SEND_EMAIL_VERIFICATION",
    SEND_EMAIL_VERIFICATION_SUCCESS: "LOGIN.SEND_EMAIL_VERIFICATION_SUCCESS",
    SEND_EMAIL_VERIFICATION_FAILURE: "LOGIN.SEND_EMAIL_VERIFICATION_FAILURE",
    REQUEST_OFFICE: "LOGIN.OFFICE",
    REQUEST_GOOGLE: "LOGIN.GOOGLE",
    SEND_PASSWORD_RESET_EMAIL: "LOGIN.SEND_PASSWORD_RESET_EMAIL",
    SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      "LOGIN.SEND_PASSWORD_RESET_EMAIL_SUCCESS",
    SEND_PASSWORD_RESET_EMAIL_FAILURE:
      "LOGIN.SEND_PASSWORD_RESET_EMAIL_FAILURE",
  },
  ACCOUNT: {
    UPDATE_PROFILE: "ACCOUT.UPDATEPROFIILE",
    UPDATE_PASSWORD: "ACCOUT.UPDATEPASSWORD",
    UPDATE_PASSWORD_SUCESS: "ACCOUNT.UPDATE_PASSWORD_SCCESS",
    UPDATE_PASSWORD_FAILURE: "ACCOUNT.UPDATE_PASSWORD_FAILURE",
    UPDATE_PASSWORD_CLEAR: "ACCOUNT.UPDATE_PASSWORD_CLEAR",
    UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
    START_SYNC_ACCOUNT: "START_SYNC_ACCOUNT",
    STOP_SYNC_ACCOUNT: "STOP_SYNC_ACCOUNT",
  },
  LOGOUT: {
    REQUEST: "LOGOUT.REQUEST",
    SUCCESS: "LOGOUT.SUCCESS",
    FAILURE: "LOGOUT.FAILURE",
  },
  INIT: {
    START: "INIT.START",
    FINISHED: "INIT.FINIHSED",
  },
  MESSAGING: {
    SET_TOKEN: "MESSAGING.SET_TOKEN",
    SYNC_TOKEM : "MESSAGING.DYNC_TOKEN"
  },
};

export const setMessagingToken = (token) => ({
  type: types.MESSAGING.SET_TOKEN,
  token,
});

export const syncMessagingToken = (token) => ({
  type: types.MESSAGING.SYNC_TOKEM,
  token,
});

export const startSyncAccount = () => ({
  type: types.ACCOUNT.START_SYNC_ACCOUNT,
});

export const stopSyncAccount = () => ({
  type: types.ACCOUNT.STOP_SYNC_ACCOUNT,
});

export const updateProfile = (profile) => ({
  type: types.ACCOUNT.UPDATE_PROFILE,
  profile,
});

export const updateAccount = (account) => ({
  type: types.ACCOUNT.UPDATE_ACCOUNT,
  account,
});
export const sendPasswordResetEmail = (email) => ({
  type: types.LOGIN.SEND_PASSWORD_RESET_EMAIL,
  email,
});
export const sendPasswordResetEmailSuccess = () => ({
  type: types.LOGIN.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
});
export const sendPasswordResetEmailFailure = (error) => ({
  type: types.LOGIN.SEND_PASSWORD_RESET_EMAIL_FAILURE,
  error,
});
export const emailVerificationSendSuccess = () => ({
  type: types.LOGIN.SEND_EMAIL_VERIFICATION_SUCCESS,
});
export const emailVerificationFailure = (error) => ({
  type: types.LOGIN.SEND_EMAIL_VERIFICATION_FAILURE,
  error,
});
export const updatePassword = (password) => ({
  type: types.ACCOUNT.UPDATE_PASSWORD,
  password,
});
export const updatePasswordSuccess = () => ({
  type: types.ACCOUNT.UPDATE_PASSWORD_SUCESS,
});
export const updatePasswordFailure = (error) => ({
  type: types.ACCOUNT.UPDATE_PASSWORD_FAILURE,
  error,
});
export const updatePasswordClear = () => ({
  type: types.ACCOUNT.UPDATE_PASSWORD_CLEAR,
});
export const requestGoogleLogin = () => ({
  type: types.LOGIN.REQUEST_GOOGLE,
});

export const requestOfficeLogin = () => ({
  type: types.LOGIN.REQUEST_OFFICE,
});

export const sendEmailVerification = () => ({
  type: types.LOGIN.SEND_EMAIL_VERIFICATION,
});

export const startInit = () => ({
  type: types.INIT.START,
});

export const stopInit = () => ({
  type: types.INIT.FINISHED,
});

export const login = () => ({
  type: types.LOGIN.REQUEST,
});

export const loginUsernamePassword = (email, password) => ({
  type: types.LOGIN.REQUEST_EMAIL_PASSWORD,
  email,
  password,
});

export const registerUsernamePassword = (email, password) => ({
  type: types.LOGIN.REGISTER_EMAIL_PASSWORD,
  email,
  password,
});

export const loginSuccess = (user) => ({
  type: types.LOGIN.SUCCESS,
  user,
});

export const loginFailure = (error) => ({
  type: types.LOGIN.FAILURE,
  error,
});

export const logout = () => ({
  type: types.LOGOUT.REQUEST,
});

export const logoutSuccess = () => ({
  type: types.LOGOUT.SUCCESS,
});

export const logoutFailure = (error) => ({
  type: types.LOGOUT.FAILURE,
  error,
});
