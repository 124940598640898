import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useRouteMatch, Switch, Route } from "react-router-dom";
import firebase from "firebase";

import ReactJson from "react-json-view";
import AccountPicture from "../../assets/undraw_address_udes.svg";
import ProductsPicture from "../../assets/undraw_empty_cart_co35.svg";
import CompanyProfile from "../../assets/undraw_account_490v.svg";
import classnames from "classnames";
import Dashboard from "./console/dashboard";
import Contact from "./console/contact";
import AccountVerified from "./payment/accountVerified";
import Products from "./products";
import Services from "./services";
import Profile from "./profile";
import Courses from "./courses";


import { syncProducts} from "../../redux/actions/console";
import Spinner from "react-bootstrap/Spinner";

export default () => {
  let match = useRouteMatch();
  const [business, setBusiness] = useState();
  let userData = useSelector((state) => state.userData);
  let dispatch = useDispatch();
  useEffect(() => {
    setBusiness(userData.business.find((item) => match.params.id === item.id));  
  }, [match, userData]);

  return (
    <Switch>
      <Route path={`${match.url}`} exact>
        <Dashboard business={business} />
      </Route>
      <Route path="/console/:id/profile">
      {business ? <Profile business={business}/> : <Spinner animation="grow" />}
      </Route>

      <Route path="/console/:id/products">
      {business ? <Products business={business}/> : <Spinner animation="grow" />}
      </Route>
      <Route path={`/console/:id/contact`}>
        {business ? <Contact business={business} /> : null}
      </Route>
      <Route path={`/console/:id/services`}>
        {business ? <Services business={business} /> : null}
      </Route>      
      <Route path={`/console/:id/courses`}>
        {business ? <Courses business={business} /> : null}
      </Route>            
      <Route path={`/console/:id/verified`}>
        <AccountVerified business={business} />
      </Route>
    </Switch>
  );
};
