import React, { useState, useContext } from "react";
import EditorContext from "editor/EditorContext";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";

import Placeholder from "components/placeholder";
import Dialog from "components/Dialog";
import Component from "editor/Component";

const Video = ({ link, id, type, ...props }) => {
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      {link ? (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={link}
            title={id}
            allowfullscreen
          ></iframe>
        </div>
      ) : (
        <Placeholder
          title="empty headline"
          text="edit component and past video link"
        />
      )}
    </Component>
  ) : link ? (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        className="embed-responsive-item"
        src={link}
        title={id}
        allowfullscreen
      ></iframe>
    </div>
  ) : null;
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.VIDEO,
});

Video.createInitialValue = (component, add = false) =>
  add ? defaultStructure() : component;
Video.title = "Video Component";

Video.displayFormFields = () => (
  <>
    <Dialog.FormGroup name="link" label="Video Link" />
  </>
);

Video.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.TEXT_IMAGE,
  ContentTypes.TEXT,
];

export default Video;
