import React from 'react';
import Console from "components/console";

export default ()=>{

    return <Console>
        <div className="container">

            <h1>hallo welt</h1>
        </div>
    </Console>
}