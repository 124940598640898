import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileIconToggle from "./ProfileIconToggle";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUserCircle, faFire,faBoxFull, faKey, faHome, 
  faConciergeBell,
  faAddressCard, faGlobeEurope,
faUsersClass } from "@fortawesome/pro-light-svg-icons";

import { useRouteMatch } from "react-router-dom";
import classNames from "classnames";


import AppBar from "ngh/components/AppBar";



export default ({ scroll }) => {
  let match = useRouteMatch();
  const [business, setBusiness] = useState();
  let userData = useSelector(state => state.userData);

  useEffect(() => {
    setBusiness(userData.business.find(item => match.params.id === item.id));
  }, [match, userData]);
  return (
    business ? 
    <AppBar title={business.companyName} 
    titleHome={`/console/${business.id}`}
    userData={userData}
    business={business}
    navItems={[
      {
        text: "Dashboard",
        icon: faHome,
        to: `/console/${business.id}`,
        exact: true
      },
      
      {
        text: "Sites",
        icon: faGlobeEurope,
        to: `/console/${business.id}/sites`
      },
      {
        text: "Public Profile",
        icon: faAddressCard,
        to: `/console/${business.id}/profile`
      },      
      {
        text: "Account",
        icon: faKey,
        to: `/console/${business.id}/contact`
      },
      {
        text: "Products",
        icon: faBoxFull,
        to: `/console/${business.id}/products`
      },
      {
        text: "Services",
        icon: faConciergeBell,
        to: `/console/${business.id}/services`
      },
      {
        text: "Classes & Workshops",
        icon: faUsersClass,
        to: `/console/${business.id}/courses`
      } 
    ]}/> : null
  );
};


/*

<div
      className={classNames({
        topnavbar: true,
        business: true,
        "pr-3": true,
        "bg-solwhite": true,
        scroll: !scroll
      })}
    >
      <div className="system-logo">
        <Link to="/">
          <FontAwesomeIcon icon={faFire} className="mr-2" />
        </Link>
      </div>
      <div className="mr-auto">
        {business ? (
          <Link to={`${match.url}`} className="text-dark" style={{textDecoration: 'none'}}>{business.companyName}</Link>
        ) : null}
      </div>
      <ProfileIconToggle />
    </div>*/