import React from "react";

import DetailHeader from "components/DetailHeader";

export default ({ business }) => {
  return (
    <div className="w-100 pl-3 pr-3">
      <DetailHeader
        header="Detail"
        items={[
          {
            to: `/console/${business.id}/products/subscriptions`,
            text: "Packages",
          },
          {
              text: "tasdf"
          }
        ]}
      />
    </div>
  );
};
