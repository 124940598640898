import React, { useState, useRef } from "react";

import classNames from "classnames";
import { useHistory,useRouteMatch } from "react-router-dom";

import "./ProfileToggle.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Dropdown from "react-bootstrap/Dropdown";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="ngh-AppBar--BrandIcon ngh-AppBar--NavItem"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default ({icon, navigation}) => {
  const history = useHistory();
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic-gh-loginprofile">
        <FontAwesomeIcon icon={icon} className="icon" />
      </Dropdown.Toggle>
  {navigation ? 
      <Dropdown.Menu className="mr-5">
        {navigation.map(child=><Dropdown.Item onClick={()=>history.push(child.to)} >
          
            {child.icon ? <FontAwesomeIcon icon={child.icon} className="icon mr-3" /> : null}
            {child.text}
        </Dropdown.Item>)}        
      </Dropdown.Menu>
      : null}
    </Dropdown>
  );
};

/*<div className="ngh-ProfileToggle">
      <div
        className="ngh-ProfileToggle__toggler"
        onClick={() => toggleMenu(!open)}
      >
        <FontAwesomeIcon icon={faUserAlt} className="icon" />
      </div>
      <div
        ref={menu}
        className={classNames({
          "ngh-ProfileToggle__menu": true,
          "ngh-ProfileToggle__menu--open": open,
          "ngh-ProfileToggle__menu--closed": !open,
        })}
        tabIndex="0"
        onBlur={() => {
          setToggleOpen(true);
          setOpen(false);
        }}
      >
        {open ? "open " : "closed"}
      </div>
    </div>*/
