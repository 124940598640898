import { types } from '../actions/courses'

const initialState = {
  courses: []
}

export default function coursesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SYNC_COURSES:
        console.log("cleanCourses");
        console.log(state)
        console.log(action.business)
        const cleanCourses = state.courses.filter((item)=>item.parent !== action.business) || [];
        
        
        console.log(cleanCourses);
      return {
        ...state,
        courses: [...cleanCourses, ...action.courses],
      }
    default:
      return state
  }
}