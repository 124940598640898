import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle, faLaptopCode, faUserGraduate } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import Moment from "react-moment";
import "moment-timezone";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { useQuery } from "react-query";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";
import { useSelector, useDispatch } from "react-redux";
import Status from "components/Status";
import Login from "components/account/login";
import Spinner from "react-bootstrap/Spinner";

import { sendEmailVerification, logout } from "redux/actions/login";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default ({ onSelect, selected }) => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.login.user);
  const userData = useSelector((state) => {
    return {
      ...state.userData,
      subscriptions: state.userData.subscriptions
        ? state.userData.subscriptions.map((sub) => {
            return {
              ...sub,
              product: state.userData.products
                ? state.userData.products.find(
                    (itm) => itm.id === sub.product.id
                  )
                : sub.product.id,
            };
          })
        : [],
    };
  });

  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  console.log(user);
  console.log(userData);

  const stripe = useStripe();
  const elements = useElements();

  const createPortalLink = async () => {
    setLoading(true);
    const updateRef = firebase
      .app()
      .functions("europe-west6")
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");

    const { data } = await updateRef({ returnUrl: window.location.href });
    window.location.assign(data.url);
  };

  const getSubscriptions = async (key, id) => {
    if (!user) {
      return [];
    }
    const querySnapshot = await firebase
      .firestore()
      .collection("users")

      .get();
    const result = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        return { ...doc.data(), id: doc.id };
      })
    );

    return result;
  };

  const { status, data, error, isFetching } = useQuery(
    ["userslist", user.uid],
    getSubscriptions
  );

  return (
    <div className="w-100">
      {user ? (
        <div className="container">
          {error ? <Alert variant="danger">{error}</Alert> : null}

          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("Admin")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Users")}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col">
              <div className="card border-0">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Display Name</th>
                      <th className="w-50">email</th>
                      <th>Type</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ? data.map((item, index) => (
                          <tr
                            className={classNames({
                              "bg-primary text-white": selected === item.id,
                            })}
                            style={{ cursor: "pointer", userSelect: "none" }}
                            action
                            key={item.id}
                            onClick={() =>
                              onSelect(selected === item.id ? null : item)
                            }
                          >
                            <td>{item.id}</td>
                            <td>{item.displayName}</td>
                            <td>{item.email}</td>
                            <td className="text-center">
                            <FontAwesomeIcon
                                icon={
                                  item.developer 
                                    ? faLaptopCode
                                    : faUserGraduate
                                }
                              />

                            </td>
                            <td
                              className={classNames({
                                "text-success": "active" === item.status,
                                "text-danger": "canceled" === item.status,
                                "text-warning": item.cancel_at_period_end,
                                "text-center": true,
                              })}
                            >
                              <FontAwesomeIcon
                                icon={
                                  "active" === item.status
                                    ? faCheckCircle
                                    : faCircle
                                }
                              />
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h1>{t("Subscriptions")}</h1>
        </div>
      )}
    </div>
  );
};
