import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { useMutation, useQueryClient } from "react-query";
import Dialog from "components/Dialog";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import products from "components/business/products";

const dialogSchema = {};

export default ({ data }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [images, setImages] = useState([]);
  const [firewalls, setFirewalls] = useState([]);
  const [volumes, setValumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getStuff() {
      const func = firebase.functions().httpsCallable("dingo-listAllSizes");
      const result = await func();

      const data = result?.data?.sizes?.map((itm) => ({
        key: itm.slug,
        label:
          itm.description +
          " / $" +
          itm.price_monthly +
          "/ $" +
          itm.price_monthly * 1.5,
      }));

      setSizes(data);
    }

    async function getImages() {
      const imagesfunc = firebase
        .functions()
        .httpsCallable("dingo-listAllSnapshots");
      const result = await imagesfunc();

      const data = result?.data?.snapshots?.map((itm) => ({
        key: itm.id,
        label: itm.name,
      }));

      setImages(data);
    }

    async function getVolumes() {
      const volumesfunc = firebase
        .functions()
        .httpsCallable("dingo-listAllVolumes");
      const result = await volumesfunc();
      //      console.log("valumes");
      //    console.log(result);
      /*const data= result?.data?.snapshots?.map(itm=>({
        key: itm.id,
        label: itm.name
      }));
      
      setImages(data);*/
    }

    async function getFirewalls() {
      const func = firebase.functions().httpsCallable("dingo-listAllFirewalls");
      const result = await func();
      console.log("firewalls");
      console.log(result);
      const data = result?.data?.firewalls?.map((itm) => ({
        key: itm.id,
        label: itm.name,
      }));

      setFirewalls(data);
    }

    const getProducts = async () => {
      const querySnapshot = await firebase
        .firestore()
        .collection("products")
        .get();
      const result = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          return {
            label: doc.data().name + " / " + doc.data().description,
            key: "/products/" + doc.id,
          };
        })
      );

      setProducts(result);
      //return result;
    };
    Promise.all([
      getImages(),
      getStuff(),
      getVolumes(),
      getFirewalls(),
      getProducts(),
    ]).then(() => setLoading(false));
  }, []);

  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);

    try {
      const db = firebase.firestore();
      const dt = {
        ...fields,
      };

      if (data?.id) {
        await db.collection("packages").doc(data.id).set(dt);
        //const newdoc = await ref.add(data);
        setShowDialog(false);
        helper.resetForm();
        queryClient.invalidateQueries("packages");
        history.push("/admin/packages");
      } else {
        const ref = await db.collection("packages");
        const newdoc = await ref.add(dt);
        setShowDialog(false);
        helper.resetForm();
        queryClient.invalidateQueries("packages");
        //history.push("/admin/settings");
        window.setTimeout(
          () => history.push(`/admin/packages/${newdoc.id}`),
          400
        );
      }
    } catch (e) {
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog
      validation={dialogSchema}
      submitText={`${data ? "Update" : "Create"}`}
      size="lg"
      title={`${data ? "Update Package" : "New Package"}`}
      loading={loading}
      show={showDialog}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
      onSubmit={submitHandler}
      initialValues={{
        ...data,
      }}
    >
      <Dialog.FormRow>
        <Dialog.FormCol>
          <Dialog.FormGroup name="name" label="Name (internal only)" />
        </Dialog.FormCol>
        <Dialog.FormCol>
          <Dialog.FormGroup name="title" label="Public Package Title" />
        </Dialog.FormCol>
      </Dialog.FormRow>

      <Dialog.FormRow>
        <Dialog.FormCol>
          <Dialog.FormGroup name="product" options={products} label="Product" />
        </Dialog.FormCol>
        <Dialog.FormCol>
          <Dialog.FormGroup options={sizes} name="size" label="sizes " />
        </Dialog.FormCol>
      </Dialog.FormRow>

      <Dialog.FormRow>
        <Dialog.FormCol>
          <Dialog.FormGroup options={images} name="image" label="Base Image " />
        </Dialog.FormCol>
        <Dialog.FormCol>
          <Dialog.FormGroup
            options={firewalls}
            name="firewall"
            label="Firewall "
          />
        </Dialog.FormCol>
      </Dialog.FormRow>

      <Dialog.FormGroup
        type="number"
        name="volumeSize"
        label="Volume Size"
        append="GB"
      />

      <Dialog.FormRow>
        <Dialog.FormCol>
          <Dialog.FormGroup name="pricesCH" label="Prices CH">
            {(index) => (
              <>
                <Dialog.FormGroup
                  name={`pricesCH.${index}.desc`}
                  label="Description"
                />
                <Dialog.FormGroup
                  name={`pricesCH.${index}.desc_de`}
                  label="Description (de)"
                />

                <Dialog.FormGroup
                  name={`pricesCH.${index}.price`}
                  label="Price"
                />
                <Dialog.FormGroup
                  name={`pricesCH.${index}.currency`}
                  label="Currency"
                />
                <Dialog.FormGroup
                  name={`pricesCH.${index}.tax`}
                  label="Tax ID"
                />
              </>
            )}
          </Dialog.FormGroup>
        </Dialog.FormCol>
        <Dialog.FormCol>
          <Dialog.FormGroup name="pricesEu" label="Prices EU">
            {(index) => (
              <>
                <Dialog.FormGroup
                  name={`pricesEu.${index}.desc`}
                  label="Description"
                />
                <Dialog.FormGroup
                  name={`pricesEu.${index}.desc_de`}
                  label="Description (de)"
                />
                <Dialog.FormGroup
                  name={`pricesEu.${index}.price`}
                  label="Price"
                />
                <Dialog.FormGroup
                  name={`pricesEu.${index}.currency`}
                  label="Currency"
                />
                <Dialog.FormGroup
                  name={`pricesEu.${index}.tax`}
                  label="Tax ID"
                />
              </>
            )}
          </Dialog.FormGroup>
        </Dialog.FormCol>
      </Dialog.FormRow>

      <Dialog.FormGroup name="pricesWorld" label="Prices Rest of world">
        {(index) => (
          <>
            <Dialog.FormGroup
              name={`pricesWorld.${index}.desc`}
              label="Description"
            />
            <Dialog.FormGroup
              name={`pricesWorld.${index}.desc_de`}
              label="Description (de)"
            />

            <Dialog.FormGroup
              name={`pricesWorld.${index}.price`}
              label="Price"
            />
            <Dialog.FormGroup
              name={`pricesWorld.${index}.currency`}
              label="Currency"
            />
            <Dialog.FormGroup
              name={`pricesWorld.${index}.tax`}
              label="Tax ID"
            />
          </>
        )}
      </Dialog.FormGroup>
      <Dialog.FormGroup
        type="textarea"
        as="textarea"
        rows={3}
        name="notes"
        label="Internal Notes"
      />
      <Dialog.FormGroup
        name="active"
        type="checkbox"
        label="Active: This Packge can be booked"
      />
    </Dialog>
  );
};
