import React from "react";


export default (props) => {

    return <div className="jumbotron-fluid jumbotron bg-solblue hero-detail-page mt-5" >
    <div className="container mt-3">
<h1 className="text-white" style={{fontWeight: 300}}>{props.title}</h1>
    </div>
</div>

}