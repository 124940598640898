import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileIconToggle from "./TopBar/ProfileIconToggle";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import {
  faCrosshairs,
  faFire,
  faUserCircle,
  faSignInAlt,
  faSignOutAlt,
  faCrown,
  faKey,
  faCity,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  faLaughWink,
  faSearch,
  faHome,
  faJournalWhills,
  faArrowLeft,
  faRadiation,
  faPegasus,
  faUsersCog,
  faStarShooting,
} from "@fortawesome/pro-light-svg-icons";

import useScroll from "../hooks/scroll";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

import { Switch, Route } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../redux/actions/login";
import classNames from "classnames";

import BusinessBar from "./TopBar/BusinessBar";

import LoginPanel from "./loginpanel";
import AppBar from "ngh/components/AppBar";
import ProfileToggle from "ngh/components/ProfileToggle";

const LandingPage = ({ variant }) => {
  const { t } = useTranslation();
  const scroll = useScroll(30, "landingpage-shit");
  const [toggleOpen, setToggleOpen] = useState();
  const user = useSelector((state) => state.login.user);
  const loading = useSelector((state) => state.login.loading);

  return (
    <AppBar
      title={
        <>
        LowCode CMS Console 
          <div style={{ maxHeight: "25px" }}>
            {/*<SearchBox />*/}
          </div>
          {/*<div className="input-group input-group-sm mt-2 mr-sm-0">    
    <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Search" />
    <div className="input-group-append">
      <div className="input-group-text"><FontAwesomeIcon icon={faSearch} /> </div>
  </div>
  </div>*/}
        </>
      }
      userData={user}
      right={<ProfileIconToggle />}
    />
    /*<Navbar
      bg={scroll ? "info" : "solwhite"}
      expand="lg"
      variant="success"
      fixed="top"
      onToggle={isOpen => setToggleOpen(isOpen)}
      className={classNames({
        topnavbar: true,
        open: toggleOpen,
        closed: !toggleOpen,
        scroll: true
      })}
    >
      <Navbar.Brand as={Link} to="/" className="brand-typo">
        <FontAwesomeIcon icon={faSparkles} className="mr-2 text-dark" />
        SOLUNEO
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {user ? (
            null
          ) : (
            <>
              <Nav.Link href="#home">{t("How does it work?")}</Nav.Link>
              <Nav.Link href="#link">{t("For Small Business Owner")}</Nav.Link>
              <Nav.Link href="#link">{t("Help")}</Nav.Link>
              <Nav.Link href="#link">{t("About us")}</Nav.Link>
            </>
          )}         

          <ProfileIconToggle/>
        </Nav>
      </Navbar.Collapse>
          </Navbar>*/
  );
};


const AdminPage = ({ variant }) => {
  const { t } = useTranslation();
  const scroll = useScroll(30, "landingpage-shit");
  const [toggleOpen, setToggleOpen] = useState();
  const user = useSelector((state) => state.login.user);
  const loading = useSelector((state) => state.login.loading);

  return (
    <AppBar      
    className="border-top border-warning"
      title={
        <>
        <span className="font-weight-bolder">Soluneo Admin Center <FontAwesomeIcon icon={faRadiation} className="ml-3 text-warning" /></span>
          <div style={{ maxHeight: "25px" }}>
            {/*<SearchBox />*/}
          </div>
          {/*<div className="input-group input-group-sm mt-2 mr-sm-0">    
    <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Search" />
    <div className="input-group-append">
      <div className="input-group-text"><FontAwesomeIcon icon={faSearch} /> </div>
  </div>
  </div>*/}
        </>
      }
      userData={user}
      right={<ProfileIconToggle />}

      navItems={[
        {
          text: "Users",
          icon: faUsersCog,
          to: "/admin/user",
          exact: false,
        },
      ]}
    />
    /*<Navbar
      bg={scroll ? "info" : "solwhite"}
      expand="lg"
      variant="success"
      fixed="top"
      onToggle={isOpen => setToggleOpen(isOpen)}
      className={classNames({
        topnavbar: true,
        open: toggleOpen,
        closed: !toggleOpen,
        scroll: true
      })}
    >
      <Navbar.Brand as={Link} to="/" className="brand-typo">
        <FontAwesomeIcon icon={faSparkles} className="mr-2 text-dark" />
        SOLUNEO
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {user ? (
            null
          ) : (
            <>
              <Nav.Link href="#home">{t("How does it work?")}</Nav.Link>
              <Nav.Link href="#link">{t("For Small Business Owner")}</Nav.Link>
              <Nav.Link href="#link">{t("Help")}</Nav.Link>
              <Nav.Link href="#link">{t("About us")}</Nav.Link>
            </>
          )}         

          <ProfileIconToggle/>
        </Nav>
      </Navbar.Collapse>
          </Navbar>*/
  );
};
const DetailPage = () => {
  const { t } = useTranslation();
  const scroll = useScroll(30);
  const [toggleOpen, setToggleOpen] = useState();
  const user = useSelector((state) => state.login.user);
  const navtitle = useSelector((state) => state.app.title);
  const microlink = useSelector((state) => state.app.microlink);
  const dispatch = useDispatch();

  return (
    <AppBar
      hideBrand
      title={
        <>
          <div className="ngh-AppBar--title">
            <Link to="/">
              <span className="text-white">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2 ml-4" />
              </span>{" "}
            </Link>
            {navtitle}
          </div>
          {/*<div className="input-group input-group-sm mt-2 mr-sm-0">    
        <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Search" />
        <div className="input-group-append">
          <div className="input-group-text"><FontAwesomeIcon icon={faSearch} /> </div>
      </div>
      </div>*/}
        </>
      }
      userData={user}
      right={<ProfileIconToggle />}
      navItems={[
        {
          text: "Homepage",
          icon: faHome,
          to: microlink,
          exact: true,
        },
        {
          text: "Blog",
          icon: faJournalWhills,
        },
      ]}
    />
  );
};

const MyStarletPage = () => {
  const { t } = useTranslation();
  const scroll = useScroll(30);
  const [toggleOpen, setToggleOpen] = useState();
  const user = useSelector((state) => state.login.user);
  const dispatch = useDispatch();

  return (
    <AppBar
      hideBrand
      title={
        <>
          <div className="ngh-AppBar--title">
            <Link to="/">
              <span className="text-white">
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2 ml-4" />
              </span>{" "}
            </Link>
            My Starlet Page
          </div>
          {/*<div className="input-group input-group-sm mt-2 mr-sm-0">    
        <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Search" />
        <div className="input-group-append">
          <div className="input-group-text"><FontAwesomeIcon icon={faSearch} /> </div>
      </div>
      </div>*/}
        </>
      }
      userData={user}
      right={<ProfileIconToggle />}
    />
  );
};
export default () => {
  return (
    <Switch>
      <Route path="/mystarlet">
        <MyStarletPage />
      </Route>
      <Route path="/login">
        <LandingPage variant="true" />
      </Route>
      <Route path="/console">
        <BusinessBar />
      </Route>
      <Route path="/account-settings">
        <BusinessBar />
      </Route>
      <Route path="/sites*">
        <BusinessBar />
      </Route>      
      <Route path="/profile">
        <BusinessBar />
      </Route>
      <Route path="/show/:id">
        <DetailPage />
      </Route>
      <Route path="/admin">
        <AdminPage />
      </Route>
      <Route path="/">
        <LandingPage />
      </Route>
    </Switch>
  );
};
