// empty
import { all, call, takeEvery } from "redux-saga/effects";
import rsf from "../firebase";
import firebase from "firebase";

import { types } from "redux/actions/editor";

function* addPage(action) {
  console.log(action.path);

  let data = {
    ...action.page,
    dirty: false,
    loading: false,
    status: "draft",
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  try {
    yield call(rsf.firestore.setDocument, action.path, data);
    action.notification("Page saved", {
      appearance: "success",
      autoDismiss: true,
    });
  } catch (e) {
    action.notification("Not Saved! " + e, {
      appearance: "error",
      autoDismiss: true,
    });
  }
}

function* publishPage(action) {
  console.log(action.path);

  try {
    console.log(action.page);
    console.log(
      action.page.versions.find(
        (version) => version.id === action.page.currentId
      )
    );
    action.notification("Publish request sent", {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 1500,
    });
    //  yield call(rsf.firestore.setDocument, action.path, data);
    let publishPage = yield firebase
      .functions()
      .httpsCallable("editor-publishPage");
    let result = yield call(publishPage, action.path);

    action.notification("Page is now live! \n", {
      appearance: "success",
      autoDismiss: true,
    });
  } catch (e) {
    console.log(e);
    action.notification("Page not published! \n" + e, {
      appearance: "error",
      autoDismiss: true,
    });
  }
}

export default function* editorRootSaga() {
  yield all([
    takeEvery(types.SAVE_CURRENT_PAGE, addPage),
    takeEvery(types.PUBLISH_CURRENT_PAGE, publishPage),
  ]);
}
