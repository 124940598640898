import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import Dialog from "components/Dialog";
import Alert from "react-bootstrap/Alert";
import classNames from "classnames";
import * as yup from "yup";
import { setEditAction } from "redux/actions/console";
import { useDispatch } from "react-redux";
import {
  useRouteMatch,
  Link,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import firebase from "firebase";

const dialogSchema = {
  nickname: yup.string().required(),
  interval_count: yup.number().required(),
  usage_type: yup.string().required(),
};

export default ({ product, business }) => {
  let match = useRouteMatch();
  let history = useHistory();

  const [newDialog, setNewDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("business")
      .doc(business.id)
      .collection("products")
      .doc(product.id)
      .collection("plans")
      .onSnapshot(function (querySnapshot) {
        let plans = [];
        querySnapshot.forEach(function (doc) {
          plans.push({ ...doc.data(), id: doc.id });
        });
        setPlans(plans);
      });

    return function cleanup() {
      return new Promise((resolve, reject) => {
        try {
          dispatch(setEditAction(null));
          unsubscribe();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [business.id, dispatch, product, setPlans]);

  return (
    <div className="card mb-5">
      <table className="table table-striped table-light table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>id</th>
            <th>Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {plans.length > 0
            ? plans.map((plan) => (
                <tr
                  style={{ cursor: "pointer", userSelect: "none" }}
                  className={classNames({
                    "bg-primary text-white": selectedRow === plan,
                  })}
                  onClick={() => {
                    setSelectedRow(selectedRow === plan ? null : plan);
                    dispatch(
                      setEditAction(
                        selectedRow !== plan
                          ? `/console/${business.id}/products/list/show/${product.id}/plan/${plan.id}`
                          : null
                      )
                    );
                  }}
                >
                  <td>{plan.nickname}</td>
                  <td>
                    {plan.interval_count
                      ? t(`plan-interval-${plan.interval_count}`)
                      : "n/a"}
                  </td>
                  <td>{plan.planId ? plan.planId : "n/a"}</td>
                  <td></td>
                  <td>
                    <div
                      className={classNames({
                        "bg-success": plan.planId,
                      })}
                    >
                      asdf
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>

      <Route
        path={[`${match.path}*/*`, `${match.path}*`, `${match.path}`]}
        children={({ match, ...rest }) => {
          return (
            <Switch>
              <Route path={`${match.path}/addplantoproduct`}>
                <Dialog
                  show={true}
                  validation={dialogSchema}
                  onSubmit={async (fields, helper) => {
                    helper.setSubmitting(true);
                    try {
                      const db = firebase.firestore();
                      const path = `business/${business.id}/products/${product.id}/plans`;
                      let ref = db.collection(path);
                      const data = {
                        ...fields,
                      };
                      console.log(data);
                      await ref.add(data);
                      setNewDialog(false);
                      helper.resetForm();
                      history.go(-1);
                    } catch (e) {
                      console.log(e);
                      helper.setErrors({ serverError: e.message });
                    }

                    helper.setSubmitting(false);
                  }}
                  initialValues={{
                    currency: "CHF",
                    trial_period_days: 0,
                    amount_decimal: "0.00",
                    usage_type: "licensed",
                  }}
                  title="Add new pricing plan"
                  handleClose={() => history.go(-1)}
                >
                  <Dialog.FormGroup name="nickname" label="Plan nickname" />
                  <Dialog.FormGroup name="currency" label="Currency" disabled />
                  <div className="container-fluid p-0 m-0">
                    <div className="row">
                      <div className="col-md-6">
                        <Dialog.FormGroup
                          name="usage_type"
                          label="Usage type"
                          options={[
                            { key: "", label: "Choose" },
                            { key: "licensed", label: "Abo" },
                            { key: "metered", label: "METERED" },
                          ]}
                        />
                      </div>
                      <div className="col">
                        <Dialog.FormGroup
                          name="interval_count"
                          label="Billing interval"
                          options={[
                            { key: "", label: "Choose" },
                            { key: "1", label: "Monthly" },
                            { key: "3", label: "Every 3 Month" },
                            { key: "6", label: "Every 6 Month" },
                            { key: "12", label: "Yearly" },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <Dialog.FormGroup
                    name="amount_decimal"
                    label="Price"
                    prepend="CHF"
                  />
                  <Dialog.FormGroup
                    name="trial_period_days"
                    type="number"
                    label="Trial period"
                    append="days"
                  />
                  <Alert variant="warning">
                    Once a pricing plan is created, only its nickname and trial
                    period can be updated.
                  </Alert>
                </Dialog>
              </Route>
              {selectedRow ? (
                <Route path={`${match.path}/plan`}>
                  <Dialog
                    show={true}
                    onSubmit={async (fields, helper) => {
                      helper.setSubmitting(true);
                      try {
                        const db = firebase.firestore();
                        const path = `business/${business.id}/products/${product.id}/plans/${fields.id}`;
                        console.log("path");
                        console.log(path);
                        let ref = db.doc(path);
                        await ref.set(
                          {
                            nickname: fields.nickname,
                            trial_period_days: fields.trial_period_days,
                          },
                          {
                            merge: true,
                          }
                        );
                        setShowEditDialog(false);
                        setSelectedRow(null);
                        helper.resetForm();
                        dispatch(setEditAction(null));
                        history.go(-1);
                      } catch (e) {
                        console.log(e);
                        helper.setErrors({ serverError: e.message });
                      }

                      helper.setSubmitting(false);
                    }}
                    initialValues={selectedRow}
                    title={`Edit ${selectedRow.nickname}`}
                    handleClose={() => history.go(-1)}
                  >
                    <Dialog.FormGroup name="nickname" label="Plan nickname" />
                    <Dialog.FormGroup
                      name="currency"
                      label="Currency"
                      disabled
                    />
                    <div className="container-fluid p-0 m-0">
                      <div className="row">
                        <div className="col-md-6">
                          <Dialog.FormGroup
                            name="usage_type"
                            disabled
                            label="Usage type"
                            options={[
                              { key: "", label: "Choose" },
                              { key: "licensed", label: "Abo" },
                              { key: "metered", label: "METERED" },
                            ]}
                          />
                        </div>
                        <div className="col">
                          <Dialog.FormGroup
                            name="interval_count"
                            disabled
                            label="Billing interval"
                            options={[
                              { key: "", label: "Choose" },
                              { key: "1", label: "Monthly" },
                              { key: "3", label: "Every 3 Month" },
                              { key: "6", label: "Every 6 Month" },
                              { key: "12", label: "Yearly" },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <Dialog.FormGroup
                      name="amount_decimal"
                      disabled
                      label="Price"
                      prepend="CHF"
                    />
                    <Dialog.FormGroup
                      name="trial_period_days"
                      label="Trial period"
                      type="number"
                      append="days"
                    />
                  </Dialog>
                </Route>
              ) : null}
            </Switch>
          );
        }}
      />
    </div>
  );
};
