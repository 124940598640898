import React, { useState } from "react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";
import Moment from "react-moment";
import "moment-timezone";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { useQuery } from "react-query";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { useSelector, useDispatch } from "react-redux";
import Status from "components/Status";
import Login from "components/account/login";
import Spinner from "react-bootstrap/Spinner";

import { sendEmailVerification, logout } from "redux/actions/login";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default () => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.login.user);
  const userData = useSelector((state) => {
    return {
      ...state.userData,
      subscriptions: state.userData.subscriptions
        ? state.userData.subscriptions.map((sub) => {
            return {
              ...sub,
              product: state.userData.products
                ? state.userData.products.find(
                    (itm) => itm.id === sub.product.id
                  )
                : sub.product.id,
            };
          })
        : [],
    };
  });

  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  console.log(user);
  console.log(userData);

  const stripe = useStripe();
  const elements = useElements();

  const createPortalLink = async (append = "") => {
    setLoading(true);
    const updateRef = firebase
      .app()
      .functions("europe-west6")
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");

    const { data } = await updateRef({ returnUrl: window.location.href });
    window.location.assign(data.url + append);
  };

  const getSubscriptions = async (key, id) => {
    if (!user) {
      return [];
    }
    const querySnapshot = await firebase
      .firestore()
      .collection("customers")
      .doc(user.uid)
      .collection("subscriptions")
      .get();
    const result = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const prod = await doc.data().product.get();
        console.log(doc.data());
        return { ...doc.data(), id: doc.id, product: { ...prod.data() } };
      })
    );

    return result;
  };

  const { status, data, error, isFetching } = useQuery(
    ["subscriptions", user.uid],
    getSubscriptions
  );

  return (
    <div className="w-100">
      {user ? (
        <div className="container">
          {error ? <Alert variant="danger">{error}</Alert> : null}

          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/account-settings">{t("Account")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Subscriptions")}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-between align-items-end">
              <h1>{t("Subscriptions")}</h1>
              {loading ? (
                <div
                  className="font-weight-light text-center"
                  style={{ fontSize: "15px", minWidth: "200px" }}
                >
                  <Spinner animation="border" size="md" />
                  <br />
                  redirecting to stripe
                </div>
              ) : (
                <div
                  className="btn btn-primary"
                  onClick={() => createPortalLink()}
                >
                  Manage Subscription
                </div>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              <div className="card">
                <ListGroup variant="flush">
                  {data
                    ? data.map((item, index) => (
                        <ListGroup.Item eventKey={index}>
                          <div class="d-flex w-100 justify-content-between">
                            <div>
                              <h5>{item.product.name} </h5>
                              <p>{item.metadata.note || "no description"}</p>
                              <p>
                                {item.metadata.siteName || "no description"}
                              </p>
                              {item.cancel_at_period_end ? (
                                item.ended_at ? null: 
                                <div
                                  className="btn btn-outline-success"
                                  onClick={() =>
                                    createPortalLink(
                                      "/subscriptions/" + item.id + "/reactivate"
                                    )
                                  }
                                >
                                  Renew Subscription
                                </div>
                              ) : (
                                <div
                                  className="btn btn-outline-danger"
                                  onClick={() =>
                                    createPortalLink(
                                      "/subscriptions/" + item.id + "/cancel"
                                    )
                                  }
                                >
                                  Cancel Subscription
                                </div>
                              )}
                              
                            </div>{" "}
                            <div>seats: {item.quantity} {item.canceled_at ? "it is cacneled" : "no item.canceled_at"}</div>
                            <div className="text-center">
                              
                              {item.cancel_at_period_end ? (
                                <span>
                                  canceled <br />
                                  active until
                                  <br />
                                  <Moment format={t("YYYY/MM/DD")}>
                                    {item.current_period_end.toDate()}
                                  </Moment>
                                </span>
                              ) : (
                                item.status
                              )}
                            </div>
                            <div
                              className={classNames({
                                "text-success": "active" === item.status,
                                "text-danger": "canceled" === item.status,
                                "text-warning": item.cancel_at_period_end,
                              })}
                            >
                              <FontAwesomeIcon
                                icon={
                                  "active" === item.status
                                    ? faCheckCircle
                                    : faCircle
                                }
                              />
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))
                    : ""}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <h1>{t("Subscriptions")}</h1>
        </div>
      )}
    </div>
  );
};
