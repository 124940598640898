import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "components/Dialog";
import useEditor from "hooks/editor";

import { returnComponentByType } from "editor";

import { updateComponent, addComponent } from "redux/actions/editor";
import EditorContext from "./EditorContext";

export default ({ component, title = "Component Dialog", onSubmit, add, business }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage] = useEditor();
  const [showDialog, setShowDialog] = useState(true);

  const renderComponent = returnComponentByType(component.type);

  const config = useSelector((state) => state.editor.config);
  
  const document = useSelector((state) => state.editor.document);
  

  return (
    <Dialog
      show={showDialog}
      initialValues={renderComponent.createInitialValue(component, add)}
      title={`${add ? "Add " : ""}${
        renderComponent ? renderComponent.title : title
      }`}
      business={business}
      submitText={add ? "Add" : "Save"}
      onSubmit={async (fields, helper) => {
        let data = renderComponent.beforeSave
          ? renderComponent.beforeSave(fields)
          : fields;

        try {
          //let redirect = await onSubmit(data, helper);
          if (add) {
            dispatch(
              addComponent(
                data,
                document.present.currentPage,
                config.currentLanguage,
                config.selectedComponents
              )
            );
          } else {
            dispatch(
              updateComponent(
                data,
                document.present.currentPage,
                config.currentLanguage
              )
            );
          }
          helper.resetForm();
          //if (redirect) {
          // history.push(redirect);
          // }
          history.go(-1);
          setShowDialog(false);
        } catch (e) {
          helper.setErrors({ serverError: e.message });
        }
        helper.setSubmitting(false);
        // removed onsubmit hanlder form component if (onSubmit) {}
      }}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
    >
      {renderComponent.displayFormFields(component, currentPage )}
    </Dialog>
  );
};
