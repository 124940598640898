import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/pro-light-svg-icons";

export default({title, text})=><div className="m-2 placeholder">
    <div className="text-center">
        <div style={{fontSize: '40px'}}><FontAwesomeIcon icon={faFrown}/></div>
            <h2>{title}</h2>
            <p>{text}</p>
    </div>
</div>