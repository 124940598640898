import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { sendPasswordResetEmail } from "../../redux/actions/login";

import { Formik } from "formik";
import * as yup from "yup";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

export default ({loginMode}) => {
  const { t } = useTranslation();
  const [schema, setSchema] = useState();
  const error = useSelector(state => state.login.error);
  const loading = useSelector(state => state.login.loading);
  const dispatch = useDispatch();
  const sendPasswordResetEmailSuccess = useSelector(
    state => state.login.sendPasswordResetEmailSuccess
  );
  const sendPasswordResetEmailFailure = useSelector(
    state => state.login.sendPasswordResetEmailFailure
  );

  useEffect(() => {
    // Update the document title using the browser API
    yup.setLocale({
      string: {
        min: t("form-validation-min"),
        required: t("form-validation-required"),
        email: t("form-validation-email")
      }
    });

    setSchema(
      yup.object({
        email: yup
          .string()
          .required()
          .email()
      })
    );
  }, [t]);

  return (
  <Formik
      validationSchema={schema}
      onSubmit={ fields =>
         dispatch(sendPasswordResetEmail(fields.email))
      }
      initialValues={{ email: ""}}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors
      }) => (
        <Form noValidate onSubmit={(handleSubmit)}>
            {!loginMode ? <h5>Reset Password</h5> : null}
          {sendPasswordResetEmailFailure && !loading ? (
            <Alert variant="danger"><small>{sendPasswordResetEmailFailure.message}</small></Alert>
          ) : null}
          {sendPasswordResetEmailSuccess && !loading ? (
              <Alert variant="info"><small>{t("Passwort Reset send ")}</small></Alert>
          ) : null}
          {!sendPasswordResetEmailSuccess && !loading ? (
            <div>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="col-form-label-sm pb-0" size="sm">
                  {t("E-Mail address")}
                </Form.Label>
                <Form.Control
                  size="sm"
                  type="email"
                  name="email"
                  feedback={errors.email}
                  placeholder={t("Enter E-Mail")}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" size="sm">
                {loginMode ? t("Login") : t("Reset Password")}
              </Button>
            </div>
          ) : null}

        </Form>
      )}
    </Formik> 
  );
};
