export const types = {
  SYNC_PAGES: "SYNC_PAGES",
  SYNC_PRODUCTS: "SYNC_PRODUCTS",
  SYNC_PACKAGES: "SYNC_PACKAGES",
  SET_EDIT_ACTION: "SET_EDIT_ACTION",
  CREATE: {
    ADD_NEW_BUSINESS: "CREATE.ADD_NEW_BUSINESS",
    ADD_NEW_BUSINESS_SUCCESS: "CREATE.ADD_NEW_BUSINESS_SUCCESS",
    ADD_NEW_BUSINESS_FAILURE: "CREATE.ADD_NEW_BUSINESS_FAILURE",
  },
  UPDATE: {
    UPDATE_BUSINESSES: "UPDATE_BUSINESS",
    UPDATE_BUSINESSES_SUCCESS: "UPDATE_BUSINESS_SUCCESS",
    UPDATE_BUSINESSES_FAILURE: "UPDATE_BUSINESS_FAILURE",
    UPDATE_SUBSCRIPTIONS: "UPDATE_UPDATE_SUBSCRIPTIONS",
    UPDATE_SUBSCRIPTIONS_SUCCESS: "UPDATE_SUBSCRIPTIONS_SUCCESS",
    UPDATE_SUBSCRIPTIONS_FAILURE: "UPDATE_SUBSCRIPTIONS_FAILURE",    
    UPDATE_COMPANY_NAME: "UPDATE_COMPANY_NAME",
    UPDATE_COMPANY_NAME_SUCCESS: "UPDATE_COMPANY_NAME_SUCCESS",
    UPDATE_COMPANY_NAME_FAILURE: "UPDATE_COMPANY_NAME_FAILURE",
    UPDATE_ADDRESS: "UPDATE_ADDRESS",
    UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
    UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",
    UPDATE_TAX_NUMBER: "UPDATE_TAX_NUMBER",
    UPDATE_TAX_NUMBER_SUCCESS: "UPDATE_TAX_NUMBER_SUCCESS",
    UPDATE_TAX_NUMBER_FAILURE: "UPDATE_TAX_NUMBER_FAILURE",
    UPDATE_VAT_NUMBER: "UPDATE_VAT_NUMBER",
    UPDATE_VAT_NUMBER_SUCCESS: "UPDATE_VAT_NUMBER_SUCCESS",
    UPDATE_VAT_NUMBER_FAILURE: "UPDATE_VAT_NUMBER_FAILURE"
  }
};

export const setEditAction = (editAction) => ({
  type: types.SET_EDIT_ACTION,
  editAction
})

export const syncPages = (business, pages) => {
  return {
    type: types.SYNC_PAGES,
    business,
    pages
  }
}
export const syncProducts = (products) => {
  return {
    type: types.SYNC_PRODUCTS,
    products
  }
}

export const syncPackages = (business, packages) => {
  return {
    type: types.SYNC_PACKAGES,
    business,
    packages
  }
}

export const updateBusiness = business => {
  return {
    type: types.UPDATE.UPDATE_BUSINESSES,
    business
  };
};

export const updateSubscriptions = subscriptions => {
  return {
    type: types.UPDATE.UPDATE_SUBSCRIPTIONS,
    subscriptions
  };
};

export const createNewBusiness = fields => ({
  type: types.CREATE.ADD_NEW_BUSINESS,
  fields
});

export const createNewBusinessSuccess = key => ({
  type: types.CREATE.ADD_NEW_BUSINESS_SUCCESS,
  key
});

export const createNewBusinessFailure = error => ({
  type: types.CREATE.ADD_NEW_BUSINESS_FAILURE,
  error
});

export const updateBusinessFailure =  error => ({
  type: types.UPDATE.UPDATE_BUSINESSES_FAILURE,
  error
});

export const updateBusinessSuccess = () => ({
  type: types.UPDATE.UPDATE_BUSINESSES_SUCCESS
});

export const updateCompanyName = name => ({
  type: types.UPDATE.UPDATE_COMPANY_NAME,
  name
});

export const updateCompanyNameFailure = error => ({
  type: types.UPDATE.UPDATE_COMPANY_NAME_FAILURE,
  error
});

export const updateCompanyNameSuccess = () => ({
  type: types.UPDATE.UPDATE_COMPANY_NAME_SUCCESS
});

export const  updateAddress = fields => ({
  type: types.UPDATE.UPDATE_ADDRESS,
  fields
});

export const updateAddressFailure = error => ({
  type: types.UPDATE.UPDATE_ADDRESS_FAILURE,
  error
});

export const updateAddressSuccess = () => ({
  type: types.UPDATE.UPDATE_ADDRESS_SUCCESS
});

export const updateTaxNumber = number => ({
  type: types.UPDATE.UPDATE_TAX_NUMBER,
  number
});

export const updateTaxNumberFailure = error => ({
  type: types.UPDATE.UPDATE_TAX_NUMBER_FAILURE,
  error
});

export const updateTaxNumberSuccess = () => ({
  type: types.UPDATE.UPDATE_TAX_NUMBER_SUCCESS
});

export const updateVatNumber = number => ({
  type: types.UPDATE.UPDATE_VAT_NUMBER,
  number
});

export const updateVatNumberFailure = error => ({
  type: types.UPDATE.UPDATE_VAT_NUMBER_FAILURE,
  error
});

export const updateVatNumberSuccess = () => ({
  type: types.UPDATE.UPDATE_VAT_NUMBER_SUCCESS
});

