import React, { useContext } from "react";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";

import Placeholder from "components/placeholder";
import Dialog from "components/Dialog";
import Component from "editor/Component";

import EditorContext from "editor/EditorContext";

const Headline = ({ text, size, id, type, ...props }) => {
  const context = useContext(EditorContext);

  function renderContent() {
    switch (size) {
      case "2":
        return <h2>{text}</h2>;
      case "3":
        return <h3>{text}</h3>;
      case "4":
        return <h4>{text}</h4>;
      default:
        return <h1>{text}</h1>;
    }
  }
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      {text ? (
        <div className="container content-section">
        {renderContent()}
        </div>
      ) : (
        <Placeholder
          title="empty headline"
          text="edit component and add headline text"
        />
      )}
    </Component>
  ) : text ? (
    <div className="container content-section">
        {renderContent()}
        </div>
  ) : null;
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.HEADLINE,
});

Headline.createInitialValue = (component, add = false) =>
  add ? defaultStructure() : component;
Headline.title = "Headline Component";
Headline.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.HERO,
  ContentTypes.SECTION,
  ContentTypes.TEXT,
  ContentTypes.TEXT_IMAGE,
];
Headline.displayFormFields = () => (
  <>
    <Dialog.FormGroup name="text" label="Text" />
    <Dialog.FormGroup
      name="size"
      label="Size"
      options={[
        { key: "", label: "Choose" },
        { key: "1", label: "H1" },
        { key: "2", label: "H2" },
        { key: "3", label: "H3" },
        { key: "4", label: "H4" },
      ]}
    />
  </>
);
export default Headline;
