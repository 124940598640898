import { combineReducers } from 'redux'

import login from './login'
import { createBusiness, userData, consoleActions } from "./console";
import { updateContactInformation } from "./business";
import { editor } from "./editor";
import { app } from "./app";
import messaging from "./messaging"
import courses from "./courses";

export default combineReducers({
  app,
  login,
  createBusiness,
  userData,
  updateContactInformation,
  consoleActions,
  editor,
  messaging,
  courses
})