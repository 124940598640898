import React, { useState, useContext } from "react";
import classNames from "classnames";
import EditorContext from "editor/EditorContext";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";
import Component from "editor/Component";
import Dialog from "components/Dialog";
import Placeholder from "components/placeholder";
import Section from "editor/content/Section";

export const createInitialValue = (component) => component;
export const title = "Column Component";

const Column = ({
  content,
  id,
  type,
  fg,
  containerType,
  fullheight,
  fullwidth,
  bg,
  marginTop = "no",
  ...props
}) => {
  const [disableHover, setDisableHover] = useState(false);
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component
      type={type}
      id={id}
      {...props}
      disableHover={disableHover}
      key={id}
    >
      <div
        className={classNames({
          container: !containerType || containerType === "regular",
          "container-fluid": containerType || containerType === "fluid",
          "mt-2": marginTop === "small",
          "mt-3": marginTop === "medium",
          "mt-4": marginTop === "big",
          "h-100": fullheight,
          "m-0": fullwidth,
        })}
        key={`${id}-wrapper`}
        style={{ backgroundColor: bg, color: fg }}
      >
        <Section
          onHover={(value) => setDisableHover(value)}
          {...content}
          key={`${content.id}-section`}
        />
      </div>
    </Component>
  ) : (
    <div
      className={classNames({
        container: !containerType || containerType === "regular",
        "container-fluid": containerType || containerType === "fluid",
        "mt-2": marginTop === "small",
        "mt-3": marginTop === "medium",
        "mt-4": marginTop === "big",
        "h-100": fullheight,
        "m-0": fullwidth,
      })}
      key={`${id}-wrapper`}
      style={{ backgroundColor: bg, color: fg }}
    >
      <Section
        onHover={(value) => setDisableHover(value)}
        {...content}
        key={`${content.id}-section`}
      />
    </div>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.COLUMN,
  parsys: [],
});

Column.accept = [ContentTypes.COLUMN];

Column.createInitialValue = (component, add) =>
  add ? defaultStructure() : component;
Column.title = "Column Component";

Column.displayFormFields = (component) => (
  <>
    <Dialog.FormGroup
      name="containerType"
      label="Column Type"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "fluid", label: "Fluid" },
        { key: "regular", label: "Regular" },
      ]}
    />

    <Dialog.FormGroup name="fg" label="foreground color" type="color" />
    <Dialog.FormGroup name="bg" label="bg" type="color" />
    <Dialog.FormGroup
      name="marginTop"
      label="marginTop"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "no", label: "no margin" },
        { key: "small", label: "small" },
        { key: "medium", label: "medium" },
        { key: "big", label: "big" },
      ]}
    />
  </>
);

export default Section;
