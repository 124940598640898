import React, { useContext } from "react";
import EditorContext from "editor/EditorContext";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { uuid } from "helper";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ContentTypes from "editor/ContentTypes";
import Component from "editor/Component";
import Dialog from "components/Dialog";
import Placeholder from "components/placeholder";

function content(title, text) {
  return (
    <>
      <h1 className="text-header">{title}</h1>
      {text}
    </>
  );
}

const TextBlock = ({ position = "left", title, image, children }) => (
  <Container fluid className="mt-5 text-content">
    <Row className={position === "left" ? null : "direction-reverse"}>
      <Col
        md={{ offset: 2, span: 4 }}
        className={position === "left" ? "text-image" : null}
      >
        {position === "left" ? image : content(title, children)}
      </Col>
      <Col
        md={{ span: 4 }}
        className={position === "left" ? null : "text-image"}
      >
        {position === "left" ? content(title, children) : image}
      </Col>
    </Row>
  </Container>
);

const TextImage = ({ text, id, type, title, position, image, ...props }) => {
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      <TextBlock
        title={title}
        position={position}
        image={image ? <img src={image.url} width="100%" /> : null}
      >
        {text ? (
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></span>
        ) : (
          <Placeholder
            title="empty text"
            text="edit component and add text and image"
          />
        )}
      </TextBlock>
    </Component>
  ) : (
    <TextBlock
      title={title}
      position={position}
      image={image ? <img src={image.url} width="100%" /> : null}
    >
      {text ? (
        <span
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></span>
      ) : (
        <Placeholder
          title="empty text"
          text="edit component and add text and image"
        />
      )}
    </TextBlock>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.TEXT_IMAGE,
  text: EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(""))
  ),
});

TextImage.beforeSave = (component) => ({
  ...component,
  text: convertToHTML(component.text.getCurrentContent()),
});

TextImage.createInitialValue = (component, add) =>
  add
    ? defaultStructure()
    : {
        ...component,
        text: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(component.text || "")
          )
        ),
      };

TextImage.title = "Text & Image Component";

TextImage.displayFormFields = () => (
  <Dialog.Tabs defaultActiveKey="0" id="text-image-dialog-component">
    <Dialog.Tab title="Text" eventKey={0}>
      <Dialog.FormGroup name="title" type="header" label="Header" />
      <Dialog.FormGroup name="text" type="richtext" label="Text" />
    </Dialog.Tab>
    <Dialog.Tab title="Image" eventKey={1}>
      <Dialog.FormGroup
        name="position"
        label="Image Position"
        options={[
          { key: "", label: "Choose" },
          { key: "left", label: "Left" },
          { key: "right", label: "Right" },
        ]}
      />
      <Dialog.FormGroup name="image" type="image" label="Image" />
    </Dialog.Tab>
  </Dialog.Tabs>
);

TextImage.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.TEXT,
  ContentTypes.TEXT_IMAGE,
  ContentTypes.HERO,
  ContentTypes.SECTION,
];

export default TextImage;
