import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { loginUsernamePassword, registerUsernamePassword } from "../../redux/actions/login";

import { Formik } from "formik";
import * as yup from "yup";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

export default ({loginMode}) => {
  const { t } = useTranslation();
  const [schema, setSchema] = useState();
  const error = useSelector(state => state.login.error);
  const loading = useSelector(state => state.login.loading);
  //const loading = useSelector(state => state.login.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    yup.setLocale({
      string: {
        min: t("form-validation-min"),
        required: t("form-validation-required"),
        email: t("form-validation-email")
      }
    });

    setSchema(
      yup.object({
        email: yup
          .string()
          .required()
          .email(),
        password: yup
          .string()
          .required()
          .min(6)
      })
    );
  }, [t]);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={fields =>
         dispatch(loginMode ? loginUsernamePassword(fields.email, fields.password)
         : registerUsernamePassword(fields.email, fields.password))
      }
      initialValues={{ email: "", password: "" }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
            {!loginMode ? <h5>{t("Register")}</h5> : null}
          {error && !loading ? (
            <Alert variant="danger"><small>{error.message}</small></Alert>
          ) : null}
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="col-form-label-sm pb-0" size="sm">
              {t("E-Mail address")}
            </Form.Label>
            <Form.Control
              size="sm"
              type="email"
              name="email"
              feedback={errors.email}
              placeholder={t("Enter E-Mail")}
              onChange={handleChange}
              isValid={touched.email && !errors.email}
              isInvalid={touched.email && errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="col-form-label-sm pb-0">
              {t("Password")}
            </Form.Label>
            <Form.Control
              size="sm"
              type="password"
              name="password"
              placeholder={t("Enter Password")}
              onChange={handleChange}
              isValid={touched.password && !errors.password}
              isInvalid={touched.password && errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" size="sm">
            {loginMode ? t("Login") : t("Register")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
