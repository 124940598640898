import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AddNewDialog from "./AddNewDialog";
import Overview from "./Overview";
//import Detail from "./Detail";

const Members = ({ business }) => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <h1>Detail</h1>
      </Route>
      <Route path={`${match.path}`}>
        <Overview business={business} />
      </Route>
    </Switch>
  );
};
Members.Dialog = AddNewDialog;

export default Members;
