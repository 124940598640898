import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as yup from "yup";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { updatePassword, updatePasswordClear } from "../../redux/actions/login";

export default () => {
  const [showForm, setShowForm] = useState();
  const [schema, setSchema] = useState();  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.login.loading);
  const updatePasswordSuccess = useSelector(
    state => state.login.updatePasswordSuccess
  );
  const updatePasswordError = useSelector(
    state => state.login.updatePasswordError
  );
  useEffect(() => {    
    yup.setLocale({
      string: {
        min: t("form-validation-min"),
        required: t("form-validation-required"),
        email: t("form-validation-email")
      }
    });

    setSchema(
      yup.object({
        password2: yup
          .string()
          .required()
          .oneOf([yup.ref("password"), null], t("Passwords must match"))
          .min(6),
        password: yup
          .string()
          .required()
          .min(6)
      })
    );
  }, [t]);
  const show = loading || updatePasswordSuccess || updatePasswordError;
  return (
    <>
      {showForm ? (
        <>
          {loading ? (
            <Modal
              size="lg"
              show={loading}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title>{t("Updating Password")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                {updatePasswordSuccess || updatePasswordError ? null : (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="fa-pulse fa-3x fa-fw text-brand"
                  />
                )}
                {updatePasswordError ? <Alert variant="danger">{updatePasswordError.message}</Alert> : null}
                {updatePasswordSuccess
                  ? t("Your Password has been changed")
                  : null}
              </Modal.Body>

              {updatePasswordSuccess || updatePasswordError ? (
                <Modal.Footer>
                  {" "}
                  <Button
                    onClick={() => {
                      dispatch(updatePasswordClear());
                      setShowForm(false);
                    }}
                  >
                    {t("Close")}
                  </Button>
                </Modal.Footer>
              ) : null}
            </Modal>
          ) : null}

          <Formik
            validationSchema={schema}
            onSubmit={fields => dispatch(updatePassword(fields.password))}
            initialValues={{ email: "", password: "" }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {updatePasswordError && !loading ? (
                  <Alert variant="danger">
                    <small>{updatePasswordError.message}</small>
                  </Alert>
                ) : null}
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="col-form-label-sm pb-0" size="sm">
                    {t("Password")}
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    name="password"
                    feedback={errors.password}
                    placeholder={t("Enter new password")}
                    onChange={handleChange}
                    isValid={touched.password && !errors.password}
                    isInvalid={errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label className="col-form-label-sm pb-0">
                    {t("Password")}
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    name="password2"
                    placeholder={t("Enter Password")}
                    onChange={handleChange}
                    isValid={touched.password2 && !errors.password2}
                    isInvalid={touched.password2 && errors.password2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password2}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="info" type="submit" size="sm">
                  {t("Change Password")}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Button size="sm" onClick={() => setShowForm(true)}>
          {t("Update Password")}
        </Button>
      )}
    </>
  );
};
