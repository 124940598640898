import firebase from "firebase";
import "firebase/firestore";
import ReduxSagaFirebase from "redux-saga-firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD5ANPyuByDaNaKE0md354DSIXXJt61H3g",
  authDomain: "nghelvetia.firebaseapp.com",
  databaseURL: "https://nghelvetia.firebaseio.com",
  projectId: "nghelvetia",
  storageBucket: "nghelvetia.appspot.com",
  messagingSenderId: "397622595358",
  appId: "1:397622595358:web:f50053c20c254d0e2d430f",
  measurementId: "G-LXSLCN8LKL"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === "localhost") {
  firebaseApp.firestore().useEmulator("localhost", 8080);

  firebaseApp.functions().useEmulator("localhost", 5001);
  var auth = firebase.auth();
    auth.useEmulator("http://localhost:9099");
    firebase.database().useEmulator("localhost", 9000);
}

firebase.auth().useDeviceLanguage();

export default new ReduxSagaFirebase(firebaseApp);
