import firebase from "firebase";
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import rsf from "../firebase";

import {
  types,
  updateContactInformationSuccess,
  updateContactInformationError
} from "../actions/business";

function* updateContactInformation(action) {
    
  try {
    console.log("update contact information")
    console.log(action.fields);
    yield call(rsf.database.update, `/business/${action.fields.id}`, action.fields)
      /*const contact = yield call(rsf.database.updateContactInformation, 
        action.name,
        action.firstName, 
        action.lastName,
        action.address.line1,
        action.address.line2,
        action.address.postal_code,
        action.address.city,
        action.address.country,
        action.phone
        );*/
        console.log("success");
      yield put(updateContactInformationSuccess());
    } catch (error) {
      console.log("error");
      console.log(error);
        yield put(updateContactInformationError(error));
    }
}

export default function* loginRootSaga() {
  yield all([
      takeEvery(types.UPDATE.CONTACT_INFORMATION, updateContactInformation)]);
}
