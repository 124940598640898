import React, { useState, useRef, useContext } from "react";
import { FilePond, registerPlugin, File } from "react-filepond";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { uuid } from "helper";
import firebase from "firebase";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CardColumns from "react-bootstrap/CardColumns";
import Card from "react-bootstrap/Card";
import classNames from "classnames";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Import the plugin code
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

// Import the plugin styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "vendor/doka/react/esm/lib/doka.min.css";
import { DialogContext } from "./Dialog";

import Spinner from "react-bootstrap/Spinner";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageEdit,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

export default ({ field, setValue, business, ...props }) => {
  const [error, setError] = useState();
  const [uploadValue, setUploadValue] = useState();
  const pond = useRef();
  const dialogContext = useContext(DialogContext);

  const [files, setFiles] = useState([]);
  const [ready, setReady] = useState(false);

  const [showImageChooser, setShowImageChooser] = useState(false);

  const handleProcessing = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    const storageRef = firebase.storage().ref();
    const fileUpload = file;
    const root = `business/${dialogContext.business.id}/images/${uuid()}`;
    const task = storageRef.child(root).put(fileUpload);

    task.on(
      `state_changed`,
      (snapshot) => {
        console.log(snapshot.bytesTransferred, snapshot.totalBytes);
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
      },
      (er) => {
        //Error
        setError(`Upload error : ${er.message}`);
        error(er);
      },
      () => {
        storageRef
          .child(root)
          .getMetadata()
          .then((metadata) => {
            storageRef
              .child(root)
              .getDownloadURL()
              .then((url) => {
                let metadataFile = {
                  name: metadata.name,
                  size: metadata.size,
                  contentType: metadata.contentType,
                  fullPath: metadata.fullPath,
                  url,
                };

                load(url);
                setValue(metadataFile);
              });
          })
          .catch(function (e) {
            error(e);
          });
      }
    );
  };

  return (
    <div className="text-center">
      <a
        href="#d"
        onClick={(e) => {
          e.preventDefault();
          setShowImageChooser(true);
        }}
        alt="search for images"
      >
        Search for images
      </a>
      <ImageChooser
        show={showImageChooser}
        onHide={() => setShowImageChooser(false)}
        onSubmit={(image)=>{setShowImageChooser(false); setValue(image);}}
      ></ImageChooser>
      {field.value && field.value.url ? (
        <div
          style={{
            height: "200px",
          }}
          onClick={() => {
            setValue();
            setFiles();
          }}
        >
          <div
            style={{
              background: "url(" + field.value.url + ")",
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
              backgroundPosition: "center center",
            }}
          ></div>
        </div>
      ) : (
        <FilePond
          ref={pond}
          files={files}
          uploadValue={uploadValue}
          allowMultiple={false}
          maxFiles={1}
          allowFilePoster={true}
          imageEditAllowEdit={true}
          server={{
            process: handleProcessing,
          }}
          labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
          imagePreviewHeight="150"
          imageCropAspectRatio="1:1"
          oninit={() => setReady(true)}
          onupdatefiles={(fileItems) => {
            setFiles(fileItems.map((fileItem) => fileItem.file));
          }}
        />
      )}
    </div>
  );
};

const ImageChooser = (props) => {
  /* let metadataFile = {
                  name: metadata.name,
                  size: metadata.size,
                  contentType: metadata.contentType,
                  fullPath: metadata.fullPath,
                  url,
                };
                
                */
  const [data, setData] = useState({});
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("shit");
  const API_KEY = "N5TkGWacb-T7bg1O6kYhC31gm6HwT-9dmpscJX7_vZ4";
  const fetchImages = async () => {
    setLoading(true);
    const response = await fetch(
      `https://api.unsplash.com/search/photos/?client_id=${API_KEY}&query=${searchQuery}&orientation=landscape`
    );
    if (response.ok) {
      const result = await response.json();
      setData(result);
    }
    setLoading(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <Row>
            <Col>
              <Form.Control
                placeholder="Search for Photos"
                name="query"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
            <Col>
              <Button onClick={() => fetchImages()}>
                {loading ? <Spinner animation="border" /> : "Search"}
              </Button>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CardColumns>
          {data.results && data.results.length > 0
            ? data.results.map((item) => (
                <Card
                  className={classNames({
                    "image-chooser": true,
                    "image-chooser--selected":
                      selected && selected.id === item.id,
                  })}
                  key={item.id}
                  onClick={() => {
                    if (selected && selected.id === item.id) {
                      setSelected();
                    } else {
                      setSelected(item);
                    }
                  }}
                >
                  <Card.Img variant="top" src={item.urls.regular} />
                  <Card.Body>
                    <Card.Title>{item.description}</Card.Title>
                    <Card.Text>From {item.user.name}</Card.Text>
                  </Card.Body>
                </Card>
              ))
            : "search"}
        </CardColumns>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>props.onSubmit({
          author: selected.user.name,
          url: selected.urls.regular,
          source: 'unsplash'
        })} disabled={!selected}>Choose Image</Button>
      </Modal.Footer>
    </Modal>
  );
};
