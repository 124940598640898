import React, { useState, useContext } from "react";
import EditorContext from "editor/EditorContext";
import classNames from "classnames";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";
import Component from "editor/Component";
import ContentMapper from "editor/ContentMapper";
import Dialog from "components/Dialog";
import Placeholder from "components/placeholder";
import Accordion from "react-bootstrap/Accordion";
export const createInitialValue = (component) => component;
export const title = "Course Schedule";

const Section = ({
  parsys,
  id,
  type,
  fg,
  containerType,
  fullheight,
  fullwidth,
  bg,
  marginTop = "no",
  ...props
}) => {
  const [disableHover, setDisableHover] = useState(false);
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component
      type={type}
      id={id}
      {...props}
      disableHover={disableHover}
      key={id}
    >
      <div
        className={classNames({
          container: !containerType || containerType === "regular",
          "container-fluid": containerType || containerType === "fluid",
          "mt-2": marginTop === "small",
          "mt-3": marginTop === "medium",
          "mt-4": marginTop === "big",
          "h-100": fullheight,
        })}
        key={`${id}-wrapper`}
        style={{ backgroundColor: bg, color: fg }}
      >
        <h1>Schedule</h1>

        {parsys && parsys.length > 0 ? (
          <Accordion defaultActiveKey="0">
            <ContentMapper
              onHover={(value) => setDisableHover(value)}
              content={parsys}
              key={`${id}-section`}
            />
          </Accordion>
        ) : (
          <Placeholder
            title={`empty course schedule`}
            text="add a schedule component for this course"
          />
        )}
      </div>
    </Component>
  ) : (
    <div
      className={classNames({
        container: !containerType || containerType === "regular",
        "container-fluid": containerType || containerType === "fluid",
        "mt-2": marginTop === "small",
        "mt-3": marginTop === "medium",
        "mt-4": marginTop === "big",
        "h-100": fullheight,
      })}
      key={`${id}-wrapper`}
      style={{ backgroundColor: bg, color: fg }}
    >
      <div className="container content-section">
        <h3>Schedule</h3>
      </div>
      {parsys && parsys.length > 0 ? (
        <Accordion defaultActiveKey="0">
          <ContentMapper
            onHover={(value) => setDisableHover(value)}
            content={parsys}
            key={`${id}-section`}
          />
        </Accordion>
      ) : (
        <Placeholder
          title={`empty course schedule`}
          text="add a schedule component for this course"
        />
      )}
    </div>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.SECTION,
  parsys: [],
});

Section.accept = [ContentTypes.COURSE_SCHEDULE, ContentTypes.COURSE_LIST];

Section.createInitialValue = (component, add) =>
  add ? defaultStructure() : component;
Section.title = "Course Schedule";

Section.displayFormFields = (component) => (
  <>
    <Dialog.FormGroup
      name="containerType"
      label="Container Type"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "fluid", label: "Fluid" },
        { key: "regular", label: "Regular" },
      ]}
    />

    <Dialog.FormGroup name="fg" label="foreground color" type="color" />
    <Dialog.FormGroup name="bg" label="bg" type="color" />
    <Dialog.FormGroup
      name="marginTop"
      label="marginTop"
      disabled={component && component.fixposition}
      options={[
        { key: "", label: "Choose" },
        { key: "no", label: "no margin" },
        { key: "small", label: "small" },
        { key: "medium", label: "medium" },
        { key: "big", label: "big" },
      ]}
    />
  </>
);

export default Section;
