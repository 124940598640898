import React, { useEffect } from "react";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

const CustomToggle = React.forwardRef(
  ({ children, onClick, icon, items, toolbar, disabled, divider }, ref) => (
    <div
      className={classNames({
        "ngh-console__toolbar__item": true,
        "ngh-console__toolbar__item--disabled": disabled,
        "ngh-console__toolbar__item--toolbar": toolbar,
        "ngh-console__toolbar__item--divider": divider,
      })}
      onClick={disabled ? () => null : onClick}
      ref={ref}
    >
      {icon ? (
        <FontAwesomeIcon
          icon={icon}
          className={classNames({
            "ngh-console__toolbar__item__icon": true,
          })}
        />
      ) : null}
      <div className="ngh-console__toolbar__item__text">{children}</div>
      {items ? (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{ fontSize: "10px" }}
          className={classNames({
            "ngh-console__toolbar__item__icon": true,
          })}
        />
      ) : null}
    </div>
  )
);

export default ({
  children,
  icon,
  onClick,
  to,
  color,
  items,
  sidebar,
  exact = false,
  toolbar = false,
  navigation = false,
  rewrite,
  active,
  disabled,
  divider,
  keystroke,
  count,
  url,
}) => {
  const history = useHistory();
  let match = useRouteMatch(to);

  function rewriteUrl(url, rewrite, to) {
    return rewrite ? url + to : to;
  }

  // TODO: refactor
  function handleClick(e, options = {}) {
    e.preventDefault();

    if (!disabled) {
      if (options.onClick || onClick) {
        if (options.onClick) {
          options.onClick(e);
        } else {
          onClick(e);
        }
      } else if (to || options.to) {
        let link = rewriteUrl(url, rewrite, to);
        if (options.to) {
          link = rewriteUrl(url, options.rewrite, options.to);
        }

        history.push(link);
      }
    }
  }

  useEffect(
    () => {
      if (!items && keystroke) {
        let keyFn = (event) => {
          let isCtrlKeyDown = keystroke.ctrl
            ? navigator.platform.indexOf("Mac") > -1
              ? event.metaKey
              : event.ctrlKey
            : true;
          let isShiftKeyDown = keystroke.shift ? event.shiftKey : true;

          let isSDown = event.key && event.key === keystroke.key; // falls back to keycode if no event.key

          if (isCtrlKeyDown && isShiftKeyDown && isSDown) {
            handleClick(event);
            event.preventDefault();
          }
        };

        document.addEventListener("keydown", keyFn);

        return () => document.removeEventListener("keydown", keyFn);
      }
    },
    [disabled, handleClick, items, keystroke],
    keystroke,
    disabled
  );

  return items ? (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        icon={icon}
        items={items}
        toolbar={toolbar}
        disabled={disabled}
        key={to}
        id="dropdown-custom-components"
      >
        {children}
      </Dropdown.Toggle>

      <Dropdown.Menu className="ngh-console__toolbar__item__menu">
        {items.map((item) =>
          item.type && item.type === "divider" ? (
            <Dropdown.Divider />
          ) : (
            <Dropdown.Item
              key={item.to}
              onClick={(e) =>
                handleClick(e, {
                  to: item.disabled
                    ? ""
                    : item.rewrite
                    ? match.url + item.to
                    : item.to,
                  onClick: item.onClick,
                })
              }
              eventKey="1"
              style={{ paddingLeft: "5px", marginLeft: 0 }}
              className={classNames({
                "ngh-console__toolbar__item__menu__item": true,
                "ngh-console__toolbar__item--disabled": item.disabled,
                "ngh-console__toolbar__item--divider": item.divider,
                "ngh-console__toolbar__item--active": item.active
                  ? item.active
                  : null,
              })}
            >
              {item.icon ? (
                <FontAwesomeIcon
                  icon={item.icon}
                  style={{ color: item.color }}
                  className={classNames({
                    "ngh-console__toolbar__item__icon": true,
                    "ngh-console__toolbar__item__icon--menu": true,
                  })}
                />
              ) : null}
              {item.text}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <div
      className={classNames({
        "ngh-console__toolbar__item": true,
        "ngh-console__toolbar__item--sidebar": sidebar,
        "ngh-console__toolbar__item--toolbar": toolbar,
        "ngh-console__toolbar__item--navigation": toolbar,
        "ngh-console__toolbar__item--disabled": disabled,
        "ngh-console__toolbar__item--active":
          active || (match && to ? (exact ? match.isExact : true) : false),
      })}
      onClick={handleClick}
      key={to}
    >
      {icon ? (
        <div style={{ position: "relative" }}>
          <FontAwesomeIcon
            icon={icon}
            style={{ color }}
            className={classNames({
              "ngh-console__toolbar__item__icon": true,
              "ngh-console__toolbar__item__icon--toolbar": toolbar,
              "ngh-console__toolbar__item__icon--sidebar": sidebar,
              "ngh-console__toolbar__item__icon--navigation": navigation,
            })}
          />
          {count ? (
            <div
              className="ngh-console__toolbar__item__count"
              style={{ position: "absolute" }}
            >
              {count}
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="ngh-console__toolbar__item__text">{children}</div>
    </div>
  );
};
