import React, { useState } from "react";
import firebase from "firebase";

import Dialog from "components/Dialog";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

const dialogSchema = {
  name: yup.string().required(),
};

export default ({ business }) => {
  const history = useHistory();

  const [showDialog, setShowDialog] = useState(true);

  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);
    try {
      const db = firebase.firestore();
      const path = `business/${business.id}/packages`;
      let ref = db.collection(path);
      const data = {
        ...fields,
      };
      console.log(data);
      const newdoc = await ref.add(data);
      setShowDialog(false);
      helper.resetForm();
      window.setTimeout(
        () =>
          history.push(
            `/console/${business.id}/products/subscriptions/${newdoc.id}`
          ),
        400
      );
    } catch (e) {
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog
      validation={dialogSchema}
      submitText="Continue"
      title="Add New Subscription Package"
      show={showDialog}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
      onSubmit={submitHandler}
      initialValues={{
        active: false,
      }}
    >
      <Dialog.FormGroup name="name" label="Package Name" />
    </Dialog>
  );
};
