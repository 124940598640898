import React from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useRouteMatch } from "react-router-dom";

import {
  changeCurrentLanguage,
  addPage,
  publishPage,
  removeComponent,
} from "redux/actions/editor";
import { ActionCreators } from "redux-undo";
import useEditor from "hooks/editor";
import { returnComponentByType } from "editor";
import ContentTypes from "editor/ContentTypes";

import Console from "components/console";
import {
  faEdit,
  faTrashAlt,
  faArrowAltToTop,
  faArrowAltToBottom,
  faInfoCircle,
  faParagraph,
  faImage,
  faCartPlus,
  faColumns,
  faSquare,
  faIdCard,
  faSave,
  faFilm,
  faHeading,
  faPhotoVideo,
  faPlus,
  faFileAlt,
  faNewspaper,
  faPuzzlePiece,
  faText,
  faClock,
  faCogs,
  faHistory,
  faGlobeEurope,
  faCloudUpload,
  faFlag,
  faUndo,
  faRedo,
  faMask,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const EditorToolbar = ({ basePath, targetPath }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const match = useRouteMatch();

  const selectedComponents = useSelector(
    (state) => state.editor.config.selectedComponents
  );

  const document = useSelector((state) => state.editor.document.present);
  const config = useSelector((state) => state.editor.config);
  const editor = useSelector((state) => state.editor.document.present);
  const future = useSelector((state) => state.editor.document.future);
  const past = useSelector((state) => state.editor.document.past);

  function isDisabled(ctype) {
    console.log("selectedComponents");
    console.log(selectedComponents);
    console.log("ctype");
    console.log(ctype);
    const renderComponent =
      selectedComponents && selectedComponents.length > 0
        ? returnComponentByType(selectedComponents[0].type)
        : null;
    if (renderComponent) {
      console.log(renderComponent.accept);
    } else {
      console.log("no render ocponet");
    }
    return renderComponent &&
      renderComponent.accept &&
      renderComponent.accept.indexOf(ctype) !== -1
      ? false
      : true;
  }

  function renderNewItems() {

    let subnavs = [{
      text: "Class or Workshop",
      icon: faFileAlt,
      to: "/add/course",
      rewrite: true,
    }] ;
    
    if(match.params.cmd === "schedule") {
        subnavs.push({
          text: "New Schedule",
          icon: faClock,
          to: "/new/schedule",
          rewrite: true,
        })
    }
    return subnavs;

  }

  return (
    <Console.LinkItems
      items={[
        {
          icon: faPlus,
          text: "New",
          items: [
            ...renderNewItems()
          ],
        },
        {
          icon: faSave,
          text: "Save",
          keystroke: {
            key: "s",
            ctrl: true,
          },
          onClick: () => {
            dispatch(
              addPage(
                targetPath + "/" + document.currentPage.id,
                document.currentPage,
                addToast
              )
            );
            dispatch(ActionCreators.clearHistory());
          },
          disabled: !document || !document.dirty,
        },
        {
          icon: faCloudUpload,
          text: "Publish",
          onClick: () => {
            dispatch(
              publishPage(
                targetPath + "/" + document.currentPage.id,
                document.currentPage,
                addToast
              )
            );
          },
          disabled:
            !document ||
            document.currentPage.status === "published" ||
            document.currentPage.status === "edited" ||
            document.currentPage.status === "new",
        },
        {
          icon: faCogs,
          text: "Settings",
          to: "/do/settings",
          rewrite: true,
          disabled: !document,
        },
        {
          icon: faPuzzlePiece,
          text: "Components",
          disabled: !document,
          items: [
            {
              text: "Schedule",
              to: `/do/add-cmp-schedule`,
              icon: faClock,
              disabled: isDisabled(ContentTypes.COURSE_SCHEDULE),
              rewrite: true,
            },
            {
              text: "Section",
              to: `/do/add-cmp-section`,
              icon: faParagraph,
              rewrite: true,
              disabled: isDisabled(ContentTypes.SECTION),
            },
            {
              text: "Headline",
              to: `/do/add-cmp-headline`,
              icon: faHeading,
              disabled: isDisabled(ContentTypes.HEADLINE),
              rewrite: true,
            },
            {
              text: "Text",
              to: `/do/add-cmp-text`,
              icon: faText,
              disabled: isDisabled(ContentTypes.TEXT),
              rewrite: true,
            },
            {
              text: "Card",
              to: `/do/add-cmp-card`,
              icon: faSquare,
              disabled: isDisabled(ContentTypes.CARD),
              rewrite: true,
            },
            {
              text: "Image",
              to: `/new1`,
              icon: faImage,
              disabled: true,
              rewrite: true,
            },
            {
              text: "Text & Image",
              to: `/new2`,
              icon: faIdCard,
              disabled: true,
              rewrite: true,
            },
            {
              text: "Gallery",
              to: `/new3`,
              icon: faPhotoVideo,
              disabled: true,
              rewrite: true,
            },
            {
              text: "Products",
              to: `/new4`,
              icon: faCartPlus,
              disabled: true,
              rewrite: true,
            },
            {
              text: "Columns",
              to: `/do/add-cmp-columns`,
              icon: faColumns,
              rewrite: true,
              disabled: isDisabled(ContentTypes.COLUMNS),
            },
            {
              text: "Video",
              to: `/do/add-cmp-video`,
              icon: faFilm,
              disabled: isDisabled(ContentTypes.VIDEO),
              rewrite: true,
            },
            {
              text: "Hero",
              to: `/do/add-cmp-hero`,
              icon: faFilm,
              disabled: isDisabled(ContentTypes.HERO),
              rewrite: true,
            },
          ],
        },
        {
          icon: faEdit,
          text: "Edit",
          to: "/do/editcomponent",
          rewrite: true,
          disabled: selectedComponents.length !== 1,
        },
        {
          text: "Undo",
          icon: faUndo,
          keystroke: {
            key: "z",
            ctrl: true,
          },
          onClick: () => dispatch(ActionCreators.undo()),
          disabled: past.length === 0,
        },
        {
          text: "Redo",
          icon: faRedo,
          keystroke: {
            key: "z",
            ctrl: true,
            shift: true,
          },

          onClick: () => dispatch(ActionCreators.redo()),
          disabled: future.length === 0,
        },
        {
          icon: faTrashAlt,
          text: "Delete",
          disabled:
            selectedComponents.length === 0 ||
            (selectedComponents[0].fixposition &&
              selectedComponents[0].fixposition === true),
          onClick: () =>
            dispatch(
              removeComponent(
                selectedComponents[0],
                document.currentPage,
                config.currentLanguage
              )
            ),
          count:
            selectedComponents.length > 1 ? selectedComponents.length : null,
        },
      ]}
    />
  );
};

const EditorActionToolbar = ({ basePath }) => {
  const dispatch = useDispatch();
  const [currentPage, editor] = useEditor();
  const status = useSelector(
    (state) => state.editor && state.editor.document && state.editor.document.present && state.editor.document.present.currentPage ? state.editor.document.present.currentPage.status : null
  );

  return (
    <Console.LinkItems
      items={[
        {
          text: status === "published" ? "Published" : "Draft",
          color: status === "published" ? "green" : "orange",
          icon: faCircle,
        },
        {         
          icon: faInfoCircle
        }  /*,
        {
          text: "Language (" + editor.currentLanguage + ")",
          icon: faGlobeEurope,
          items: [
            {
              icon: faFlag,
              text: "Deutsch",
              active: editor.currentLanguage === "de",
              onClick: () => dispatch(changeCurrentLanguage("de")),
            },
            {
              icon: faFlag,
              text: "English",
              active: editor.currentLanguage === "en",
              onClick: () => dispatch(changeCurrentLanguage("en")),
            },
          ],
        },
        {
          text: "Versions",
          icon: faHistory,
          items: [
            {
              icon: faClock,
              text: "version 1",
            },
            {
              icon: faClock,
              text: "version 2",
            },
            {
              divider: true,
            },
            {
              text: "Create new version",
              icon: faPlus,
            },
          ],
        },*/,
      ]}
    />
  );
};

export const ActionToolbar = ({ business }) => {
  const basePath = `/console/${business.id}/courses/view/:id`;
  const targetPath = `/business/${business.id}/courses/view`;

  return (
    <Switch>
      <Route path={basePath} exact>
        <EditorActionToolbar basePath={basePath} targetPath={targetPath} />
      </Route>
    </Switch>
  );
};

export default ({ business }) => {
  const basePath = `/console/${business.id}/courses`;
  const targetPath = `/business/${business.id}/courses`;
  return (
    <Switch>
      <Route
        path={[
          `/console/${business.id}/courses/view/:pagename/:cmd/*`,
          `/console/${business.id}/courses/view/:pagename/:cmd`,
          `/console/${business.id}/courses/view/:pagename`,
          `/console/${business.id}/courses/view/do/*`,
          `/console/${business.id}/courses/do/*`,
          `/console/${business.id}/courses`,
        ]}
      >
        <EditorToolbar basePath={basePath} targetPath={targetPath} />
      </Route>
    </Switch>
  );
};
