import { CSSTransition, TransitionGroup } from "react-transition-group";
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import Toolbar, { ActionToolbar } from "./toolbar";
import { clearCurrentPage, setCurrentPage } from "redux/actions/editor";
import {
  faArrowLeft, 
  faClock,
  faHomeAlt,
  faNewspaper,
  faUsersClass,
  faTvRetro,
  faUserGraduate
} from "@fortawesome/pro-light-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { ActionCreators } from "redux-undo";
import Console from "components/console";
import ContentTypes from "editor/ContentTypes";
import Dialogs from "./Dialogs";
import Editor from "editor";
import { default as MainToolbar } from "components/console/toolbar";
import Schedule from "./schedule";
import Spinner from "react-bootstrap/Spinner";
import firebase from "firebase";
import { syncCourses } from "redux/actions/courses";
import useEditor from "hooks/editor";

// {!business.pages || business.pages.length === 0 ? <strong>Landing Page*</strong> : "Landing Page"}

const LeftNavigationMain = ({ business, courses }) => {
  return (
    <div className="mt-0 h-100">
      <div style={{ overflow: "hidden" }} className="h-100">
        <MainToolbar.Item
          to={`/console/${business.id}/courses`}
          exact
          icon={faHomeAlt}
          sidebar
        >
          Dashboard
        </MainToolbar.Item>
        <h6 className="pl-3 mt-3">Courses</h6>
        {courses
          ? courses.map((item) => (
              <MainToolbar.Item
                to={`/console/${business.id}/courses/view/${item.id}`}
                icon={faUsersClass}
                sidebar
              >
                {item.id}
              </MainToolbar.Item>
            ))
          : null}
      </div>
    </div>
  );
};
const LeftNavigationView = ({ business }) => {
  const match = useRouteMatch();
  return (
    <div className="mt-0 h-100">
      <div style={{ overflow: "hidden" }} className="h-100">
        <MainToolbar.Item
          to={`/console/${business.id}/courses`}
          exact
          icon={faArrowLeft}
          sidebar
          divider
        >
          Back
        </MainToolbar.Item>
        <h6 className="pl-3 mt-3">Course Configurations</h6>
        <MainToolbar.Item
          to={`/console/${business.id}/courses/view/${match.params.id}`}
          exact
          icon={faNewspaper}
          sidebar
          divider
        >
          Description
        </MainToolbar.Item>
        <MainToolbar.Item
          to={`/console/${business.id}/courses/view/${match.params.id}/schedule`}
          icon={faClock}
          sidebar
          divider
        >
          Schedule
        </MainToolbar.Item>
        <MainToolbar.Item
          to={`/console/${business.id}/courses/view/${match.params.id}/bookings`}
          icon={faUserGraduate}
          sidebar
          divider
        >
          Bookings
        </MainToolbar.Item>        
        <MainToolbar.Item
          to={`/console/${business.id}/courses/view/${match.params.id}/stage`}
          icon={faTvRetro}
          sidebar
          divider
        >
          Virtual Room
        </MainToolbar.Item>

      </div>
    </div>
  );
};

const LoadEditor = ({ business }) => {
  const [currentPage, editor] = useEditor();
  const document = useSelector((state) =>
    state.editor ? state.editor.document.present : null
  );
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const bid = business.id;
  const course = useSelector((state) =>
    state.courses.courses.find((item) => item.id === match.params.id)
  );
  useEffect(() => {
    if (course) {
      dispatch(setCurrentPage(course.id, course));
      setLoading(false);
    }
    return () => {
      dispatch(clearCurrentPage());
      dispatch(ActionCreators.clearHistory());
    };
  }, [course, dispatch]);

  return loading ? (
    <Spinner animation="border" />
  ) : course && document ? (
    <Editor
      editing={true}
      document={document}
      currentPage={currentPage}
      content={currentPage.parsys}
    />
  ) : (
    <h1>Course with ${match.params.id} not found</h1>
  );
};

export default ({ business }) => {
  let user = useSelector((state) => state.login.user);

  const [currentPage, editor] = useEditor();
  const document = useSelector((state) => state.editor.document.present);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const bid = business.id;
  const courses = useSelector((state) =>
    state.courses.courses.filter((item) => item.parent === bid)
  );
  console.log("courses list ashift");
  console.log(courses);

  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("business")
      .doc(bid)
      .collection("courses")
      .onSnapshot(function (querySnapshot) {
        var pages = [];
        querySnapshot.forEach(function (doc) {
          console.log(doc);
          pages.push({ ...doc.data(), id: doc.id, parent: bid });
        });
        console.log(pages);
        dispatch(syncCourses(bid, pages));
      });

    return function cleanup() {
      return new Promise((resolve, reject) => {
        try {
          unsubscribe();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [dispatch, bid]);

  return (
    <Console
      loading={document && document.currentPage.loading}
      menuItems={<Toolbar business={business} />}
      actionItems={<ActionToolbar business={business} />}
      navigation={
        <Switch>
          <Route path={`/console/${business.id}/courses`} exact>
            <LeftNavigationMain business={business} courses={courses} />
          </Route>
          <Route
            path={[
              `/console/${business.id}/courses/view/:id`,
              `/console/${business.id}/courses/view/:id/do/*`,
            ]}
          >
            <LeftNavigationView business={business} />
          </Route>
        </Switch>
      }
    >
      <Switch>
        <Route
          path={[
            `/console/${business.id}/courses/view/:id`,
            `/console/${business.id}/courses/view/:id/do/*`,
          ]}
          exact
        >
          <LoadEditor business={business} />
        </Route>
        <Route path={[`/console/${business.id}/courses/view/:id/schedule`,
      `/console/${business.id}/courses/view/:id/schedule/:schedule`,
      `/console/${business.id}/courses/view/:id/schedule/:schedule/*`]}>
          <Schedule business={business} />
        </Route>
      </Switch>
      <Dialogs business={business} />
    </Console>
  );
};
