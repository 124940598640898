import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import firebase from "firebase";
//import AddNewDialog from "./AddNewDialog";
import Spinner from "react-bootstrap/Spinner";
import { syncCourses } from "redux/actions/courses";

import Overview from "./Overview";
import Detail from "./Detail";

const Schedule = ({ business }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const bid = business.id;

  const course = useSelector((state) => state.courses.courses.find(item=>item.id === match.params.id));
  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("business")
      .doc(bid)
      .collection("courses")
      .doc(match.params.id)
      .collection("schedule")
      .onSnapshot(function (querySnapshot) {
        var pages = [];
        querySnapshot.forEach(function (doc) {
          console.log(doc);
          pages.push({ ...doc.data(), id: doc.id, parent: bid });
        });
        console.log(pages);
        setLoading(false)        
        //dispatch(syncCourses(bid, pages));
      });

    return function cleanup() {
      return new Promise((resolve, reject) => {
        try {
          unsubscribe();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    };
  }, [dispatch, bid, match.params.id]);

  return (
    <div className="w-100 pl-3 pr-3">
      {loading ? (
        <h2>Loading <Spinner animation="border" /></h2>
      ) : (
        <Switch>
          <Route path={[`${match.path}`,
        `${match.path}/new/*`,
        `${match.path}/add/*`]} exact>
            <Overview business={business}  course={course}/>
          </Route>
          <Route path={`${match.path}/:schedule`}>
            <Detail business={business} course={course} />
          </Route>
          
          
        </Switch>
      )}
    </div>
  );
};
//Subscription.Dialog = AddNewDialog;

export default Schedule;
