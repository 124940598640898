import React, { useState } from "react";
import firebase from "firebase";
import { useMutation, useQueryClient } from 'react-query'
import Console from "components/console";
import Editor from "editor";
import ContentTypes from "editor/ContentTypes";
import { default as MainToolbar } from "components/console/toolbar";
import UsersList from "components/admin/UsersList";
import { useDispatch, useSelector } from "react-redux";
import {
  faCogs,
  faUsersCog,
  faLaptopCode,
  faGiftCard,
} from "@fortawesome/pro-light-svg-icons";
// {!business.pages || business.pages.length === 0 ? <strong>Landing Page*</strong> : "Landing Page"}
export default () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const queryClient = useQueryClient();
  let user = useSelector((state) => state.login.user);
  console.log(user);

  async function promoteDevuser(developer = true) {
    const docRef = await firebase
      .firestore()
      .collection("users").doc(selectedUser.id);
    await docRef.set({developer, claimDeveloper: false}, { merge: true})   
    setSelectedUser({...selectedUser, developer, claimDeveloper: false})
    queryClient.invalidateQueries("userslist");    
  }

  const devBar = () => {
    return selectedUser
      ? [
          {
            icon: faLaptopCode,
            text: selectedUser?.developer
              ? "Remove as Developer"
              : "Claim Developer Rights",
            active: selectedUser?.developer,
            onClick: ()=>promoteDevuser(selectedUser?.developer === true ? false : true),
            disabled: !selectedUser,
          },
        ]
      : [];
  };
  return (
    <div className="regular-layout">
      <Console
        actionItems={<Console.LinkItems items={[...devBar()]} />}
        navigation={
          <div className="mt-0 h-100">
            <div style={{ overflow: "hidden" }} className="h-100">
              <MainToolbar.Item
                to={`/admin/user/developer`}
                exact
                icon={faLaptopCode}
                sidebar
              >
                Developer Program
              </MainToolbar.Item>

              <MainToolbar.Item
                to={`/admin/user/invigations`}
                exact
                icon={faGiftCard}
                sidebar
              >
                Invitation
              </MainToolbar.Item>
            </div>
          </div>
        }
      >
        <UsersList
          selected={selectedUser?.id}
          onSelect={(usr) => setSelectedUser(usr)}
        />
      </Console>
    </div>
  );
};
