export const types = {
  UPDATE: {
    UPDATE_BUSINESSES: "UPDATE_BUSINESS",
    UPDATE_BUSINESSES_SUCCESS: "UPDATE_BUSINESS_SUCCESS",
    UPDATE_BUSINESSES_FAILURE: "UPDATE_BUSINESS_FAILURE",

    CONTACT_INFORMATION: " UPDATE_CONTACT_INFORMATION",
    CONTACT_INFORMATION_SUCCESS: "UPDATE_CONTACT_INFORMATION_SUCCESS",
    CONTACT_INFORMATION_FAILURE: " UPDATE_CONTACT_INFORMATION_FAILURE",
  },
};

export const updateBusiness = (business) => {
  return {
    type: types.UPDATE.UPDATE_BUSINESSES,
    business,
  };
};

export const updateBusinessFailure = (error) => ({
  type: types.UPDATE.UPDATE_BUSINESSES_FAILURE,
  error,
});

export const updateBusinessSuccess = () => ({
  type: types.UPDATE.UPDATE_BUSINESSES_SUCCESS,
});

export const updateContactInformation = (fields) => ({
  type: types.UPDATE.CONTACT_INFORMATION,
  fields,
});

export const updateContactInformationError = (error) => ({
  type: types.UPDATE.CONTACT_INFORMATION_FAILURE,
  error
});

export const updateContactInformationSuccess = () => ({
  type: types.UPDATE.CONTACT_INFORMATION_SUCCESS,
});
