import React, { useState, useLayoutEffect } from "react";
import {
  Link,
  useRouteMatch,
  useHistory,
  Route,
  Switch,
  Prompt
} from "react-router-dom";
// TODO: migrate eventually to dialog component!
import firebase from "firebase";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import RichEditor from "components/RichEditor";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { convertToHTML } from "draft-convert";
import * as yup from "yup";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { EditorState } from "draft-js";
export default ({ show, handleClose, businessId }) => {
  let match = useRouteMatch();
  const { t } = useTranslation();
  const [error, setError] = useState(); 

  return (
    <Modal backdrop show={ show } onHide={handleClose} centered>
      <Modal.Header closeButton className="bg-solwhite">
        <Modal.Title className="h6">Create a new product</Modal.Title>
      </Modal.Header>

      <Formik
        onSubmit={async (fields, actions) => {
          const data = {
            ...fields,
            active: false,
            description: convertToHTML(fields.description.getCurrentContent())
          };
//businessId
try {
          const db = firebase.firestore();
          let ref = db.collection(`business/${businessId}/products`);
          let doc = await ref.add(data);
          handleClose();
        }catch(e) {
          setError(e);
        }
          
        }}
        initialValues={{
          description: EditorState.createEmpty()
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          setFieldValue,
          errors,
          resetForm
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body className="bg-solwhite">
                <Form.Group controlId="formGridAddress1">
                  <Form.Label>{t("Product Name")}</Form.Label>
                  <Form.Control
                    placeholder={t("Placeholder Product Name")}
                    name="name"
                    feedback={errors.name}
                    onChange={handleChange}
                    isValid={touched.name && !errors.name}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                  {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

        <h6>Description:</h6>
                <div className="container-fluid m-0 p-0">
                  <RichEditor
                    editorState={values.description}
                    onChange={value => setFieldValue("description", value)}
                  />
                </div>
              </Modal.Body>

              <Modal.Footer className="bg-solwhite">
                <Button variant="secondary">Close</Button>
                <Button variant="info" type="submit">
                  Save changes
                </Button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
