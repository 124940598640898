import React, { useState, useRef, useContext } from "react";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";
import EditorContext from "editor/EditorContext";
import { useDispatch, useSelector } from "react-redux";

import {
  addSelectedComponent,
  removeSelectedComponent,
  moveComponent,
} from "redux/actions/editor";

import { returnComponentByType } from "editor";
import ContentTypes from "editor/ContentTypes";

import Button from "react-bootstrap/Button";

const isTouch =
  "ontouchstart" in window ||
  navigator.MaxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

const Component =  ({
  className,
  children,
  name,
  onDblClick,
  id = null,
  type = "Component",
  onHover,
  fixposition = false,
  disableHover,
  content,
  editiable = true,
  selectable = false,
  onlyDroppable = false,
  draggable = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const context = useContext(EditorContext);
  let ref = useRef();

  const selectedComponent = useSelector((state) =>
    state.editor.config.selectedComponents.find((item) => item.id === id)
  );

  const config = useSelector((state) => state.editor.config);
  const document = useSelector((state) => state.editor.document.present);

  const [hover, setHover] = useState(false);
  const [canDrop, setCanDrop] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const renderComponent = returnComponentByType(type);

  function toggleClick() {
    if (selectedComponent) {
      dispatch(removeSelectedComponent(id));
    } else {
      dispatch(addSelectedComponent({ id, type, fixposition }));
    }
  }
  const [collectedProps, drag] = useDrag({
    item: { id, type },
    canDrag: !fixposition && draggable,
    begin: () => {
      setIsDragging(true);
    },
    end: (item, monitor) => {
      let result = monitor.getDropResult();
      if (result && result.id !== id) {
        dispatch(
          moveComponent(
            result.id,
            id,
            result.down,
            document.currentPage,
            config.currentLanguage
          )
        );
      }
      setIsDragging(false);
    },
  });

  const [dropProps, drop] = useDrop({
    accept:
      !fixposition || onlyDroppable
        ? renderComponent && renderComponent.accept
          ? renderComponent.accept
          : "component"
        : "null",
    collect: (monitor, props) => {
      const isdrop = monitor.isOver({ shallow: true });
      setCanDrop(isdrop);
      if (monitor && monitor.getDifferenceFromInitialOffset()) {
        let down = monitor.getDifferenceFromInitialOffset().x < 0;
        setDropDown(down);
      }
    },
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        return {
          id,
          down: monitor.getDifferenceFromInitialOffset().x < 0,
        };
      } else {
        return undefined;
      }
    },
  });

  function attachRef(el) {
    drag(el);
    drop(el);
    ref.current = el;
  }

  return (
    context.editing ? 
    <div
      key={`${id}-component-wrapper-`}
      ref={attachRef}
      className={`ngh-editor__component${className ? className : ""}`}
      tabIndex="0"
      style={{
        opacity: isDragging ? 0.3 : 1,
      }}
      onKeyDown={(event) => (event.key === "Enter" ? toggleClick() : null)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (editiable || selectable) toggleClick();
      }}
      onMouseOver={() => {
        if (onHover && editiable) {
          onHover(true);
        }
        setHover(true);
      }}
      onMouseOut={() => {
        if (onHover && editiable) {
          onHover(false);
        }
        setHover(false);
      }}
    >
      <div
        className={classNames({
          "ngh-editor__component__overlay": true,
          "ngh-editor__component__overlay--dragging": isDragging,
          "ngh-editor__component__overlay--candrop": canDrop,
          "ngh-editor__component__overlay--candrop-down": canDrop && dropDown,
          "ngh-editor__component__overlay--candrop-up": canDrop && !dropDown,
          "ngh-editor__component__overlay--selected": selectedComponent,
          "ngh-editor__component__overlay--over":
            !isTouch &&
            !disableHover &&
            !selectedComponent &&
            hover &&
            editiable,
        })}
      >
        <div className="type">{type}</div>
      </div>
      <div
        className="ngh-editor__component__content"
        key={`${id}-children ngh editor content`}
      >
        {children}
      </div>
    </div>: children
  );
};

export default Component;