import React, { useState } from "react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { useQuery } from "react-query";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { useSelector, useDispatch } from "react-redux";
import Status from "components/Status";
import Login from "components/account/login";
import Subscriptions from "components/account/subscriptions";
import Spinner from "react-bootstrap/Spinner";

import { sendEmailVerification, logout } from "redux/actions/login";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default () => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.login.user);
  const userData = useSelector((state) => {
    return {
      ...state.userData,
      subscriptions: state.userData.subscriptions
        ? state.userData.subscriptions.map((sub) => {
            return {
              ...sub,
              product: state.userData.products
                ? state.userData.products.find(
                    (itm) => itm.id === sub.product.id
                  )
                : sub.product.id,
            };
          })
        : [],
    };
  });

  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  console.log(user);
  console.log(userData);

  const stripe = useStripe();
  const elements = useElements();

  const createPortalLink = async () => {
    setLoading(true);
    const updateRef = firebase
      .app()
      .functions("europe-west6")
      .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");

    const { data } = await updateRef({ returnUrl: window.location.href });
    window.location.assign(data.url);
  };

  const getSubscriptions = async (key, id) => {
    if (!user) {
      return [];
    }
    const querySnapshot = await firebase
      .firestore()
      .collection("customers")
      .doc(user.uid)
      .collection("subscriptions")
      .get();
    const result = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const prod = await doc.data().product.get();
        console.log(doc.data())
        return { ...doc.data(), id: doc.id, product: { ...prod.data() } };
      })
    );

    return result;
  };

  const { status, data, error, isFetching } = useQuery(
    ["subscriptions", user.uid],
    getSubscriptions
  );

  return (
    <div className="regular-layout">
      <Switch>
        <Route path={`${match.url}`} exact>
          {user ? (
            <div className="container mt-2">
              
              {error ? <Alert variant="danger">{error}</Alert> : null}
              {!user.emailVerified ? (
                <div className="row">
                  <div className="col">
                    {loginState.sendEmailVerificationSuccess ? (
                      <Alert variant="success">
                        {t(
                          "We sent you an E-Mail. Please follow the link to verify your E-Mail and sign in back again."
                        )}{" "}
                        <Button
                          variant="link"
                          onClick={() => dispatch(logout())}
                        >
                          {t("Click here to")}
                        </Button>
                      </Alert>
                    ) : (
                      <Alert variant="danger">
                        {t("please verify your e-mail address")}{" "}
                        <Button
                          variant="link"
                          onClick={() => dispatch(sendEmailVerification())}
                        >
                          {t("send verification email")}
                        </Button>
                      </Alert>
                    )}

                    {loginState.sendEmailVerificationError ? (
                      <Alert variant="danger">
                        {loginState.sendEmailVerificationError.message}
                      </Alert>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col">
                  <h1>{t("Account")}</h1>
                  <h5>
                    {t("Account ID")}:{" "}
                    <span className="font-weight-light">
                      {user.profile ? user.profile.stripeId : "no id"}
                    </span>
                  </h5>
                  <p className="mt-4">
                    <strong>{user.displayName}</strong>, {user.email} 
                    {/* -{" "} <Link to={`/profile/${user.uid}`}>
                      {t("Go to Profile")}
                    </Link>*/}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`${match.url}/login`}>
                        {t("Login Settings")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <h4>Account Type</h4>
                      {user.isDeveloper ? <p>Developer</p> : <p>User</p>}
                    </div>
                  </div>
                </div>
                  
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`${match.url}/subscriptions`}>
                        {t("My Subscriptions")}
                      </Link>
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          ) : (
            <div className="container">
              <h1>{t("Account")}</h1>
            </div>
          )}
        </Route>
        <Route path={`${match.url}/login`}>
          <Login />
        </Route>
        <Route path={`${match.url}/subscriptions`}>
          <Subscriptions />
        </Route>
        <Route path={`${match.url}/photo`}></Route>
      </Switch>
    </div>
  );
};
