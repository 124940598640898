import React from "react";
import Item from "./Item";
import Dropdown from "react-bootstrap/Dropdown";

export default ({ items, match }) => {
  return items.map((action) => {    
    return (
      <Item to={action.to} key={action.to} {...action} url={match.url} {...action} match>
        {action.text}
      </Item>
    )
  });
};
