import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import Spinner from "../../Spinner";

export default ({ business }) => {
  const { t } = useTranslation();
  let match = useRouteMatch();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (business && business.id) {
      const updateAccount = async () => {
        let update = firebase.functions().httpsCallable("stripe-updateAccount");
        await update({
          id: business.id
        });
        setUpdated(true);
      };
      updateAccount();
    }
  }, [business]);

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          {business ? (
            !updated ? (
              <>
                <h1>Updating {business.companyName}</h1>
                <div className="text-center">
                  <Spinner variant="brand" />
                </div>
              </>
            ) : (
                <>
              <h1>
                {business.companyName} Updated
              </h1>
            <Link to={`/console/${match.params.id}`}>{t('Back to Account Admin Page')}</Link>
              </>
            )
          ) : (
            <div className="text-center">
              <Spinner variant="brand" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
