import React, { useState } from "react";
import firebase from "firebase";
import { useMutation, useQueryClient } from 'react-query'
import Dialog from "components/Dialog";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

const dialogSchema = {
  
};

export default ({ data }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(true);

  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);
    
    try {
      const db = firebase.firestore();
      const data = {
        ...fields,
      };
      console.log(data);
      await db.collection("settings").doc("dev").set(data)
      //const newdoc = await ref.add(data);
      setShowDialog(false);
      helper.resetForm();
      queryClient.invalidateQueries("devsettings")
      history.push("/admin/settings")
      /*window.setTimeout(
        () =>
          history.push(
            `/console/products/subscriptions/${newdoc.id}`
          ),
        400
      );*/
    } catch (e) {
      alert(e)
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog
      validation={dialogSchema}
      submitText="Continue"
      title="Development Instance Setup Settings"
      show={showDialog}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
      onSubmit={submitHandler}
      initialValues={{
        ...data
      }}
    >
      <Dialog.FormGroup name="GithubToken" label="Github Token" />
      <Dialog.FormGroup name="GithubUser" label="Github User" />
      <Dialog.FormGroup name="GithubInstallTemplate" label="Github Install Template Repository" />
      <Dialog.FormGroup name="DockerLoginCommand" label="Login Command"/>
      docker login ghcr.io -u labertasch -p
    </Dialog>
  );
};
