import React, { useState, useContext } from "react";
import { uuid } from "helper";
import EditorContext from "editor/EditorContext";
import ContentTypes from "editor/ContentTypes";

import Placeholder from "components/placeholder";
import Dialog from "components/Dialog/Dialog";
import Component from "editor/Component";

import { FieldArray } from "formik";

import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Nav from "react-bootstrap/Nav";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import Navbar from "react-bootstrap/Navbar";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Hero = ({ title, subtitle, type, id, slides, transition, ...props }) => {
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      <div style={{}}>
        <Carousel
          fade={transition && transition === "fade"}
          controls={slides.length > 1}
          indicators={slides.length > 1}
          prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          {slides.length === 0 ? <h1>Please add a slide</h1> : null}
          {slides.map((slide) => (
            <Carousel.Item>
              <div
                className="d-block w-100"
                style={{
                  height: "500px",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundImage: `url(${
                    slide.image && slide.image.url
                      ? slide.image.url
                      : "https://images.unsplash.com/photo-1426901428072-737be6c018f9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=1600"
                  })`,
                }}
              >
                <div
                  style={{
                    background:
                      "radial-gradient(rgba(0,0,0,0.2), rgba(0,0,0,1))",
                  }}
                  className="d-block w-100 h-100"
                >
                  {slide.style && slide.style === "hero" ? (
                    <Jumbotron fluid className="w-100 h-100">
                      <Container>
                        <h1>Fluid jumbotron</h1>
                        <p>
                          This is a modified jumbotron that occupies the entire
                          horizontal space of its parent.
                        </p>
                        <p>
                          <Button variant="primary">Learn more</Button>
                        </p>
                      </Container>
                    </Jumbotron>
                  ) : null}
                </div>
              </div>
              {!slide.style && slide.style !== "hero" ? (
                <Carousel.Caption>
                  <h1>{slide.title}</h1>
                  <h2 style={{ fontWeight: 300 }}>{slide.subtitle}</h2>
                </Carousel.Caption>
              ) : null}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Component>
  ) : (
    <div>
      <Carousel
        fade={transition && transition === "fade"}
        controls={slides.length > 1}
        indicators={slides.length > 1}
        prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
      >
        {slides.length === 0 ? <h1>Please add a slide</h1> : null}
        {slides.map((slide) => (
          <Carousel.Item>
            <div
              className="d-block w-100"
              style={{
                height: "500px",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: `url(${
                  slide.image && slide.image.url
                    ? slide.image.url
                    : "https://images.unsplash.com/photo-1426901428072-737be6c018f9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=900&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=1600"
                })`,
              }}
            >
              <div
                style={{
                  background: "radial-gradient(rgba(0,0,0,0.2), rgba(0,0,0,1))",
                }}
                className="d-block w-100 h-100"
              >
                {slide.style && slide.style === "hero" ? (
                  <Jumbotron fluid className="w-100 h-100">
                    <Container>
                      <h1>Fluid jumbotron</h1>
                      <p>
                        This is a modified jumbotron that occupies the entire
                        horizontal space of its parent.
                      </p>
                      <p>
                        <Button variant="primary">Learn more</Button>
                      </p>
                    </Container>
                  </Jumbotron>
                ) : null}
              </div>
            </div>
            {!slide.style && slide.style !== "hero" ? (
              <Carousel.Caption>
                <h1>{slide.title}</h1>
                <h2 style={{ fontWeight: 300 }}>{slide.subtitle}</h2>
                {/*<p>
                <buttno className="btn btn-info text-white">Book now</buttno>
              </p>*/}
              </Carousel.Caption>
            ) : null}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.HERO,
  slides: [],
});

Hero.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.HERO,
  ContentTypes.SECTION,
  ContentTypes.TEXT,
  ContentTypes.TEXT_IMAGE,
];

Hero.createInitialValue = (component, add = false) =>
  add ? defaultStructure() : component;
Hero.title = "Hero Component";

const Form = ({ component }) => {
  const [click, setClick] = useState(false);

  return (
    <>
      <FieldArray
        name="slides"
        component={({
          arrayHelpers,
          helpers,
          form,
          push,
          remove,
          move,
          ...props
        }) => {
          return (
            <>
              <Tabs defaultActiveKey="0" id="uncontrolled-tab-example">
                <Tab eventKey={-1} title="Config">
                  <div className="container">
                    <Dialog.FormGroup
                      name="transition"
                      label="Transition"
                      options={[
                        {
                          key: "fade",
                          label: "Fade",
                        },
                        { key: "", label: "Slide" },
                      ]}
                    />
                  </div>
                </Tab>
                {form.values.slides.map((slide, index) => (
                  <Tab
                    eventKey={index}
                    title={slide.title ? slide.title : `Slide ${index}`}
                  >
                    <div className="container">
                      <Dialog.FormGroup
                        name={`slides[${index}].image`}
                        type="image"
                        label="Background Image"
                      />
                      <Dialog.FormGroup
                        name={`slides[${index}].title`}
                        label="Text"
                      />
                      <Dialog.FormGroup
                        name={`slides[${index}].subtitle`}
                        label="Text"
                      />
                      <Dialog.FormGroup
                        name={`slides[${index}].style`}
                        label="Style"
                        options={[
                          { key: "", label: "Regular" },
                          {
                            key: "hero",
                            label: "Hero",
                          },
                        ]}
                      />

                      {slide.style && slide.style === "hero" ? (
                        <h1>Hero STyle</h1>
                      ) : null}

                      <button type="button" onClick={() => remove(index)}>
                        -
                      </button>
                      {index !== 0 ? (
                        <button
                          type="button"
                          onClick={() => move(index, index - 1)}
                        >
                          down
                        </button>
                      ) : null}
                      {index < form.values.slides.length ? (
                        <button
                          type="button"
                          onClick={() => move(index, index + 1)}
                        >
                          up
                        </button>
                      ) : null}
                    </div>
                  </Tab>
                ))}
              </Tabs>

              <button
                type="button"
                onClick={() => push({ title: "new slide" })}
              >
                add
              </button>
            </>
          );
        }}
      />
    </>
  );
};

Hero.displayFormFields = (component) => <Form component={component} />;
export default Hero;
