import React, { useContext } from "react";
import EditorContext from "editor/EditorContext";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";
import Component from "editor/Component";
import Dialog from "components/Dialog";

const Card = ({ text, id, type, title, header, ...props }) => {
  const context = useContext(EditorContext);
  return context.editing ? (
    <Component type={type} id={id} {...props}>
      <div className="card w-100 h-100">
        {header ? <div class="card-header">{header}</div> : null}
        <div className="card-body">
          {title ? <h5 class="card-title">{title}</h5> : null}
          <div className="card-text">
            {text ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              ></span>
            ) : (
              <h1>add text</h1>
            )}
          </div>
        </div>
      </div>
    </Component>
  ) : (
    <div className="card w-100 h-100">
      {header ? <div class="card-header">{header}</div> : null}
      <div className="card-body">
        {title ? <h5 class="card-title">{title}</h5> : null}
        <div className="card-text">
          {text ? (
            <span
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            ></span>
          ) : (
            null
          )}
        </div>
      </div>
    </div>
  );
};

export const defaultStructure = () => ({
  id: uuid(),
  type: ContentTypes.CARD,
  text: EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(""))
  ),
});

Card.beforeSave = (component) => ({
  ...component,
  text: convertToHTML(component.text.getCurrentContent()),
});

Card.createInitialValue = (component, add) =>
  add
    ? defaultStructure()
    : {
        ...component,
        text: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(component.text || "")
          )
        ),
      };

Card.title = "Card Component";

Card.accept = [
  ContentTypes.HEADLINE,
  ContentTypes.CARD,
  ContentTypes.VIDEO,
  ContentTypes.TEXT,
  ContentTypes.TEXT_IMAGE,
];

Card.displayFormFields = () => (
  <>
    <Dialog.FormGroup name="header" label="Card Header" />
    <Dialog.FormGroup name="title" label="Card Title" />
    <Dialog.FormGroup name="text" type="richtext" label="Text" />
  </>
);

export default Card;
