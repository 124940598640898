export const types = {
    SYNC_COURSES: "COURSES/SYNC_COURSES",    
  };
  
  export const syncCourses = (business, courses) => {
    return {
      type: types.SYNC_COURSES,
      business,
      courses
    }
  }
  