import React, { useState, useEffect } from "react";
import firebase from "firebase";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  Link,
} from "react-router-dom";
import useQuery, { useCurrentLocation } from "hooks/searchQuery";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  login,
  logout,
  requestOfficeLogin,
  requestGoogleLogin,
  registerUsernamePassword,
  sendPasswordResetEmail,
} from "../redux/actions/login";
import HeroDetailPage from "components/HeroDetailPage";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faMicrosoft,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import EmailLogin from "../components/loginpanel/EmailLogin";
import Alert from "react-bootstrap/Alert";
import ResetPassword from "../components/loginpanel/ResetPassword";

export default () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const query = useQuery();
  const user = useSelector((state) => state.login.user);
  const loading = useSelector((state) => state.login.loading);
  const dispatch = useDispatch();
  const [cli, setCli] = useState(false);  
  const [cliOk, setCliOk] = useState(false);
  const [loginMode, setLoginMode] = useState(true);

  useEffect(()=>{
    if(query.has("cli")) {
      setCli(true);
    }
  }, []);

  useEffect(()=>{
if(user && cli) {

  async function getToken() {
    
    const token = await firebase.auth().currentUser.getIdToken(true);
    
    await fetch('http://localhost:2710/ok', {
            method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({token})})
          setCliOk(true);
  }
  getToken();
}
  }, [user, cli])
  
  if(user && cli) {
    return <div className="regular-layout d-flex h-100 align-items-center justify-content-center flex-column">   <h1 className="m-5">{cliOk ? "You can close the Window" : "Checking Permissions" }</h1> 
    {!cliOk ?
    <Spinner animation="border" variant="success"/>   
    : null}
    </div>

  }
  return (
    <div className="regular-layout">      
      
      {user && !query.has("cli") ? (
        <>
          <Button onClick={() => dispatch(logout())}>{t("Sign out")}</Button>
          <Redirect to="/"></Redirect>
        </>
      ) : (
        <>
          <div className="container">
            <div className="login-panel m-2">
              <Switch>
                <Route path="/login/solutas" exact>
                  <>
                    <div className="container">
                      <HeroDetailPage
                        title={
                          <div style={{ textAlign: "center" }} className="pb-3">
                            <img
                              height="60"
                              src="http://demo.soluneo.io/static-files/assets/ca4a96dffec4ac0cb75789a2ca9ad1e/e842ae93978bd0c829a04e9c6896344cc/560de645c79d196d04f066704f73f702.png"
                            />
                          </div>
                        }
                      />
                      <div className="card">
                        <div className="card-body text-center p-5">
                          <Button
                            variant="brand"
                            size="lg"
                            onClick={() => dispatch(requestOfficeLogin())}
                          >
                            <FontAwesomeIcon
                              icon={faMicrosoft}
                              className="mr-3"
                            />{" "}
                            {t("Login with your company credentials")}
                          </Button>
                          <div className="text-muted mt-4">
                            {t("Only for employees from SOLUTAS GmbH")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Route>
                <Route path={`${match.url}`} exact>
                  <div className="section">
                    <div className="text-muted">
                      {t("Sign in with your social media account")}
                    </div>
                  </div>
                  <div className="section">
                    <div className="card">
                      <div className="card-body">
                        <Button
                          onClick={() => dispatch(login())}
                          size="sm"
                          variant="facebook"
                        >
                          <FontAwesomeIcon icon={faFacebook} className="mr-2" />{" "}
                          Facebook
                        </Button>
                        <Button
                          onClick={() => dispatch(requestGoogleLogin())}
                          size="sm"
                          variant="google"
                          className="ml-2"
                        >
                          <FontAwesomeIcon icon={faGoogle} className="mr-2" />{" "}
                          Google
                        </Button>

                        <Button
                          onClick={() => dispatch(requestOfficeLogin())}
                          size="sm"
                          variant="microsoft"
                          className="ml-2"
                        >
                          <FontAwesomeIcon
                            icon={faMicrosoft}
                            className="mr-2"
                          />{" "}
                          Microsoft
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="section">
                    <span className="text-muted">{t("or")}</span>
                  </div>
                  <div className="section">
                    <div className="card">
                      <div className="card-body">
                        <EmailLogin loginMode={loginMode} />
                      </div>
                    </div>
                  </div>
                  <div className="section">
                    {loginMode ? (
                      <div>
                        <span className="text-muted">
                          {t("Don't have an account yet?")}{" "}
                          <a href="#" onClick={() => setLoginMode(false)}>
                            {t("Register")}
                          </a>{" "}
                        </span>
                        <br />
                        <span className="text-muted">
                          {t("Forgott your password?")}{" "}
                          <Link to="/login/reset">{t("Reset password")}</Link>
                        </span>
                      </div>
                    ) : (
                      <span className="text-muted">
                        {t("Already have an account? ")}{" "}
                        <a href="#" onClick={() => setLoginMode(true)}>
                          {t("login")}
                        </a>{" "}
                      </span>
                    )}
                  </div>
                </Route>
                <Route path={`${match.url}/reset`}>
                  <ResetPassword />
                </Route>
              </Switch>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
