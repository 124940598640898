import React from "react";
import Sites from "./sites";
import Wizard from "./wizard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";

export default () => (
  <Switch>
    <Route path="/sites/create">
      <div className="regular-layout">
        <div className="pt-5 container-fluid">
          <Wizard />
        </div>
      </div>
    </Route>
    <Route path="/">
      <Sites />
    </Route>
  </Switch>
);
