import { useState, useEffect } from "react";

export default (offset = 10, element) => {
  // setting initial value to true
  const [scroll, setScroll] = useState(1);
  const container = element ? document.getElementById(element) : null;
  console.log("usesccsarslföd ");
  console.log(container)
  // running on mount
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = container
        ? container.scrollTop < offset
        : window.scrollY < offset;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    // setting the event handler from web API
    if (container) {
      container.addEventListener("scroll", onScroll);
    } else {
      document.addEventListener("scroll", onScroll);
    }

    // cleaning up from the web API
    return () => {
      if (container) {
        container.removeEventListener("scroll", onScroll);
      } else {
        document.removeEventListener("scroll", onScroll);
      }
    };
  }, [container, element, offset, scroll, setScroll]);

  return scroll;
};
