import React, { useEffect } from "react";
import {
  useHistory,
  Switch,
  Route,
  useRouteMatch,
  Link,
} from "react-router-dom";
import DetailPage from "./DetailPage";
import Status from "components/Status";
const Listing = ({ business, history }) => (
  <div className="w-100 pl-3 pr-3"> 
    <h1 className="mt-3">Products</h1>
    {business.products ? (
      <div class="list-group">
        {business.products.map((product) => (
          <div
            key={product.id}
            class="list-group-item list-group-item-action flex-column align-items-start "
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/console/${business.id}/products/list/show/${product.id}`
              )
            }
          >
            <div class="d-flex w-100 justify-content-between">
              <div>{product.name}</div>
              <Status value={product.active} />
            </div>
            {/*<div className="card-body">              
              {product.productId ? (
                <div className="bg-success text-white">
                  Product Synced {product.productId}
                </div>
              ) : (
                <div className="bg-danger text-white">Product not synced</div>
              )}
              <p className="card-text">{product.id}</p>
            </div>*/}
          </div>
        ))}
      </div>
    ) : (
      <div> add new product</div>
    )}
  </div>
);

const ProductDetail = ({ business }) => {
  const match = useRouteMatch();
  return (
    <>
      {business ? (
        <DetailPage business={business} productId={match.params.product} />
      ) : null}
    </>
  );
};

export default ({ business }) => {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/show/:product`}>
          <ProductDetail business={business} history={history} />
        </Route>

        <Route path={`${match.path}`}>
          <Listing business={business} history={history} />
        </Route>
      </Switch>
    </div>
  );
};
