import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import HeroLandingPage from "components/HeroLandingPage";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import Container from "react-bootstrap/Container";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  Pagination,
  HitsPerPage,
  connectHits,
  Stats,
} from "react-instantsearch-dom";

import {
  GoogleMapsLoader,
  GeoSearch,
  Control,
  Marker,
} from "react-instantsearch-dom-maps";

import Button from "react-bootstrap/Button";
import qs from "qs";
import { useTranslation } from "react-i18next";
import HeroDetailPage from "components/HeroDetailPage";
import { useDispatch, useSelector } from "react-redux";
import { requestOfficeLogin } from "redux/actions/login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import LoginPanel from "../components/loginpanel";

const HitResult = ({ hit }) => {
  const history = useHistory();
  return (
    <div
      className="card"
      onClick={(e) => history.push(`/show${hit.objectID}`)}
      style={{ cursor: "pointer" }}
    >
      <img
        className="card-img-top"
        src={hit.languages.de.parsys[0].slides[0].image.url}
        alt="Card image cap"
      />
      <div class="card-body">
        <h5 class="card-title">{hit.languages.de.meta.title}</h5>
        <p
          class="card-text"
          dangerouslySetInnerHTML={{ __html: hit.languages.de.meta.teaser }}
        ></p>
      </div>
    </div>
  );
};

const ResultList = connectHits(({ hits }) => {
  return (
    <div className="card-columns">
      {hits.map((hit) => (
        <HitResult hit={hit} />
      ))}
    </div>
  );
});
export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route path="/login/solutas" exact>
        <>
          <HeroDetailPage title="SOLUTAS Internal Login" />
          <div className="container">
            <div className="card">
              <div className="card-body text-center p-5">
                <Button
                  variant="brand"
                  size="lg"
                  onClick={() => dispatch(requestOfficeLogin())}
                >
                  <FontAwesomeIcon icon={faMicrosoft} className="mr-3" />{" "}
                  {t("Login with your company credentials")}
                </Button>
                <div className="text-muted mt-4">
                  {t("Only for employees from SOLUTAS GmbH")}
                </div>
              </div>
            </div>
          </div>
        </>
      </Route>
      <Route path="/login" exact>
        <>
          <HeroDetailPage title="Join Us" />
          <div className="container">
            <div className="card">
              <LoginPanel />
            </div>
          </div>
        </>
      </Route>
      <Route path="/" exact>
        <div id="landingpage-shit">
          <HeroDetailPage title="Become a Starlet" />
          {/*
            <RefinementList attribute="business.city" />
*/}
          <div className="container mb-5" style={{position: 'sticky', backgroundColor: 'white'}}>
            <div className="row">
              <div className="col">
                {" "}
                <HitsPerPage
                  defaultRefinement={10}
                  items={[
                    { value: 1, label: "Show only one hit" },
                    { value: 5, label: "Show 5 hits" },
                    { value: 10, label: "Show 10 hits" },
                  ]}
                />
              </div>
              <div className="col">
                <Stats
                  translations={{
                    stats(nbHits, timeSpentMS) {
                      return `${nbHits} results found in ${timeSpentMS}ms`;
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <ResultList/>
          </div>
          <div className="container">
            <Pagination />
          </div>
          {/*<div className="container">
              <h1>google</h1>
              <div style={{ height: 500 }}>
                <GoogleMapsLoader apiKey="AIzaSyDiWk4PENq1FdLYG-5N5CX_Tj781I3NILk">
                  {(google) => {
                    console.log("google");
                    console.log(google);
                    return (
                      <GeoSearch google={google} initialZoom={8}>
                        {({ hits }) => {
                          console.log("hits");
                          console.log(hits);
                          return (
                            <div>
                              <Control />
                              {hits.map((hit) => (
                                <Marker key={hit.objectID} hit={hit} />
                              ))}
                            </div>
                          );
                        }}
                      </GeoSearch>
                    );
                  }}
                </GoogleMapsLoader>
                </div>
            </div>*/}

          
          {/*
          <Container className="search-result-list">
            <CardColumns>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title that wraps to a new line</Card.Title>
                  <Card.Text>
                    <Link to="/show/34234">my businasdf</Link>
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                    <Link to="/show/34234">my businasdf</Link>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="p-3">
                <blockquote className="blockquote mb-0 card-body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                  <footer className="blockquote-footer">
                    <small className="text-muted">
                      Someone famous in{" "}
                      <cite title="Source Title">{t("Source Title")}</cite>
                    </small>
                  </footer>
                </blockquote>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This card has supporting text below as a natural lead-in to
                    additional content.{" "}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Last updated 3 mins ago</small>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title that wraps to a new line</Card.Title>
                  <Card.Text>
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title that wraps to a new line</Card.Title>
                  <Card.Text>
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title that wraps to a new line</Card.Title>
                  <Card.Text>
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title that wraps to a new line</Card.Title>
                  <Card.Text>
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img
                  variant="top"
                  src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg"
                />
                <Card.Body>
                  <Card.Title>Card title that wraps to a new line</Card.Title>
                  <Card.Text>
                    This is a longer card with supporting text below as a
                    natural lead-in to additional content. This content is a
                    little bit longer.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card bg="primary" text="white" className="text-center p-3">
                <blockquote className="blockquote mb-0 card-body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                  <footer className="blockquote-footer">
                    <small className="text-muted">
                      Someone famous in{" "}
                      <cite title="Source Title">{t("Source Title")}</cite>
                    </small>
                  </footer>
                </blockquote>
              </Card>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This card has supporting text below as a natural lead-in to
                    additional content.{" "}
                  </Card.Text>
                  <Card.Text>
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Img src="https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg" />
              </Card>
              <Card className="text-right">
                <blockquote className="blockquote mb-0 card-body">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                  <footer className="blockquote-footer">
                    <small className="text-muted">
                      Someone famous in{" "}
                      <cite title="Source Title">{t("Source Title")}</cite>
                    </small>
                  </footer>
                </blockquote>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Card title</Card.Title>
                  <Card.Text>
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This card has even longer
                    content than the first to show that equal height action.
                  </Card.Text>
                  <Card.Text>
                    <small className="text-muted">
                      Last updated 3 mins ago
                    </small>
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardColumns>
          </Container>*/}
         
        </div>
      </Route>
    </Switch>
  );
};
