import { uuid } from "helper";
import ContentTypes from "editor/ContentTypes";

export default (config) => {
  const FIRST_VERSION_ID = uuid();

  return {
    name: "landing",
    type: "ngh/page/course",
    currentVersion: FIRST_VERSION_ID,
    defaultLanguage: "de",
    createdAt: new Date(),
    versions: [
      {
        id: FIRST_VERSION_ID,
        createdAt: new Date().getTime(),
        languages: {
          de: {
            meta: {
              ...config
            },
            parsys: [
              {
                id: uuid(),
                fixposition: true,
                type: ContentTypes.HERO,
                slides: [
                  {
                    title: config.title,
                    subtitle: config.subtitle,
                    image:
                      "https://solutas.ch/wp-content/uploads/2019/09/IMG_5262-300x225.jpg",
                  },
                ],
                title: config.title,
              },
              {
                type: ContentTypes.SECTION,
                containerType: "regular",
                fixposition: true,
                parsys: [
                  {
                    type: ContentTypes.HEADLINE,
                    text: config.title,
                    size: 1,
                    id: uuid(),
                  },
                  {
                    type: ContentTypes.TEXT,
                    text: config.description || null,
                    id: uuid(),
                  },
                ],
                id: uuid(),
              },
              {
                type: ContentTypes.COURSE_LIST,
                containerType: "regular",
                fixposition: true,
                parsys: [
                 
                ],
                id: uuid(),
              },              
            ],
          },
          en: {
            meta: {
              title: config.title,
              name: config.name,
              subtitle: "page sub title",
            },
            parsys: [
              {
                type: ContentTypes.HEADLINE,
                text: "Title English",
                size: 1,
                id: uuid(),
              },
            ],
          },
        },
      },
    ],
  };
};
