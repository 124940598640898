import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { useMutation, useQueryClient } from 'react-query'
import Dialog from "components/Dialog";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

const dialogSchema = {
  
};

export default ({ data }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(true);


  
  const submitHandler = async (fields, helper) => {
    helper.setSubmitting(true);
    
    try {
      const db = firebase.firestore();
      const data = {
        ...fields,
      };
      console.log(data);
      await db.collection("settings").doc("prod").set(data)
      //const newdoc = await ref.add(data);
      setShowDialog(false);
      helper.resetForm();
      queryClient.invalidateQueries("prodsettings")
      history.push("/admin/settings")
      /*window.setTimeout(
        () =>
          history.push(
            `/console/products/subscriptions/${newdoc.id}`
          ),
        400
      );*/
    } catch (e) {
      helper.setErrors({ serverError: e.message });
    }
    helper.setSubmitting(false);
  };

  return (
    <Dialog
      validation={dialogSchema}
      submitText="Continue"
      title="Cloud Configuration"
      show={showDialog}
      handleClose={() => {
        setShowDialog(false);
        window.setTimeout(() => history.go(-1), 400);
      }}
      onSubmit={submitHandler}
      initialValues={{
        ...data
      }}
    >
      
      <Dialog.FormGroup type="textarea" as="textarea" rows={5} name="masterKey" label="Master SSH Key"/>
      <Dialog.FormGroup type="password" name="dcToken" label="DigitalOcean Admin Token"/>
      
    </Dialog>
  );
};
